import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import { useTranslation } from "react-i18next";
import AutocompleteField from "../../field/AutocompleteField";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { IconButton } from "@mui/material";
import { isEmptyValue } from "../../../helpers/methods";


export default function MemberForm(props) {

  const { t } = useTranslation();

  const allowAddNewMember = props.formValue?.member_name === undefined
  return (
    <Grid container spacing={1}>

      <Grid item xs={12}>
        <TextFieldFormControl
          label={t(`form.member_form.company_short_name`)}
          value={props.formValue?.company_short_name}
          disabled={true}
        />
      </Grid>
      {allowAddNewMember &&
        <>
          <Grid item xs={!isEmptyValue( props.formValue?.user ) ? 12 :11}>
            <AutocompleteField
              label={t("form.member_form.select_user")}
              name="user"
              value={props.formValue?.user}
              options={props.companies_users}
              isObjectOption={true}
              optionLabelKey={"full_name"}
              onChange={props.onChangeAutocomplete}
              disabled={props.readOnly || !allowAddNewMember}
              onClear={props.onClearUserSelectField}
              required={true}
            />
          </Grid>
        { isEmptyValue( props.formValue?.user )  &&
          <Grid item xs={1}>
            <IconButton
              onClick={props.onOpenInviteMemberDialog}
            >
              <ControlPointOutlinedIcon color={"primary"} fontSize="large" />
            </IconButton>
          </Grid>
}
        </>
      }
      {
        !allowAddNewMember &&
        <>
        <Grid item xs={12}>
          <TextFieldFormControl
            label={t(`form.member_form.new_member_name`)}
            value={props.formValue?.member_name}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldFormControl
            label={t(`form.member_form.mail`)}
            value={props.formValue?.user_mail}
            disabled={true}
          />
        </Grid>
        </>
      }
      <Grid item xs={12}>
        <AutocompleteField
          label={t("form.member_form.role_team")}
          name="role"
          value={props.formValue?.role}
          options={props.roles}
          isObjectOption={true}
          optionLabelKey={"role_name"}
          onChange={props.onChangeAutocomplete}
          disabled={props.readOnly}
          required={true}
        />
      </Grid>
    </Grid>
  );
};

MemberForm.propTypes = {
  readOnly: PropTypes.bool,
  formValue: PropTypes.object,
  onChange: PropTypes.func
};

MemberForm.defaultProps = {
  readOnly: false
};

