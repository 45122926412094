import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export default function ProjectTitleTypography(props) {


  const { t } = useTranslation();
  return (
    <div style={{ display: 'ruby-base' }}>
      <Typography
        className="typography-project-prefix"
        variant="h6"
        color={props.color}
        padding={'5px'}
        // style={{fontFamily: 'Anton'}}
      >
        {t("typography.project_tittle.project")}
      </Typography>
      <Typography
        className="typography-project"
        variant="h6"
        color={props.color}
        // style={{fontFamily: 'Anton, sans-serif'}}
      >
        {props.projectTitle}
      </Typography>
    </div>
  );
}

ProjectTitleTypography.propTypes = {
  titleKey: PropTypes.string,
  color: PropTypes.string,
};

ProjectTitleTypography.defaultProps = {
  color: "primary",
};
