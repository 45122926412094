import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  TableSortLabel,
} from "@mui/material";
import NoRecordsPaper from "../../paper/NoRecordsPaper";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box } from "@mui/system";
import {
  ORDERING_DIRECTION_ASC,
  ORDERING_DIRECTION_DESC,
} from "../../../helpers/constants";

import ContextMenu from "../../wrapper/ContextMenu/ContextMenu";

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: "none",
    fontSize: "0.92rem",
    color: "inherit",
    backgroundColor: "var(--primary)",
  },
  padding: "5px",
}));

export const StyledTableRow = styled(TableRow)(({ theme, hideLastBorder = true }) =>{
  return {
  // hide last border
  borderBottom: "1px solid rgba(224, 224, 224, 1)",
  "&:last-child td, &:last-child th": hideLastBorder ? {
    border: 0,
    borderBottom: 0,
  } : {}
}});

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.primary,
  color: "var(--white)",
}));

export default function BaseTable(props) {
  function getRowTableCellContent(rowElement) {
    if (
      ![null, undefined].includes(rowElement) &&
      typeof rowElement === "object"
    ) {
      if (rowElement.elementType === "button") {
        return (
          <Button
            onClick={rowElement.onClick}
            disabled={rowElement.disabled}
            size="small"
            variant="contained"
            color={"secondary"}
          >
            {rowElement.label}
          </Button>
        );
      } else if (rowElement.elementType === "checkbox") {
        return (
          <Checkbox
            size="small"
            checked={rowElement.value}
            disabled={rowElement.disabled ?? true}
            color={"secondary"}
            {...props.fieldConfig}
          />
        );
      }
    }
    return rowElement;
  }

  const showButtonCell = () => {
    return props.showEditButton;
  };

  if (props.showLoader) {
    return <CircularProgress />;
  } else if (props.rows.length > 0 || !props.showNoRecordsPaper) {
    return (
      <TableContainer
        component={props.tableComponent}
        id={props.id}
        sx={props.stickyHeader ? { overflowX: "initial" } : undefined}
      >
        <Table
          size="small"
          aria-label="a dense table"
          style={props.tableStyle}
          stickyHeader={props.stickyHeader}
        >
          <StyledTableHead style={props.tableHeadStyle}>
            {props.headers.map((header, index) => {
              if (props.hiddenColumns.includes(index)) return null;
              return (
                <StyledTableCell
                  className="capitalize-first-letter"
                  key={`${header}${index}`}
                  align="center"
                  style={props.tableHeadStyle}
                  {...header?.config}
                >
                  {!props.sortable ? (
                    typeof header === "object" ? (
                      header.label
                    ) : (
                      header
                    )
                  ) : (
                    <TableSortLabel
                      align={"left"}
                      active={props.sortColumn === header.orderKey}
                      direction={
                        props.sortColumn === header.orderKey
                          ? props.sortOrder
                          : ORDERING_DIRECTION_ASC
                      }
                      onClick={() =>
                        props.onSort(
                          header.orderKey,
                          props.sortColumn === header.orderKey &&
                            props.sortOrder === ORDERING_DIRECTION_ASC
                            ? ORDERING_DIRECTION_DESC
                            : ORDERING_DIRECTION_ASC
                        )
                      }
                    >
                      {header.label}
                    </TableSortLabel>
                  )}
                </StyledTableCell>
              );
            })}
            {showButtonCell() && (
              <StyledTableCell
                className="capitalize-first-letter"
                align="center"
                style={props.tableHeadStyle}
              ></StyledTableCell>
            )}
          </StyledTableHead>
          <TableBody>
            {props.rows.map((row) => (
              <ContextMenu
                key={row.rowId}
                showMenu={props.showContextMenu}
                actions={props.contextMenuActions.map((action) => ({
                  label: action.label,
                  icon: action.icon,
                  callback: () => action.callback(row.rowId),
                }))}
                Component={
                  <StyledTableRow
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                      borderBottom: "1px solid rgba(224, 224, 224, 1)",
                    }}
                    onClick={
                      props.rowOnClick !== undefined
                        ? (e) => props.rowOnClick(e, row.rowId)
                        : null
                    }
                    style={{
                      cursor: props.rowOnClick !== undefined ? "pointer" : null,
                    }}
                    selected={props.selectedRows.includes(row.rowId)}
                  >
                    {row.data.map((rowElement, index) => {
                      if (props.hiddenColumns.includes(index)) return null;
                      return (
                        <StyledTableCell
                          key={`${rowElement}-rowid-${row.id}-index-${index}`}
                          component="th"
                          scope="row"
                          align="center"
                          style={props.tableBodyCellStyle}
                        >
                          {getRowTableCellContent(rowElement)}
                        </StyledTableCell>
                      );
                    })}
                    {showButtonCell() && (
                      <StyledTableCell
                        key={`${row.id}-edit`}
                        component="th"
                        scope="row"
                        align="center"
                        style={props.tableBodyCellStyle}
                      >
                        <Box display="flex" sx={{ justifyContent: "center" }}>
                          {props.showEditButton &&
                            (row.config?.showEditButtonPerRow ?? true) && (
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  props.onEdit(row.rowId);
                                }}
                              >
                                <ModeEditOutlineOutlinedIcon color="secondary" />
                              </IconButton>
                            )}
                          {props.showDeleteButton &&
                            (row.config?.showDeleteButtonPerRow ?? true) && (
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  props.onDelete(row.rowId);
                                }}
                              >
                                <DeleteOutlineIcon color="secondary" />
                              </IconButton>
                            )}
                        </Box>
                      </StyledTableCell>
                    )}
                  </StyledTableRow>
                }
              />
            ))}

            {props.lastBodyRowContent && (
              <StyledTableRow>
                {props.lastBodyRowContent.map((element, idx) => (
                  <StyledTableCell
                    key={`lastRow-${idx}`}
                    component="th"
                    scope="row"
                    align="center"
                    style={{
                      ...props.tableBodyCellStyle,
                      ...props.lastBodyRowContentCellStyle,
                    }}
                  >
                    {element}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return <NoRecordsPaper />;
  }
}

BaseTable.propTypes = {
  headers: PropTypes.array,
  rows: PropTypes.array,
  id: PropTypes.string,
  showLoader: PropTypes.bool,
  rowOnClick: PropTypes.func,
  showEditButton: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  hiddenColumns: PropTypes.array,
  selectedRows: PropTypes.array,
  tableComponent: PropTypes.object,
  tableStyle: PropTypes.object,
  tableHeadStyle: PropTypes.object,
  tableBodyCellStyle: PropTypes.object,
  sortable: PropTypes.bool,
  sortColumn: PropTypes.string,
  sortOrder: PropTypes.string,
  onSort: PropTypes.func,
  stickyHeader: PropTypes.bool,
  showContextMenu: PropTypes.bool,
  contextMenuActions: PropTypes.arrayOf(PropTypes.object),
  lastBodyRowContent: PropTypes.arrayOf(PropTypes.element),
  lastBodyRowContentCellStyle: PropTypes.object,
  showNoRecordsPaper: PropTypes.bool,
};
BaseTable.defaultProps = {
  showLoader: false,
  showEditButton: false,
  hiddenColumns: [],
  selectedRows: [],
  tableComponent: Paper,
  tableStyle: {},
  tableHeadStyle: {},
  tableBodyCellStyle: {},
  rows: [],
  isSortable: false,
  stickyHeader: false,
  showContextMenu: false,
  contextMenuActions: [],
  lastBodyRowContent: null,
  lastBodyRowContentCellStyle: {},
  showNoRecordsPaper: true,
  headers: [],
};
