import React, { useState } from "react"
import { PropTypes } from 'prop-types'
import LoginForm from "../../forms/LoginForm"
import Grid from '@mui/material/Grid'
import { useSnackbarAlert } from "../../context/snackbarAlert"
import { useAuth } from "../../context/auth"
import { useTranslation } from "react-i18next"
import {useAsyncFn} from "../../hooks/useAsync"

export default function LoginPage(props) {

    const { t } = useTranslation();
    const { login } = useAuth();
    const loginFn = useAsyncFn(login);

    const isLoading = loginFn.loading

    const snackbarAlert = useSnackbarAlert();

    const onClickLogin = (loginData) => {
        loginFn
            .execute(loginData.username, loginData.password)
            .then(() => {
                snackbarAlert.openSuccessSnackbarAlert(t("snackbar_alert.logged_in"));
            })
            .catch((error) => {
                if (error.status === 401) {
                    snackbarAlert.openErrorSnackbarAlert(
                        t("snackbar_alert.bad_credentials")
                    );
                } else {
                    snackbarAlert.openErrorSnackbarAlert(
                        t("snackbar_alert.server_error")
                    );
                }
            });
    };

    return (
        <Grid container direction="row" justifyContent={"flex-start"} alignItems="center">
            <Grid item sm={2} xs={0} />
            <Grid item sm={8} xs={12}>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <LoginForm onClickLogin={onClickLogin} showLoadingButton={isLoading} />
                </Grid>
            </Grid>
            <Grid item sm={2} xs={0} />
        </Grid>
    )
}

LoginPage.propTypes = {
    getUserData: PropTypes.func,
}