import * as React from "react";

import PropTypes from "prop-types";

import UniversalHeaderFilterRow from "./UniversalHeaderFilterRow";
import UniversalTableFirstHeader from "./UniversalTableFirstHeader";

const UniversalTableHeaders = React.memo(
  ({
    showFilters,
    showCheckbox,
    showDetailsIcon,
    checkedAll,
    onClickCheckAll,
    resetPageNumber,
    headersConfig,
    filterPrefix,
    showCleanFilterIcon,
    showChangesCircle,
    showChangesCircleFilter,
    initialExpanded,
    excludedKeysForClearSearchParams,
  }) => {
    const [expanded, setExpanded] = React.useState(initialExpanded);
    return (
      <>
        <UniversalTableFirstHeader
          showChangesCircle={showChangesCircle}
          showChangesCircleFilter={showChangesCircleFilter}
          showFilters={showFilters}
          showCheckbox={showCheckbox}
          showDetailsIcon={showDetailsIcon}
          checkedAll={checkedAll}
          headersConfig={headersConfig}
          onClickCheckAll={onClickCheckAll}
          filterPrefix={filterPrefix}
          expanded={expanded}
          setExpanded={setExpanded}
        />
        {expanded && (
          <UniversalHeaderFilterRow
            showChangesCircle={showChangesCircle}
            showCheckbox={showCheckbox}
            showDetailsIcon={showDetailsIcon}
            headersConfig={headersConfig}
            resetPageNumber={resetPageNumber}
            filterPrefix={filterPrefix}
            showCleanFilterIcon={showCleanFilterIcon}
            excludedKeysForClearSearchParams={excludedKeysForClearSearchParams}
          />
        )}
      </>
    );
  }
);

export default UniversalTableHeaders;

UniversalTableHeaders.propTypes = {
  showCheckbox: PropTypes.bool,
  showDetailsIcon: PropTypes.bool,
  checkedAll: PropTypes.bool,
  onClickCheckAll: PropTypes.func,
  resetPageNumber: PropTypes.func,
  headersConfig: PropTypes.object,
  showCleanFilterIcon: PropTypes.bool,
  showFilters: PropTypes.bool,
  showChangesCircle: PropTypes.bool,
  initialExpanded: PropTypes.bool,
  showChangesCircleFilter: PropTypes.bool,
  excludedKeysForClearSearchParams: PropTypes.array,
};

UniversalTableHeaders.defaultProps = {
  showCheckbox: true,
  showCleanFilterIcon: false,
  showDetailsIcon: false,
  showFilters: true,
  showChangesCircle: false,
  showChangesCircleFilter: true,
  initialExpanded: false,
};
