import {
  projectAccessDataUrl,
  projectDataUrl,
  projectFullDataUrl,
  projectSelectListUrl,
  projectTeamDataUrl,
  projectUrl
} from "../helpers/apiUrls";
import { makeRequest } from "../helpers/makeRequest";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

export default function useProjectService() {
  const axiosPrivate = useAxiosPrivate();

  const getProjectSelectList = () => {
    return makeRequest(axiosPrivate, projectSelectListUrl);
  }

  const createProject = (dataToSend) => {
    return axiosPrivate(projectUrl, {
      method: "POST",
      data: dataToSend,
    });
  };

  const getProjectData = (projectId) => {
    return makeRequest(axiosPrivate, projectDataUrl(projectId));
  }

  const getProjectFullData = (projectId) => {
    return makeRequest(axiosPrivate, projectFullDataUrl(projectId));
  }

  const getProjectAccess = (projectId) => {
    return makeRequest(axiosPrivate, projectAccessDataUrl(projectId));
  }

  const getProjectTeam = (projectId) => {
    return makeRequest(axiosPrivate, projectTeamDataUrl(projectId));
  }

  return {
    getProjectSelectList,
    getProjectData,
    createProject,
    getProjectFullData,
    getProjectAccess,
    getProjectTeam
  };
}
