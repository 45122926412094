import * as React from 'react';
import { Grid } from '@mui/material';
import CreateProjectForm from '../../../form/CreateProjectForm';
import CompanyForm from '../../../form/CompanyForm';
import AddressForm from '../../../form/AddressForm';
import PropTypes from "prop-types";


export default function CustomerStep(props) {


  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
      style={{ marginTop: "5px" }}
    >
      <Grid item xs={4}>
        <CreateProjectForm
          formValue={props.projectForm}
          readOnly={true}
        />
      </Grid>
      <Grid item xs={4} >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={12} >
            <CompanyForm
              formValue={props.companyForm}
              companies = {props.companies}
              readOnly={true}
              isCustomer={false}
              selectedProjectId = {props.selectedProjectId}
            />
          </Grid>
          <Grid item xs={12} >
            <AddressForm
              formValue={props.addressForm}
              addressKinds={props.addressKinds}
              readOnly={true}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid item xs={4} >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >

          <Grid item xs={4} >
            <CompanyForm
              formValue={props.clientForm}
              onChange={props.onClientFormChange}
              onClearClientSelectField={props.onClearClient}
              companies_clients={props.companies_clients}
              isCustomer={true}
              selectedProjectId = {props.selectedProjectId}
              readOnly = {props.readOnly}
            />
          </Grid>
          <Grid item xs={4} >
            <AddressForm
              formValue={props.clientAddressForm}
              onChange={props.onClientAddressFormChange}
              addressKinds={props.addressKinds}
              readOnly = {props.readOnly}
            />
          </Grid>
        </Grid> 
      </Grid>*/}
    </Grid>
  )
}

CustomerStep.propTypes = {
  projectForm: PropTypes.object,
  companyForm: PropTypes.object,
  addressForm: PropTypes.object,
  addressKinds: PropTypes.array,
  clientForm: PropTypes.object,
  onClientFormChange : PropTypes.func,
  onClear: PropTypes.func,
  clientAddressForm : PropTypes.object,
  onClientAddressFormChange: PropTypes.func
};

CustomerStep.defaultProps = {
  addressKinds: []
};