
import { useEffect, useState } from "react";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper/DefaultPageWrapper";
import NavigationDrawer from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import TeamDragableTable from "../../components/table/TeamDragableTable/TeamDragableTable";
import { useParams } from "react-router-dom";
import useProjectService from "../../services/projectService";
import { useAsync } from "../../hooks/useAsync";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";


export default function TeamPage(props) {
  const { pageName } = props;
  const [projectTitle, setProjectTitle] = useState()
  const { projectId } = useParams();
  const { t } = useTranslation();


  const {
    getProjectAccess 
  } = useProjectService();
  const projectAccessData = useAsync(() => {
    if (projectId) {
      return getProjectAccess(projectId)
    }
    return Promise.resolve({})
  }, [projectId])
  

  useEffect(() => {
    if (projectAccessData.loading) return
    if (projectAccessData.value) {
      setProjectTitle(projectAccessData.value.project_name)
    }
  }, [projectAccessData.loading])

  if (projectAccessData.loading) return (<LoaderWrapper showLoader={true} />)

  return (
    <NavigationDrawer pageName={pageName} projectAccessData={projectAccessData?.value}>
      <DefaultPageWrapper titleKey={"team"} projectTitle={projectTitle}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          columnSpacing={1}
          rowSpacing={1}
          style={{marginTop : "2px"}}
        >

          <Grid item xs={12}>
            <TeamDragableTable
              projectId={projectId}
              projectAccessDataRefetch= {projectAccessData.refetch}
            />
          </Grid>
        </Grid>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
