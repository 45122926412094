import PropTypes from "prop-types";

import Tooltip from "@mui/material/Tooltip";
import { IconButton } from "@mui/material";

function IconButtonWithTooltip(props) {
  return (
    <Tooltip title={props.title}>
      <IconButton onClick={props.onClick}>{props.children}</IconButton>
    </Tooltip>
  );
}

IconButtonWithTooltip.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
};

export default IconButtonWithTooltip;
