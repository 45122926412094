import React, { memo } from 'react';
import './shapeNode.css';
import OperationShape from '../OperationShape';
import '../OperationShape/operationNode.css'

const ActivityShape = memo(({
  id,
  data,
  draggable
}) => {


  return (
    <OperationShape
      id={id}
      data={data}
      draggable={draggable}
      label={data.label !== undefined ? data.label : "CZYNNOŚĆ"}
      className={'activityNode operationNode'}
      style= {{ width: data.width, height: data.height }}
    />
  );
});

export default ActivityShape;
