import BasicDialog from "../../base/BasicDialog"
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { useForm } from "../../../hooks/useForm"
import LoadingButton from "@mui/lab/LoadingButton";
import { getErrorMsg, isEmptyValue } from "../../../helpers/methods";
import { useCallback } from "react";
import { Grid } from "@mui/material";
import useProcessService from "../../../services/processService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import TextFieldFormControl from "../../field/TextFieldFormControl";

function ProviderDialog(props) {
    const { t } = useTranslation();

    const snackbarAlert = useSnackbarAlert();


    const requiredFields = [
        "provider_name",
    ];

    const {
        createOpsProvider,
        getProviderDetails,
        updateProviderDetails
    } = useProcessService()

    const createOpsProviderFn = useAsyncFn( createOpsProvider);
    const updateProviderDetailsFn = useAsyncFn(updateProviderDetails);

    const {
        formValue,
        setFormValue,
        onChange
    } = useForm({ 'process': props.processId })


    const operationNodeData = useAsync(() => {
        if (props.providerId) {
            return getProviderDetails(props.providerId).then((res) => {
                setFormValue(res)
            })
                .catch((error) => {
                    snackbarAlert.openErrorSnackbarAlert(
                        t("snackbar_alert.occurred_error_during_get_provider_data")
                    );
                });
        }
        return Promise.resolve(formValue)
    }, [props.providerId])

    const isFormValid = () => {
        return requiredFields.every(
            (fieldName) => !isEmptyValue(formValue[fieldName])
        );
    };



    const onSubmit = useCallback(() => {
        if (props.providerId){
            updateProviderDetailsFn
                .execute(props.providerId, formValue)
                .then((res) => {
                    snackbarAlert.openSuccessSnackbarAlert(
                        t("snackbar_alert.ops_provider_updated")
                    );
                    if (props.onRefetch) {
                        props.onRefetch();
                    }
                    props.onClose();
                })
                .catch((error) => {
                    snackbarAlert.openErrorSnackbarAlert(
                        getErrorMsg(error.response.data),
                        t("snackbar_alert.occurred_error_during_updating_ops_provider")
                    );
                });
        }else {
            createOpsProviderFn
                .execute(formValue)
                .then((res) => {
                    snackbarAlert.openSuccessSnackbarAlert(
                        t("snackbar_alert.ops_provider_added")
                    );
                    if (props.onRefetch) {
                        props.onRefetch();
                    }
                    props.onClose();
                })
                .catch((error) => {
                    snackbarAlert.openErrorSnackbarAlert(
                        getErrorMsg(error.response.data),
                        t("snackbar_alert.occurred_error_during_adding_ops_provider")
                    );
                });
        }
    }, [formValue])



    return (
        <BasicDialog
            open={props.open}
            titleAlign="center"
            contentAlign="center"
            title={props.providerId? t("dialog.add_provider_dialog.edit_provider") : t("dialog.add_provider_dialog.add_provider")}
            fullScreen={false}
            showCustomFooter={false}
            showDialogActions={true}
            onClose={props.onClose}
            maxWidth={"sm"}
            showTopCloseButton={true}
        >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <TextFieldFormControl
                            name="provider_name"
                            label={t(`dialog.add_provider_dialog.provider_name`)}
                            value={formValue?.provider_name}
                            onChange={onChange}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        fullWidth
                        loading={props.isLoading}
                        disabled={!isFormValid()}
                        onClick={() => onSubmit(formValue)}
                    >
                        {props.providerId?t("universal.edit") :t("universal.create")}
                    </LoadingButton>
                </Grid>
            </Grid>
        </BasicDialog >
    )
}

ProviderDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    sipocNodeKind: PropTypes.string,
    positionX: PropTypes.number,
    positionY: PropTypes.number
};

ProviderDialog.defaultProps = {
    open: false,
    positionX: 0,
    positionY: 0,
};

export default ProviderDialog