import TextFieldFormControl from "../../field/TextFieldFormControl";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import useField from "../../../hooks/useField";

function FloatField(props) {
  const { getInputWidth } = useField();

  const valueIsBetweenMinAndMax = (value) => {
    if (value === "") {
      return true;
    }
    return (
      (props.min === undefined || parseFloat(value) >= props.min) &&
      (props.max === undefined || parseFloat(value) <= props.max)
    );
  };

  const handleInputChange = (event) => {
    var { value } = event.target;

    value = value.replace(",", ".");

    if (value === ".") {
      value = "0.";
    }

    var regexString = `^${props.allowNegative ? "-?" : ""}\\d{0,}`;
    if (props.decimalPlaces && props.decimalPlaces > 0) {
      regexString = `${regexString}(\\.\\d{0,${props.decimalPlaces}})?`;
    } else if (props.decimalPlaces === 0) {
      if (!isNaN(parseInt(value))) {
        value = parseInt(value);
        if (parseInt(props.value) === value) {
          return;
        }
      }
    }
    const regex = new RegExp(`${regexString}$`);
    if (regex.test(value) && valueIsBetweenMinAndMax(value)) {
      event.target.value = value;
      props.onChange(event);
    }
  };

  const inputProps = useMemo(() => {
    var inputPropsTemp = props.inputProps || {};
    if (inputPropsTemp.minWidth === undefined && props.fitWidthToContent) {
      if (inputPropsTemp.style === undefined) {
        inputPropsTemp.style = {};
      }
      inputPropsTemp.style.minWidth = getInputWidth(props.value, false);
    }
    return inputPropsTemp;
  }, [props.fitWidthToContent, props.value]);

  return (
    <TextFieldFormControl
      name={props.name}
      label={props.label}
      value={props.value}
      onChange={handleInputChange}
      sx={props.sx}
      disabled={props.disabled}
      readOnly={props.readOnly}
      min={props.min}
      max={props.max}
      fullWidth={props.fullWidth}
      style={props.style}
      helperText={props.helperText}
      helperTextStyle={props.helperTextStyle}
      suffix={props.suffix}
      onBlur={props.onBlur}
      inputRef={props.inputRef}
      inputProps={inputProps}
      variant={props.variant}
      required= {props.required}
    />
  );
}

FloatField.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  decimalPlaces: PropTypes.number,
  sx: PropTypes.object,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  fullWidth: PropTypes.bool,
  style: PropTypes.object,
  helperText: PropTypes.string,
  helperTextStyle: PropTypes.object,
  suffix: PropTypes.string,
  onBlur: PropTypes.func,
  fitWidthToContent: PropTypes.bool,
  inputProps: PropTypes.object,
  allowNegative: PropTypes.bool,
  variant: PropTypes.string,
  required: PropTypes.bool
};

FloatField.defaultProps = {
  decimalPlaces: 2,
  helperText: "",
  helperTextStyle: {},
  fitWidthToContent: false,
  allowNegative: false,
  variant: "outlined",
  required : false
};

export default FloatField;
