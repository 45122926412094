import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";
import DateField from "../../field/DateField";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import FloatField from "../../base/FloatField"


export default function CreateProjectForm(props) {
  const { t } = useTranslation();


  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start" 
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item xs={12}>
        <TextFieldFormControl
          name="project_name"
          label={t("form.create_project_form.project_name")}
          value={props.formValue?.project_name}
          onChange={props.onChange}
          disabled={props.readOnly}
          required 
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="project_nr"
          label={t("form.create_project_form.project_nr")}
          value={props.formValue?.project_nr}
          onChange={props.onChange}
          disabled={props.readOnly}
          required 
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="project_desc"
          label={t("form.create_project_form.project_short_desc")}
          value={props.formValue?.project_desc}
          onChange={props.onChange}
          disabled={props.readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="project_bc"
          label={t("form.create_project_form.project_bc")}
          value={props.formValue?.project_bc}
          onChange={props.onChange}
          multiline
          rows={3}
          resize={"vertical"}
          disabled={props.readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="project_scope"
          label={t("form.create_project_form.project_scope_and_desc")}
          value={props.formValue?.project_scope}
          onChange={props.onChange}
          multiline
          rows={3}
          resize={"vertical"}
          disabled={props.readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="project_constraints"
          label={t("form.create_project_form.project_constraints")}
          value={props.formValue?.project_constraints}
          onChange={props.onChange}
          multiline
          rows={3}
          resize={"vertical"}
          disabled={props.readOnly}
        />
      </Grid>
      <Grid item xs={6}>
        <DateField
          name="project_date_start"
          label={t("form.create_project_form.project_date_start")}
          value={props.formValue?.project_date_start}
          onChange={props.onChangeDate}
          disabled={props.readOnly}
          required 
        />
      </Grid>
      <Grid item xs={6}>
        <DateField
          name="project_date_end"
          label={t("form.create_project_form.project_date_end")}
          value={props.formValue?.project_date_end}
          onChange={props.onChangeDate}
          disabled={props.readOnly}
          showClearIcon = {true}
        />
      </Grid>
      <Grid item xs={6} >
        <FloatField
          name="project_sprint_long"
          label={t("form.create_project_form.project_sprint_long")}
          value={props.formValue?.project_sprint_long}
          onChange={props.onChange}
          decimalPlaces={0}
          min={1}
          suffix={t('universal.days')}
          disabled={props.readOnly}
          required 
        />
      </Grid>
      <Grid item xs={6} >
        <FloatField
          name="project_sprint_amount"
          label={t("form.create_project_form.project_sprint_amount")}
          value={props.formValue?.project_sprint_amount}
          onChange={props.onChange}
          decimalPlaces={0}
          min={1}
          disabled={props.readOnly}
        />
      </Grid>

    </Grid>
  );
}

CreateProjectForm.propTypes = {
  onChange: PropTypes.func,
  formValue: PropTypes.object,
  readOnly : PropTypes.bool
};

CreateProjectForm.defaultProps = {
  formValue: {},
  readOnly: false

};
