import {
  clientSelectListUrl,
  companySelectListUrl,
  memberCreateUrl,
  memberDetailsUrl,
  roleTeamSelectListUrl,
  userSelectListUrl,
  userTeamMembersSelectListUrl
} from "../helpers/apiUrls";
import { makeRequest } from "../helpers/makeRequest";
import { prepareUrlWithQueryParams } from "../helpers/methods";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

export default function useUserService() {
  const axiosPrivate = useAxiosPrivate();

  const getCompanySelectList = (searchParams = {}) => {
    return makeRequest(axiosPrivate, prepareUrlWithQueryParams(companySelectListUrl, searchParams));
  }

  const getClientSelectList = (searchParams = {}) => {
    return makeRequest(axiosPrivate, prepareUrlWithQueryParams(clientSelectListUrl, searchParams));
  }
  
  const getUserSelectList = (searchParams = {}) => {
    return makeRequest(axiosPrivate, prepareUrlWithQueryParams(userSelectListUrl, searchParams));
  }

  const getTeamUserSelectList = (teamId) => {
    return makeRequest(axiosPrivate, userTeamMembersSelectListUrl(teamId));
  }

  const getRoleTeamSelectList = () => {
    return makeRequest(axiosPrivate, roleTeamSelectListUrl);
  }

  const createTeamMember = ( memberData) => {
    return makeRequest(axiosPrivate, memberCreateUrl, {
      method: "POST",
      data: memberData,
    });
  };

  const updateTeamMember = (memberId, memberData) => {
    return makeRequest(axiosPrivate, memberDetailsUrl(memberId), {
      method: "PATCH",
      data: memberData,
    });
  };

  const deleteTeamMember = (memberId) => {
    return makeRequest(axiosPrivate, memberDetailsUrl(memberId), {
      method: "DELETE",
    });
  };

  return {
    getCompanySelectList,
    getClientSelectList,
    getUserSelectList,
    getRoleTeamSelectList,
    getTeamUserSelectList,
    createTeamMember,
    updateTeamMember,
    deleteTeamMember
  };
}
