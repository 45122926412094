import {
  createSipocEdgeUrl,
  createSipocNodeUrl,
  processDataUrl,
  processesByProjectListUrl,
  processSipocMapDataUrl,
  processOperationUrl,
  processUrl,
  sipocEdgeDataUrl,
  sipocNodeDataUrl,
  projectProcessDataUrl,
  projectProcessOperationUrl,
  processOpsProviderUrl,
  processMapDataUrl,
  operationUrl,
  operationNodeDataUrl,
  operationEdgeUrl,
  mapEdgeDataUrl,
  operationDetailsUrl,
  providerDataUrl,
  mapNodesRenumerateUrl,
} from "../helpers/apiUrls";
import { makeRequest } from "../helpers/makeRequest";
import { prepareUrlWithQueryParams } from "../helpers/methods";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

export default function useProcessService() {
  const axiosPrivate = useAxiosPrivate();


  const getProcessList = (projectId) => {
    return makeRequest(axiosPrivate, processesByProjectListUrl(projectId));
  }

  const createProcess = (dataToSend) => {
    return axiosPrivate(processUrl, {
      method: "POST",
      data: dataToSend,
    });
  };

  const updateProcess = (processId, dataToSend) => {
    return axiosPrivate(processDataUrl(processId), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const getProcessData = (processId) => {
    return makeRequest(axiosPrivate, processDataUrl(processId));
  }

  const deleteProjectProcess = (projectProcessId) => {
    return makeRequest(axiosPrivate, projectProcessDataUrl(projectProcessId), {
      method: "DELETE",
    });
  };

  const createSipocNode = (dataToSend) => {
    return axiosPrivate(createSipocNodeUrl, {
      method: "POST",
      data: dataToSend,
    });
  };

  const updateSipocNode = (nodeId, dataToSend) => {
    return axiosPrivate(sipocNodeDataUrl(nodeId), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const deleteSipocNode = (nodeId) => {
    return axiosPrivate(sipocNodeDataUrl(nodeId), {
      method: "DELETE",
    });
  };


  const getProcessSipocMapData = (processId) => {
    return makeRequest(axiosPrivate, processSipocMapDataUrl(processId));
  }

  const getProcessMapData = (processId) => {
    return makeRequest(axiosPrivate, processMapDataUrl(processId));
  }

  const createSipocEdge = (dataToSend) => {
    return axiosPrivate(createSipocEdgeUrl, {
      method: "POST",
      data: dataToSend,
    });
  };


  const deleteSipocEdge = (edgeId) => {
    return axiosPrivate(sipocEdgeDataUrl(edgeId), {
      method: "DELETE",
    });
  };
  const getProjectProcessOperation = (searchParams = {}) => {
    return makeRequest(axiosPrivate, prepareUrlWithQueryParams(projectProcessOperationUrl, searchParams));
  }

  const getProcessOperation = (searchParams = {}) => {
    return makeRequest(axiosPrivate, prepareUrlWithQueryParams(processOperationUrl, searchParams));
  }

  const createOpsProvider = (dataToSend) => {
    return axiosPrivate(processOpsProviderUrl, {
      method: "POST",
      data: dataToSend,
    });
  };

  const getProviderDetails = (providerId) => {
    return makeRequest(axiosPrivate, providerDataUrl(providerId));
  }

  const updateProviderDetails = (providerId, dataToSend) => {
    return axiosPrivate(providerDataUrl(providerId), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const deleteProvider = (providerId) => {
    return axiosPrivate(providerDataUrl(providerId), {
      method: "DELETE",
    });
  };

  const createOperation = (dataToSend) => {
    return axiosPrivate(operationUrl, {
      method: "POST",
      data: dataToSend,
    });
  };

  const updateOperationNode = (nodeId, dataToSend) => {
    return axiosPrivate(operationNodeDataUrl(nodeId), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const createOperationEdge = (dataToSend) => {
    return axiosPrivate(operationEdgeUrl, {
      method: "POST",
      data: dataToSend,
    });
  };

  const deleteOperationEdge = (edgeId) => {
    return axiosPrivate(mapEdgeDataUrl(edgeId), {
      method: "DELETE",
    });
  };

  const getOperationIdDetails = (operationId) => {
    return makeRequest(axiosPrivate, operationDetailsUrl(operationId));
  };

  const updateOperation = (operationId, dataToSend) => {
    return axiosPrivate(operationDetailsUrl(operationId), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const deleteOperation = (operationId) => {
    return axiosPrivate(operationDetailsUrl(operationId), {
      method: "DELETE",
    });
  };

  const renumerateOperationNodes = (procesId) => {
    return axiosPrivate(mapNodesRenumerateUrl(procesId), {
      method: "POST",
      data: {},
    });
  };
  

  return {
    getProcessList,
    createProcess,
    updateProcess,
    getProcessData,
    deleteProjectProcess,
    createSipocNode,
    getProcessSipocMapData,
    updateSipocNode,
    deleteSipocNode,
    createSipocEdge,
    deleteSipocEdge,
    getProjectProcessOperation,
    getProcessOperation,
    createOpsProvider,
    getProviderDetails,
    updateProviderDetails,
    getProcessMapData,
    createOperation,
    updateOperationNode,
    deleteProvider,
    createOperationEdge,
    deleteOperationEdge,
    getOperationIdDetails,
    updateOperation,
    deleteOperation,
    renumerateOperationNodes
  };
}
