import { useSearchParams } from "react-router-dom";
import { getObjectFromSearchParams } from "../helpers/methods";
import usePaginationWithSearchParams from "./usePaginationWithSearchParams";
import { isEmptyValue } from "../helpers/methods";

const useFillterButton = (filterPrefix = undefined) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { getFilterFieldKey } = usePaginationWithSearchParams(filterPrefix);

  const handleClickFilterButton = (filterValue, filterKey) => {
    setSearchParams((prev) => {
      filterKey = getFilterFieldKey(filterKey);
      const pageKey = getFilterFieldKey("page");
      const currSearchParamsObject = getObjectFromSearchParams(searchParams, [
        pageKey,
      ]);

      if (isEmptyValue(filterValue)) {
        delete currSearchParamsObject[[filterKey]];
        return currSearchParamsObject;
      }
      return { ...currSearchParamsObject, [filterKey]: filterValue };
    });
  };

  return {
    handleClickFilterButton,
  };
};

export default useFillterButton;
