import BasicDialog from "../../base/BasicDialog"
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { useForm } from "../../../hooks/useForm"
import LoadingButton from "@mui/lab/LoadingButton";
import { getErrorMsg, isEmptyValue } from "../../../helpers/methods";
import { useCallback, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import useProcessService from "../../../services/processService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import OperationNodeForm from "../../form/OperationNodeForm/OperationNodeForm";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import useSipocMapService from "../../../services/sipocMapService";

function OperationDialog(props) {
    const { t } = useTranslation();

    const [width, setWidth] = useState(100);
    const [height, setHeight] = useState(75);
    const snackbarAlert = useSnackbarAlert();
    const decisionWidthRatio = 1.3
    const decisionHeightRato = decisionWidthRatio * 0.6


    const requiredFields = ["ops_text"];

    const {
        createOperation,
        getOperationIdDetails,
        updateOperation
    } = useProcessService()

    const createOperationFn = useAsyncFn(createOperation);
    const updateOperationFn = useAsyncFn(updateOperation);

    const {
        calcualteDetaYCoordinate
    } = useSipocMapService()

    const {
        formValue,
        setFormValue,
        onChange
    } = useForm({
        'ops_provider': props.opsProvider ? props.opsProvider.id : null,
        'ops_coordinate_x': props.XCoordinate,
        'ops_coordinate_y': props.opsProvider ? calcualteDetaYCoordinate(props.YCoordinate, props.opsProvider): props.YCoordinate,
        'process': props.processId,
        'project': props.projectId,
        'shape': props.operationKind,
        'ops_color' : props.nodeColor
    })




    const operationNodeData = useAsync(() => {
        if (props.operationId) {
            return getOperationIdDetails(props.operationId).then((res) => {
                setFormValue(res)
            })
                .catch((error) => {
                    snackbarAlert.openErrorSnackbarAlert(
                        t("snackbar_alert.occurred_error_during_get_data")
                    );
                });
        }
        return Promise.resolve(formValue)
    }, [props.operationId])


    const isFormValid = () => {
        return requiredFields.every(
            (fieldName) => !isEmptyValue(formValue[fieldName])
        );
    };

    const onPrepareData = (data) => {
        return {
            ...data,
            ops_dimension_h: height,
            ops_dimension_w: width
        }
    }

    const onSubmit = useCallback(() => {
        if (props.operationId){
            updateOperationFn
            .execute(props.operationId, onPrepareData(formValue))
            .then((res) => {
                snackbarAlert.openSuccessSnackbarAlert(
                    t("snackbar_alert.operation_updated")
                );
                if (props.onRefetch) {
                    props.onRefetch();
                }
                props.onClose();
            })
            .catch((error) => {
                snackbarAlert.openErrorSnackbarAlert(
                    getErrorMsg(error.response.data),
                    t("snackbar_alert.occurred_error_during_updating_operation")
                );
            });
        }
        else{
        createOperationFn
            .execute(onPrepareData(formValue))
            .then((res) => {
                snackbarAlert.openSuccessSnackbarAlert(
                    t("snackbar_alert.operation_added")
                );
                if (props.onRefetch) {
                    props.onRefetch();
                }
                props.onClose();
            })
            .catch((error) => {
                snackbarAlert.openErrorSnackbarAlert(
                    getErrorMsg(error.response.data),
                    t("snackbar_alert.occurred_error_during_adding_operation")
                );
            });
        }
    }, [formValue])

    const getTextDimensions = (text, fontSize = "15px", fontFamily = "Arial", lineHeight = "1.5") => {
        const tempDiv = document.createElement("div");
        text = 
        tempDiv.innerText = text;

        tempDiv.style.fontSize = fontSize;
        tempDiv.style.fontFamily = fontFamily;
        tempDiv.style.lineHeight = lineHeight;
        tempDiv.style.position = "absolute";
        tempDiv.style.visibility = "hidden";
        tempDiv.style.whiteSpaceCollapse = "breakSpaces";
        tempDiv.style.width = "auto";
        tempDiv.style.height = "auto";

        document.body.appendChild(tempDiv);

        const width = tempDiv.offsetWidth;
        const height = tempDiv.offsetHeight;

        document.body.removeChild(tempDiv);

        return { width, height };
    }

    useEffect(() => {
        let text = `${formValue?.ops_nr}. ${formValue?.ops_text}`
        const dimensions = getTextDimensions(text);
        setWidth(Math.max(100, props.operationKind === "activity_shape" ? dimensions.width : dimensions.width * decisionWidthRatio))
        setHeight(Math.max(formValue?.shape === "decision_shape" ? 150: 75, formValue?.shape === "decision_shape" ? decisionHeightRato * dimensions.width: dimensions.height ))
    }, [formValue?.ops_text, formValue?.ops_nr])




    return (
        <BasicDialog
            open={props.open}
            titleAlign="center"
            contentAlign="center"
            title={props.operationId ? t("dialog.operation_dialog.edit_operation") : t("dialog.operation_dialog.add_operation")}
            fullScreen={false}
            showCustomFooter={false}
            showDialogActions={true}
            onClose={props.onClose}
            maxWidth={"sm"}
            showTopCloseButton={true}
        >
            <LoaderWrapper showLoader={operationNodeData.loading}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <OperationNodeForm
                            formValue={formValue}
                            operationKind={props.operationKind}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            fullWidth
                            loading={props.isLoading}
                            disabled={!isFormValid()}
                            onClick={() => onSubmit(formValue)}
                        >
                            {props.operationId ? t("universal.edit"): t("universal.create")}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </LoaderWrapper>
        </BasicDialog >
    )
}

OperationDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    opsProvider: PropTypes.string,
    XCoordinate: PropTypes.number,
    YCoordinate: PropTypes.number,
    operationKind: PropTypes.string,
    projectId: PropTypes.string,
    processId: PropTypes.string,
    operationKind: PropTypes.string
};

OperationDialog.defaultProps = {
    open: false,
    XCoordinate: 0,
    YCoordinate: 0,
};

export default OperationDialog