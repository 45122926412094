import { LANGUAGE_KEY_IN_LOCAL_STORAGE } from "../helpers/constants";

const LocalstorageService = {
  getLanguage: function () {
    const language = localStorage.getItem(LANGUAGE_KEY_IN_LOCAL_STORAGE);

    if ([null, undefined].includes(language)) {
      return "pl";
    }

    return JSON.parse(language);
  },
};

export default LocalstorageService;
