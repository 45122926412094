import React, { useState } from "react"

import Grid from '@mui/material/Grid'
import { useSnackbarAlert } from "../../context/snackbarAlert"
import { useTranslation } from "react-i18next"
import {useAsyncFn} from "../../hooks/useAsync"
import RegistrationForm from "../../forms/RegistrationForm/RegistrationForm"
import useRegistrationService from "../../services/registrationService"
import { useNavigate } from "react-router-dom"
import { getErrorMsg } from "../../helpers/methods"

export default function RegistrationPage(props) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {register} = useRegistrationService();
    const registerFn = useAsyncFn(register);

    const isLoading = registerFn.loading

    const snackbarAlert = useSnackbarAlert();

    const onClickSubmit = (registrationData) => {
        registerFn
            .execute(registrationData)
            .then(() => {
                navigate("/login/")
                snackbarAlert.openSuccessSnackbarAlert(t("snackbar_alert.activation_email_has_been_sent"));
            })
            .catch((error) => {

                if (error.status === 400) {
                    
                    snackbarAlert.openErrorSnackbarAlert(
                        getErrorMsg(error.data),
                        t("snackbar_alert.bad_data")
                    );
                } else {
                    snackbarAlert.openErrorSnackbarAlert(
                        getErrorMsg(error.data),
                        t("snackbar_alert.server_error")
                    );
                }
            });
    };

    return (
        <Grid container direction="row" justifyContent={"flex-start"} alignItems="center">
            <Grid item sm={2} xs={0} />
            <Grid item sm={8} xs={12}>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <RegistrationForm onClickSubmit={onClickSubmit} showLoadingButton={isLoading} />
                </Grid>
            </Grid>
            <Grid item sm={2} xs={0} />
        </Grid>
    )
}

RegistrationPage.propTypes = {

}