import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useAsync } from "../../hooks/useAsync";
import { Grid } from "@mui/material";
import CardIconWithText from "../../components/other/CardIconWithText";
import ProjectDashboardBar from "../../components/bar/ProjectDashboardBar";
import useProjectService from "../../services/projectService";
import ProjectRegistrationDialog from "../../components/dialog/ProjectRegistrationDialog";
import useDialog from "../../hooks/useDialog";


const cardAreaStyle = {
  height: "27vh",
  alignItems: "center",
  textAlign: "center"
};

const GRID_COLS = 4;
const GRID_ROWS = 3;

const generateGrid = (
  gridRows,
  gridCols,
  gridConfig,
  generateConfigElement
) => {
  const rowsArray = Array.from({ length: gridRows }, (x, i) => i + 1);
  const colsArray = Array.from({ length: gridCols }, (x, i) => i + 1);

  return (
    <>
      {rowsArray.map((row) =>
        colsArray.map((col) => {
          const configElement = generateConfigElement(
            gridConfig[`${row}_${col}`]
          );
          return (
            <Grid
              item
              key={`${row}_${col}`}
              xs={gridCols / 2}
              sm={gridCols / 3}
              lg={1}
              sx={{
                display: { xs: configElement ? "block" : "none", lg: "block" },
              }}
            >
              {configElement}
            </Grid>
          );
        })
      )}
    </>
  );
};

export default function DashboardPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [project, setProject] = useState()

  const location = useLocation();
  const returnedProject = location.state || {};

  useEffect(() => {
    if (returnedProject && returnedProject.projectId) {
      setProject(returnedProject.projectId)
    }
  }, [returnedProject]);

 
  const { getProjectSelectList,
    getProjectAccess
   } = useProjectService();

  const projectSelectList = useAsync(getProjectSelectList)

  const projectAccessData = useAsync(() => {
    if (project){
      return getProjectAccess(project)
    }
    return Promise.resolve({})
  }, [project])

  const [
    openNewProjectDialog,
    onOpenNewProjectDialog,
    handleCloseNewProjectDialog,
  ] = useDialog();

  const onChangeProject = useCallback((e) => {
    const { name, value } = e.target;
    setProject(value)
  }, [project]);

  const onClearProject  = useCallback(() => {
    setProject(undefined)
  }, [project]);

  const onOpenNewProject = useCallback(() => {
    setProject(undefined)
    onOpenNewProjectDialog()
  }, [project]);

  const config = {
    "1_1": {
      label: t("pages.dashboard_page.team"),
      isActive: projectAccessData?.value?.is_team_icon_active,
      onClick: () => navigate(`/project/${project}/team/`),
    },
    "1_2": {
      label: t("pages.dashboard_page.process"),
      isActive: projectAccessData?.value?.is_process_icon_active,
      onClick: () => navigate(`/project/${project}/process/`),
    },
    "1_3": {
      label: t("pages.dashboard_page.screens"),
      isActive: projectAccessData?.value?.is_screens_icon_active,
      onClick: () => navigate(`/project/${project}/screens/`),
    },
    "1_4": {
      label: t("pages.dashboard_page.pbl"),
      isActive: projectAccessData?.value?.is_product_backlog_icon_active,
      onClick: () => navigate(`/project/${project}/product_backlog/`),
    },
    "2_1": {
      label: t("pages.dashboard_page.events"),
      isActive: projectAccessData?.value?.is_events_icon_active,
      onClick: () => navigate(`/project/${project}/events/`),
    },
    "2_2": {
      label: t("pages.dashboard_page.user_stories"),
      isActive: projectAccessData?.value?.is_user_stories_icon_active,
      onClick: () => navigate(`/project/${project}/user_stories/`),
    },
    "2_3": {
      label: t("pages.dashboard_page.kanban"),
      isActive: projectAccessData?.value?.is_kanban_icon_active,
      onClick: () => navigate(`/project/${project}/kanban/`),
    },
    "2_4": {
      label: t("pages.dashboard_page.tests"),
      isActive: projectAccessData?.value?.is_tests_icon_active,
      onClick: () => navigate(`/project/${project}/tests/`),
    },
    "3_1": {
      label: t("pages.dashboard_page.go_live"),
      isActive: projectAccessData?.value?.is_go_live_icon_active,
      onClick: () => navigate(`/project/${project}/go_live/`),
    },
    "3_2": {
      label: t("pages.dashboard_page.retro"),
      isActive: projectAccessData?.value?.is_retro_icon_active,
      onClick: () => navigate(`/project/${project}/retro/`),
    },
    "3_3": {
      label: t("pages.dashboard_page.docs"),
      isActive: projectAccessData?.value?.is_docs_icon_active,
      onClick: () => navigate(`/project/${project}/docs/`),
    },
    "3_4": {
      label: t("pages.dashboard_page.reports"),
      isActive: projectAccessData?.value?.is_reports_icon_active,
      onClick: () => navigate(`/project/${project}/reports/`),
    },
  };

  const getCardIconWithText = (config) => {
    if (!config) return null;

    return (
      <CardIconWithText
        cardAreaStyle={cardAreaStyle}
        label={config.label}
        isActive={config.isActive}
        onClick={config.onClick}
      />
    );
  };

  return (
    <Grid
      container
      direction="rows"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      marginY={{ xs: 1, sm: 0 }}
      rowGap={{ xs: 1, sm: 0 }}
      paddingX={1}
      columns={GRID_COLS}
      sx={{ alignContent: 'center' }}

    >
      <Grid item xs={12}>
        <ProjectDashboardBar
          projects={projectSelectList?.value}
          selectedProjectId={project}
          onChange={onChangeProject}
          onCreateNewProject={onOpenNewProject}
          onOpenStepper = {onOpenNewProjectDialog}
          onClear = {onClearProject}
        />
      </Grid>
      {generateGrid(GRID_ROWS, GRID_COLS, config, getCardIconWithText)}
      {openNewProjectDialog &&
        <ProjectRegistrationDialog
          open={openNewProjectDialog}
          onClose={handleCloseNewProjectDialog}
          selectedProjectId={project}
          onRefetch={projectSelectList.refetch}
        />
      }
    </Grid>
  );
}
