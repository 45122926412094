import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';

export default function BasicDialog(props) {

  const { t } = useTranslation();
  const getLabelCloseButton = () => {
    if (props.closeButtonLabel) {
      return props.closeButtonLabel;
    }
    return t("universal.close");
  };

  return (
    <Dialog
      fullWidth
      open={props.open}
      maxWidth={props.maxWidth}
      sx={{ display: props.open ? undefined : "none", ...props.dialogSx }}
      fullScreen={props.fullScreen}
    >
      {props.showTitle && (
        <DialogTitle
          align={props.titleAlign}
          style={{ color: "var(--primary)", fontSize: props.titleFontSize }}
          sx={{
            "& .MuiDialogTitle-root": {
              color: "var(--primary)",
            },
            ...props.titleSx,
          }}
        >
          {props.title.charAt(0).toUpperCase() + props.title.slice(1)}
        </DialogTitle>
      )}
      {props.showTopCloseButton &&
      <IconButton
        aria-label="close"
        onClick={props.onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 16,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>}
      {
        props.showTopActionButton &&
        <Grid
          item xs={12}
          style={{ padding: "10px" }}
          alignItems="flex-start"
        >
          {props.topActionButton}
        </Grid>
      }

      <DialogContent dividers={props.dividers} align={props.contentAlign} sx={{ ...props.contentSx }}>
        {props.children}
      </DialogContent>

      {
        props.showBottomActionButton &&
        <Grid
          container
          style = {props.bottomActionStyle}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          {props.bottomActionButton}
        </Grid>
      }
      {!props.showCustomFooter && props.showDialogActions && (
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            onClick={props.onClose}
            sx={props.closeButtonSx}
            disabled={props.disableOnCloseButton}
          >
            {getLabelCloseButton()}
          </Button>
        </DialogActions>
      )}
      {props.showCustomFooter && (
        <DialogActions sx={{ alignItems: "flex-end" }}>
          {props.customFooter}
          <Button
            variant="contained"
            size="small"
            onClick={props.onClose}
            sx={{ vertialAlign: "bottom" }}
          >
            {getLabelCloseButton()}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

BasicDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  titleFontSize: PropTypes.string,
  titleSx: PropTypes.object,
  content: PropTypes.any,
  onClose: PropTypes.func,
  titleAlign: PropTypes.oneOf(["right", "left", "center"]),
  closeButtonLabel: PropTypes.string,
  contentAlign: PropTypes.oneOf(["right", "left", "center"]),
  dividers: PropTypes.bool,
  showTitle: PropTypes.bool,
  maxWidth: PropTypes.string,
  fullScreen: PropTypes.bool,
  showDialogActions: PropTypes.bool,
  showCustomFooter: PropTypes.bool,
  customFooter: PropTypes.element,
  disableOnCloseButton: PropTypes.bool,
  closeButtonSx: PropTypes.object,
  showTopActionButton: PropTypes.bool,
  topActionButton: PropTypes.object,
  showBottomActionButton: PropTypes.bool,
  bottomActionButton: PropTypes.object,
  dialogSx: PropTypes.object,
  showTopCloseButton : PropTypes.bool
};
BasicDialog.defaultProps = {
  open: false,
  titleAlign: "left",
  titleFontSize: "24px",
  titleSx: {},
  contentAlign: "center",
  dividers: true,
  showTitle: true,
  maxWidth: "lg",
  fullScreen: false,
  showDialogActions: true,
  customFooter: null,
  showTopActionButton: false,
  showBottomActionButton: false,
  dialogSx: {},
  showTopCloseButton : true
};
