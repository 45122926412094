import { NUMERIC_FIELD_TYPE, TEXT_FIELD_TYPE, SELECT_FIELD_TYPE } from "../../helpers/constants";
import { capitalizeFirstLetter } from "../../helpers/methods";
import i18n from "../../i18n";

export const USER_STORY_TABLE_FIELDS_CONFIG = [
    {
        name: "name",
        getValueCallback: (rowData) => `${capitalizeFirstLetter(i18n.t(`form.user_story.as`))} ${rowData.us_as} ${i18n.t(`form.user_story.want`)} ${rowData.us_want} ${i18n.t(`form.user_story.in_order`)} ${rowData.us_in_order}.`,
        label: i18n.t("table.user_story.name"),
        filterType: TEXT_FIELD_TYPE,
    },
    {
        name: "us_category",
        getValueCallback: (rowData) => rowData.us_category,
        label: i18n.t("table.user_story.category"),
        filterType: SELECT_FIELD_TYPE,
    },
    {
        name: "us_priority",
        getValueCallback: (rowData) => rowData.us_priority,
        label: i18n.t("table.user_story.priority"),
        filterType: NUMERIC_FIELD_TYPE,
    }
]