import * as React from "react";
import { StyledTableCell, StyledTableHead, StyledTableRow } from "../../base/BaseTable/BaseTable";
import { Paper, Table, TableBody, TableContainer, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import CheckboxFieldTable from "../../field/CheckboxFieldTable/CheckboxFieldTable";

const ProcessOperationTable = React.memo((props) => {

    console.log('props', props)

    const { t } = useTranslation();

    const theme = useTheme()

    const Headers = () => {
        return (
            <StyledTableHead>
                <StyledTableRow>
                    <StyledTableCell
                        className="capitalize-first-letter"
                        align="center"
                        sx={{ whiteSpace: "nowrap" }}
                    >
                        {t("table.process_operation.activity")}
                    </StyledTableCell>
                    <StyledTableCell
                        className="capitalize-first-letter"
                        align="center"
                        sx={{ whiteSpace: "nowrap" }}
                    >
                        {t("table.process_operation.coverage")}
                    </StyledTableCell>
                </StyledTableRow>
            </StyledTableHead>
        )
    }

    const Body = () => {
        return (
            <TableBody>
                {props.processes.map((process) => (
                    <ProcessOperation
                        process={process}
                    />
                ))}
            </TableBody>
        )
    }

    const ProcessOperation = ({ process }) => {
        return (
            <>
                <StyledTableRow>
                    <StyledTableCell colSpan={2} sx={{ textAlign: 'center', backgroundColor: theme.palette.primary.light, color: 'white' }}>
                        {`${process.process_nr}. ${process.process_name}`}
                    </StyledTableCell>
                </StyledTableRow>
                {process.operations.map((operation) => (
                    <StyledTableRow>
                        <StyledTableCell sx={{ textAlign: 'justify' }}>
                            {`${process.process_nr}.${operation.ops_nr} ${operation.ops_text}`}
                        </StyledTableCell>
                        <StyledTableCell>
                            <CheckboxFieldTable
                                value={operation.coverage}
                                editable={false}
                            />
                        </StyledTableCell>
                    </StyledTableRow>
                ))}
            </>
        )
    }

    return (
        <TableContainer component={Paper} style={props.style}>
            <Table>
                <Headers />
                <Body />
            </Table>
        </TableContainer>
    )
})

export default ProcessOperationTable;