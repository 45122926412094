import { priorityParamUrl, productBacklogDetailUrl, productBacklogsUrl, userStoryDetailUrl, userStoryUrl } from "../helpers/apiUrls";
import { makeRequest } from "../helpers/makeRequest";
import { prepareUrlWithQueryParams } from "../helpers/methods";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

export default function useBacklogsService() {

  const axiosPrivate = useAxiosPrivate();

  const getProductBacklogs = (searchParams = {}) => {
    return makeRequest(axiosPrivate, prepareUrlWithQueryParams(productBacklogsUrl, searchParams));
  }

  const getProductBacklogsForProject = (projectId) => {
    const searchParams = { project: projectId }
    return getProductBacklogs(searchParams)
  }

  const createProductBacklog = (dataToSend) => {
    return axiosPrivate(productBacklogsUrl, {
      method: "POST",
      data: dataToSend,
    });
  };

  const updateProductBacklog = (productBacklogId, dataToSend) => {
    return axiosPrivate(productBacklogDetailUrl(productBacklogId), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const getProductBacklogData = (productBacklogId) => {
    return makeRequest(axiosPrivate, productBacklogDetailUrl(productBacklogId));
  }

  const getUserStory = (searchParams = {}) => {
    return makeRequest(axiosPrivate, prepareUrlWithQueryParams(userStoryUrl, searchParams));
  }

  const createUserStory = (dataToSend) => {
    return axiosPrivate(userStoryUrl, {
      method: "POST",
      data: dataToSend,
    });
  }

  const updateUserStory = (userStoryId, dataToSend) => {
    return axiosPrivate(userStoryDetailUrl(userStoryId), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const getPriorityParam = (searchParams = {}) => {
    return makeRequest(axiosPrivate, prepareUrlWithQueryParams(priorityParamUrl, searchParams));
  }


  return {
    getProductBacklogs,
    getProductBacklogsForProject,
    createProductBacklog,
    updateProductBacklog,
    getProductBacklogData,
    getUserStory,
    createUserStory,
    updateUserStory,
    getPriorityParam
  };
}