import {
  addressKindSelectListUrl,
} from "../helpers/apiUrls";
import { makeRequest } from "../helpers/makeRequest";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

export default function useAddressService() {
  const axiosPrivate = useAxiosPrivate();

  const getAddressKindelectList = () => {
    return makeRequest(axiosPrivate, addressKindSelectListUrl);
  }

  return {
    getAddressKindelectList,
  };
}
