import * as React from 'react';
import { Grid } from '@mui/material';
import CreateProjectForm from '../../../form/CreateProjectForm';
import PropTypes from "prop-types";


export default function CreateProjectStep(props) {


  return (
   
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
        style={{ marginTop: "5px" }}
      >
        <Grid item xs={4}>
          <CreateProjectForm
            formValue={props.formValue}
            onChange={props.onChange}
            onChangeDate={props.onChangeDate}
          />
        </Grid>
      </Grid>
    
  );
}

CreateProjectStep.propTypes = {
  formValue: PropTypes.object,
  onChange: PropTypes.func,
  onChangeDate: PropTypes.func
};

CreateProjectStep.defaultProps = {
};

