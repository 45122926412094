const WindowService = {
    showEmailWindow: function (mailTo ="", subject="Temat", body="") {
        var mailToString = `mailto:${mailTo}?`
        if(subject.length > 0){
            mailToString = `${mailToString}subject=${subject}&body=${encodeURIComponent(body)}`
        }
        else{
            mailToString = `${mailToString}body=${encodeURIComponent(body)}`
        }
        window.open(mailToString, "_self", 'PopUp1');
    },
    openInNewTab: function(url) {
        window.open(url, '_blank').focus();
    }
}

export default WindowService