import React, { memo } from 'react';
import './buttonSipocNode.css';
import Tooltip from "@mui/material/Tooltip";


const SipocButtonNode = memo(({
  data,
  style
}) => {
  const showTooltip = data?.tooltipLabel !== undefined
  return (
    <Tooltip title={data?.tooltipLabel} disableHoverListener={!showTooltip}>
    <div className='nodegometry' style={{ ...style}}>
      <div className='buttonNode' onClick={()=>data.onAddNode(data.parentId)} style = {{backgroundColor:data.buttonCollor}}>
        +
      </div>
    </div>
    </Tooltip>
  );
});

export default SipocButtonNode ;
