import React from "react";
import PropTypes from "prop-types";
import { Draggable } from '@hello-pangea/dnd';
import styled from '@emotion/styled';
import PersonIcon from '@mui/icons-material/Person';
import ContextMenu from "../../wrapper/ContextMenu";

const TaskInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 15px;
  min-height: 106px;
  border-radius: 5px;
  max-width: 311px;
  /* background: ${({ isDragging }) =>
    isDragging ? 'rgba(255, 59, 59, 0.15)' : 'white'}; */
  background: white;
  margin-top: 15px;

  .secondary-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 12px;
    font-weight: 400px;
    color: #7d7d7d;
  }
  /* .priority{ */
  /* margin-right: 12px; */
  /* align-self: center;
    svg{
      width: 12px !important;
      height: 12px !important;
      margin-right: 12px; */
  /* margin-top: 2px; */
  /* } */
  /* } */
`;

const PersonCard = ({ item, index, contextMenuActions, showContextMenuActions }) => {
  
 
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided) => (
        
        <ContextMenu
        key={item.id}
        showMenu={showContextMenuActions}
        actions={contextMenuActions.map((action) => ({
          label: action.label,
          icon: action.icon,
          callback: () => action.callback(item.id),
        }))}
        Component={
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            userSelect: 'none',
            padding: '5px',
            margin: '0 0 1px 0',
            width: '100%',
            minHeight: '50px',
            backgroundColor: item.is_registred_user ?'#456C86' : '#B3B3B3',
            color: 'white',
            borderRadius: "25px",
            ...provided.draggableProps.style,
          }}
        >
          <PersonIcon
            fontSize="large"
          />
          <p
            style={{
              padding: "5px",
              margin: "0px"
            }}
          >{item.member_name}</p>
        </div>}/>
      )}
    </Draggable>
  );

};

PersonCard.propTypes = {
  user: PropTypes.string,

};

PersonCard.defaultProps = {

};

export default PersonCard;
