import { useCallback, useState } from "react";
import DateService from "../services/dateService";

export const useForm = (initialFormValue) => {
  const [formValue, setFormValue] = useState(() =>
    typeof initialFormValue === "function"
      ? initialFormValue()
      : initialFormValue
  );

  const onChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormValue((formValue) => ({ ...formValue, [name]: value }));

    return { name, value };
  }, []);

  const onChangeWithCallback = useCallback(
    (callback) => {
      return (e) => {
        const { name, value } = onChange(e);
        callback(name, value);
      };
    },
    [onChange]
  );

  const onChangeInArrayForm = useCallback((index, e) => {
    setFormValue((formValue) =>
      formValue.map((element, _index) =>
        _index === index
          ? {
              ...element,
              [e.target.name]: e.target.value,
            }
          : element
      )
    );
  }, []);

  const onChangeDate = useCallback((e) => {
    const { name, value } = e.target;
    const newValue = DateService.convertDateToFormatYYYYMMDD(value);
    setFormValue((formValue) => ({
      ...formValue,
      [name]: newValue,
    }));

    return { name, value: newValue};
  }, []);

  const onChangeDateInArrayForm = useCallback((index, e) => {
    setFormValue((formValue) =>
      formValue.map((element, _index) =>
        _index === index
          ? {
              ...element,
              [e.target.name]: DateService.convertDateToFormatYYYYMMDD(
                e.target.value
              ),
            }
          : element
      )
    );
  }, []);

  const onChangeDateWithCallback = useCallback(
    (callback) => {
      return (e) => {
        const { name, value } = onChangeDate(e);
        callback(name, value);
      };
    },
    [onChangeDate]
  );

  const onChangeAutocompleteFieldWithObjectOptions = useCallback(
    (e, value_object, value_key, state_value_name) => {
      let newFormValue = Array.isArray(value_object)
        ? value_object.map((option) =>
            typeof option === "object" ? option[value_key] : option
          )
        : value_object[value_key];
      if (newFormValue === undefined) {
        newFormValue = null;
      }
      setFormValue((formValue) => ({
        ...formValue,
        [state_value_name]: newFormValue,
      }));

      return { name: state_value_name, value: newFormValue };
    },
    []
  );

  const onChangeAutocompleteFieldWithObjectOptionsAndCallback = useCallback(
    (callback) => {
      return (...inputProps) => {
        const { name, value, index } =
          onChangeAutocompleteFieldWithObjectOptions(...inputProps);

        callback(name, value, index);
      };
    },
    [onChangeAutocompleteFieldWithObjectOptions]
  );

  const onChangeAutocompleteFieldWithObjectOptionsInArrayForm = useCallback(
    (index, e, value_object, value_key, state_value_name) => {
      const newElementValue = Array.isArray(value_object)
        ? value_object.map((option) =>
            typeof option === "object" ? option[value_key] : option
          )
        : value_object[value_key];

      setFormValue((formValue) =>
        formValue.map((element, _index) =>
          _index === index
            ? {
                ...element,
                [state_value_name]: newElementValue,
              }
            : element
        )
      );
    },
    []
  );

  return {
    formValue,
    setFormValue,
    onChange,
    onChangeWithCallback,
    onChangeInArrayForm,
    onChangeDate,
    onChangeDateWithCallback,
    onChangeDateInArrayForm,
    onChangeAutocompleteFieldWithObjectOptions,
    onChangeAutocompleteFieldWithObjectOptionsAndCallback,
    onChangeAutocompleteFieldWithObjectOptionsInArrayForm,
  };
};
