import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useState } from "react";
import { useForm } from '../../../hooks/useForm';
import useUserService from '../../../services/userService';
import { useAsync, useAsyncFn } from '../../../hooks/useAsync';
import useAddressService from '../../../services/addressService';
import useProjectService from '../../../services/projectService';
import { useSnackbarAlert } from '../../../context/snackbarAlert';
import ProjectDataStep from './ProjectDataStep';
import CompanyStep from './CompanyStep';
import CustomerStep from './CustomerStep';
import LoaderWrapper from '../../wrapper/LoaderWrapper';
import { isEmptyValue } from '../../../helpers/methods';


export default function CreateProjectStepper(props) {

  const { t } = useTranslation();

  const { getCompanySelectList, getClientSelectList } = useUserService();
  const { getAddressKindelectList } = useAddressService();
  const { createProject, getProjectFullData } = useProjectService();


  const snackbarAlert = useSnackbarAlert();

  // const steps = ['project_data', 'owner_data', 'client_data'];
  const steps = ['project_data', 'owner_data', ];
  // const [activeStep, setActiveStep] = useState(props.selectedProjectId ? 3 : 0);
  const [activeStep, setActiveStep] = useState(props.selectedProjectId ? 2 : 0);
  const [skipped, setSkipped] = useState(new Set());


  const {
    formValue: projectForm,
    setFormValue: setProjectForm,
    onChange: onProjectFormChange,
    onChangeDate: onProjectChangeDate
  } = useForm({});

  const {
    formValue: companyForm,
    setFormValue: setCompanyForm,
    onChange: onCompanyFormChange,
  } = useForm({ 'company_is_customer': false });

  const {
    formValue: companyAddressForm,
    setFormValue : setCompanyAddressForm,
    onChange: onCompanyAddressFormChange,
  } = useForm({});

  const {
    formValue: clientForm,
    setFormValue : setClientForm,
    onChange: onClientFormChange,
  } = useForm({ 'company_is_customer': true });

  const {
    formValue: clientAddressForm,
    setFormValue : setClientAddressForm,
    onChange: onClientAddressFormChange,
  } = useForm({});

  const onCompanyChange =  React.useCallback((e) => {
    const { name, value } = e.target;
    if (name === 'company'){
      setCompanyForm( { company: value })
      setCompanyAddressForm({})
    }else{
      setCompanyForm((prev) => {
        return { ...prev, company: undefined };
      });
      onCompanyFormChange(e)
    }
  },[companyForm])

  const onClientChange =  React.useCallback((e) => {
    const { name, value } = e.target;
    if (name === 'client'){
      setClientForm( { client: value, 'company_is_customer': true  })
      setClientAddressForm({})
    }else{
      setClientForm((prev) => {
        return { ...prev, client: undefined,  'company_is_customer': true  };
      });
      onClientFormChange(e)
    }
  },[clientForm])

  const userCompanySelectList = useAsync(getCompanySelectList)

  const projetData = useAsync(() => {
    if (props.selectedProjectId) {
      return getProjectFullData(props.selectedProjectId)
    }
    return Promise.resolve({})
  }, [props.selectedProjectId])

  React.useEffect(() => {
    if (projetData.loading) {
      return;
    }
    setProjectForm(projetData.value)
    setCompanyForm(projetData.value?.company)
    setCompanyAddressForm(projetData.value?.company?.address)
    setClientForm(projetData.value?.customer)
    setClientAddressForm(projetData.value?.customer?.address)
  }, [projetData.loading]);


  const companyCustomerRelatedSelectList = useAsync(() => {
    if (companyForm?.company) {
      return getClientSelectList({ 'company': companyForm?.company })
    }
    return Promise.resolve([])
  }, [companyForm?.company])

  const addressKindsList = useAsync(getAddressKindelectList)

  const createProjectFn = useAsyncFn(createProject)

  const requiredProjectFields = [
    "project_name",
    "project_nr",
    "project_date_start",
    "project_sprint_long"
  ];

  const requiredCompanyFields = [
    "company_full_name",
    "company_short_name",
  ];

  const requiredAddressFields = [
    "address_zip",
    "address_city",
    "address_street",
    "address_building"
  ]


  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onClearCompanySelectField = React.useCallback(() => {
    setCompanyForm((prev) => {
      return { ...prev, company: undefined };
    });
  }, [companyForm]);

  const onClearClientSelectField = React.useCallback(() => {
    setClientForm((prev) => {
      return { ...prev, client: undefined};
    });
  }, [clientForm]);



  const onPrepareData = () => {
    let dataToSend = projectForm
    let tempCompanyForm = companyForm
    if (tempCompanyForm?.company) {
      projectForm['company'] = tempCompanyForm['company']
    } else {
      tempCompanyForm['address'] = companyAddressForm
      dataToSend['company_data'] = tempCompanyForm
    }

    // let tempClientForm = clientForm
    // if (tempClientForm?.client) {
    //   projectForm['client'] = tempClientForm['client']
    // } else {
    //   tempClientForm['address'] = clientAddressForm
    //   dataToSend['client_data'] = tempClientForm
    // }

    return dataToSend
  }


  const onSubmit = () => {
    createProjectFn
      .execute(onPrepareData())
      .then((result) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.project_saved")
        );
        props.onClose()
        props.onRefetch()
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_project_saving")
        );
      })

  }

  const getFormByActiveStep = () => {
    switch (activeStep) {
      case 0:
        return (
          <ProjectDataStep
            formValue={projectForm}
            onChange={onProjectFormChange}
            onChangeDate={onProjectChangeDate}
          />
        )

      case 1:
        return (
          <CompanyStep
            projectForm={projectForm}
            companyForm={companyForm}
            onCompanyChange={onCompanyChange}
            onClear={onClearCompanySelectField}
            addressForm={companyAddressForm}
            onAddressChange={onCompanyAddressFormChange}
            addressKinds={addressKindsList.value?.address_kinds || []}
            companies={userCompanySelectList?.value || []}
          />

        )
      case 2:
        return (
          <CustomerStep
            projectForm={projectForm}
            companyForm={companyForm}
            onCompanyChange={onCompanyFormChange}
            onClear={onClearCompanySelectField}
            addressForm={companyAddressForm}
            onAddressChange={onCompanyAddressFormChange}
            addressKinds={addressKindsList.value?.address_kinds || []}
            clientForm={clientForm}
            onClientFormChange={onClientChange}
            onClearClient={onClearClientSelectField}
            clientAddressForm={clientAddressForm}
            onClientAddressFormChange={onClientAddressFormChange}
            companies={userCompanySelectList?.value || []}
            companies_clients={companyCustomerRelatedSelectList?.value || []}
            readOnly = {false}
          />

        )
      default:
        return (
          <CustomerStep
            projectForm={projectForm}
            companyForm={companyForm}
            onCompanyChange={onCompanyFormChange}
            onClear={onClearCompanySelectField}
            addressForm={companyAddressForm}
            onAddressChange={onCompanyAddressFormChange}
            addressKinds={addressKindsList.value?.address_kinds || []}
            clientForm={clientForm}
            onClearClient={onClearClientSelectField}
            clientAddressForm={clientAddressForm}
            onClientAddressFormChange={onClientAddressFormChange}
            companies={userCompanySelectList?.value || []}
            companies_clients={companyCustomerRelatedSelectList?.value || []}
            selectedProjectId={props.selectedProjectId}
            readOnly = {true}
          />

        )
    }
  }

  const setDisabled = () => {
    switch (activeStep) {
      case 0:
        return !requiredProjectFields.every(
          (fieldName) => !isEmptyValue(projectForm[fieldName])
        );
      case 1:
        if (companyForm && companyAddressForm){
        return !companyForm && !requiredCompanyFields.every(
          (fieldName) => !isEmptyValue(companyForm[fieldName])
        ) || !companyAddressForm && !requiredAddressFields.every(
          (fieldName) => !isEmptyValue(companyAddressForm[fieldName])
        );}
        
      // case 2:
      //   if (clientForm && clientAddressForm){
      //   return !clientForm && !requiredCompanyFields.every(
      //     (fieldName) => !isEmptyValue(clientForm[fieldName])
      //   ) || !clientAddressForm && !requiredAddressFields.every(
      //     (fieldName) => !isEmptyValue(clientAddressForm[fieldName])
      //   );}

    }
    return true
  }


  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}
              >{t(`stepper.create_project_stepper.${label}`)}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <React.Fragment>
        <LoaderWrapper  showLoader={projetData.loading}>
        {getFormByActiveStep()}
        </LoaderWrapper>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          {!props.selectedProjectId &&
            <>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                {t("universal.back")}
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />

              {activeStep === steps.length - 1 ?
                <Button onClick={onSubmit}
                  disabled={setDisabled()}>
                  {t("stepper.create_project_stepper.save_data")}
                </Button>
                :
                <Button
                  onClick={handleNext}
                  disabled={setDisabled()}
                >
                  {t("universal.next")}
                </Button>
              }
            </>
          }
        </Box>
      </React.Fragment>
    </Box>
  );
}
