
import { useCallback, useEffect, useMemo, useState } from "react";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper/DefaultPageWrapper";
import NavigationDrawer from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import { useParams } from "react-router-dom";
import useProjectService from "../../services/projectService";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import AddButton from "../../components/button/AddButton";
import FilterTable from "../../components/table/FilterTable"
import TableService from "../../services/tableService";
import { PROCESS_TABLE_FIELDS_CONFIG } from "./TablesConfig";
import useProcessService from "../../services/processService";
import ProcessDialog from "../../components/dialog/ProcessDialog/ProcessDialog";
import useDialogWithId from "../../hooks/useDialogWithId";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useSnackbarAlert } from "../../context/snackbarAlert";
import EditIcon from '@mui/icons-material/Edit';
import useDialog from "../../hooks/useDialog";
import ConfirmationDialog from "../../components/dialog/ConfirmationDialog";
import { useNavigate } from "react-router-dom";

export default function ProcessPage(props) {
  const { pageName } = props;
  const [projectTitle, setProjectTitle] = useState()
  const [readOnly, setReadOnly] = useState(false)
  const [processListLocal, setProcessListLocal] = useState([])
  const [processIdToRemove, setProcessIdToRemove] = useState()
  const { projectId } = useParams();
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const navigate = useNavigate();

  const {
    getProjectAccess
  } = useProjectService();


  const {
    getProcessList,
    deleteProjectProcess
  } = useProcessService();

  const deleteProcessFn = useAsyncFn(deleteProjectProcess)

  const projectAccessData = useAsync(() => {
    if (projectId) {
      return getProjectAccess(projectId)
    }
    return Promise.resolve({})
  }, [projectId])



  const processList = useAsync(() => {
    if (projectId) {
      return getProcessList(projectId)
    }
    return Promise.resolve([])
  }, [projectId])


  useEffect(() => {
    if (projectAccessData.loading) return
    if (projectAccessData.value) {
      setProjectTitle(projectAccessData.value.project_name)
    }
  }, [projectAccessData.loading])

  useEffect(() => {
    if (processList.loading) return
    if (processList.value) {
      setProcessListLocal(processList.value)
    }
  }, [processList.loading])

  const onRemoveProcess = useCallback(() => {
    deleteProcessFn
      .execute(processIdToRemove)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.process_deleted")
        );
        onRefetchData()
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_deleting_process")
        );
      });
  }, [processIdToRemove])

  const [
    openProcessDialog,
    processDialogId,
    onOpenProcessDialog,
    onCloseProcessDialog,
  ] = useDialogWithId();

  const [
    openConfirmationDialog,
    onOpenConfirmationDialog,
    onCloseConfirmationDialog,
  ] = useDialog();

  const getAddRowButton = useCallback(() => {
    if (!readOnly) {
      return (<AddButton onClick={() => onOpenProcessDialog(undefined)} />)
    }
  }, [readOnly])

  const contextMenuActions = [
    {
      label: t("pages.process_page.edit_process"),
      callback: (projectProcessId) => {
        let project = processListLocal.find( (p) => p.id === projectProcessId)
        onOpenProcessDialog(project.process_id)
      },
      icon: <EditIcon fontSize="small" />,
    },
    {
      label: t("pages.process_page.remove_process"),
      callback: (projectProcessId) => {
        onOpenConfirmationDialog()
        setProcessIdToRemove(projectProcessId)
      },
      icon: <HighlightOffIcon fontSize="small" />,
    },
  ];

  const onOpenMap = (e, processId) => navigate(`/project/${projectId}/process/${processId}/map/`)
  const onOpenSipoc = (e, processId) => navigate(`/project/${projectId}/process/${processId}/sipoc/`)
  const onOpenOperations = () => undefined; 
  
  const data = useMemo(
    () =>
      TableService.getPreparedDataForCollapsibleTable(
        PROCESS_TABLE_FIELDS_CONFIG,
        processListLocal,
        [],
        [],
        onOpenMap,
        onOpenSipoc,
        onOpenOperations
      ),
    [processListLocal, PROCESS_TABLE_FIELDS_CONFIG]
  );

  const onRefetchData = () => {
    processList.refetch()
    projectAccessData.refetch()
  }

  const handleCloseConfirmationDialog = (isDeleteProcessConfirm) => {
    if (isDeleteProcessConfirm) {
      onRemoveProcess()
    }
    setProcessIdToRemove(undefined)
    onCloseConfirmationDialog()
  }


  if (projectAccessData.loading) return (<LoaderWrapper showLoader={true} />)

  return (
    <NavigationDrawer pageName={pageName} projectAccessData={projectAccessData?.value}>
      <DefaultPageWrapper titleKey={"process"} projectTitle={projectTitle}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          columnSpacing={1}
          rowSpacing={1}
          style={{ marginTop: "2px" }}
        >

          <Grid item xs={12}>
            <div style={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
              {getAddRowButton()}
              <FilterTable
                onClickRow={() => undefined}
                data={data}
                filteringData={[]}
                countRecords={false}
                showCounterRecords={false}
                headersConfig={TableService.getHeadersConfigForCollapsibleTable(
                  PROCESS_TABLE_FIELDS_CONFIG,
                  [],
                  [],
                  [],
                )}
                style={props.style}
                showCheckbox={false}
                showFilters={false}
                withPagination={false}
                showCleanFilterIcon={false}
                showContextMenu={!readOnly}
                contextMenuActions={contextMenuActions}
                showDetailsIcon={false}
              />

            </div>

          </Grid>
        </Grid>
        {openProcessDialog &&
          <ProcessDialog
            open={openProcessDialog}
            onClose={onCloseProcessDialog}
            processId={processDialogId}
            projectId={projectId}
            onRefetch={onRefetchData}
          />
        }
        {openConfirmationDialog && (
          <ConfirmationDialog
            open={openConfirmationDialog}
            onNo={() => handleCloseConfirmationDialog(false)}
            onYes={() => handleCloseConfirmationDialog(true)}
            title={t("pages.process_page.alert")}
            content={t(
              "pages.process_page.are_you_sure_to_remove_process"
            )}
          />
        )}
      </DefaultPageWrapper>
    </NavigationDrawer >
  );
}
