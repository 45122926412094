import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import AutocompleteField from "../AutocompleteField";
import FloatField from "../../base/FloatField/FloatField";
import DateField from "../DateField/DateField";
import SelectField from "../SelectField/SelectField";
import TextFieldFormControl from "../TextFieldFormControl";
import TrueFalseSelectField from "../TrueFalseSelectField/trueFalseSelectField";

import DateService from "../../../services/dateService";

import {
  getFieldValueFromSearchParams,
  getObjectFromSearchParams,
} from "../../../helpers/methods";

import {
  TEXT_FIELD_TYPE,
  NUMERIC_FIELD_TYPE,
  SELECT_FIELD_TYPE,
  DATE_FIELD_TYPE,
  DATE_RANGE_FIELD_TYPE,
  AUTOCOMPLETE_FIELD_TYPE,
  BOOLEAN_FIELD_TYPE,
  NUMERIC_RANGE_FIELD_TYPE,
} from "../../../helpers/constants";

import useDebounce from "../../../hooks/useDebouce";

const FilterFieldTable = React.memo(
  ({ type, name, fieldConfig, label, resetPageNumber}) => {


    const [searchParams, setSearchParams] = useSearchParams();
    const [formValue, setFormValue] = useState(() =>
      getFieldValueFromSearchParams(searchParams, name)
    );

    const onChange = (e) => {
      const { value } = e.target;
      setFormValue(value);
    };

    const onChangeDate = (e) => {
      const { value } = e.target;
      setFormValue(DateService.convertDateToFormatYYYYMMDD(value));
    };

    useEffect(() => {
      setFormValue(getFieldValueFromSearchParams(searchParams, name))
    }, [searchParams, name]);

    const onChangeAutocompleteFieldWithObjectOptions = (
      e,
      value_object,
      value_key,
      state_value_name
    ) => {
      const newFormValue = Array.isArray(value_object)
        ? value_object.map((option) =>
            typeof option === "object" ? option[value_key] : option
          )
        : value_object[value_key];

      setFormValue(newFormValue);
    };

    const onSearch = () => {
      if (formValue !== undefined)
        setSearchParams((prev) => {
          let searchObject = {
            ...getObjectFromSearchParams(prev),
            [name]: formValue,
          };

          if (prev.get("page")) {
            searchObject = { ...searchObject, page: 1 };
          }

          if (resetPageNumber) resetPageNumber();
          return searchObject;
        });
    };

    const getDebounceDeps = () => {
      return Array.isArray(formValue) ? formValue.length : formValue;
    };

    useDebounce(onSearch, 1000, [getDebounceDeps()]);
    let FilterField = null;
    let onFilterChange = onChange;
    switch (type) {
      case NUMERIC_FIELD_TYPE:
        FilterField = FloatField;
        break;
      case NUMERIC_RANGE_FIELD_TYPE:
        FilterField = FloatField;
        break;
      case TEXT_FIELD_TYPE:
        FilterField = TextFieldFormControl;
        break;
      case AUTOCOMPLETE_FIELD_TYPE:
        FilterField = AutocompleteField;
        if (fieldConfig?.isObjectOption) {
          onFilterChange = onChangeAutocompleteFieldWithObjectOptions;
        }
        break;
      case SELECT_FIELD_TYPE:
        FilterField = SelectField;
        break;
      case DATE_FIELD_TYPE:
      case DATE_RANGE_FIELD_TYPE:
        FilterField = DateField;
        onFilterChange = onChangeDate;
        break;
      case BOOLEAN_FIELD_TYPE:
        FilterField = TrueFalseSelectField;
        break;
      default:
        return formValue;
    }

    return (
      <FilterField
        name={name}
        label={label}
        value={formValue}
        onChange={onFilterChange}
        variant={"standard"}
        {...fieldConfig}
      />
    );
  }
);

FilterFieldTable.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  fieldConfig: PropTypes.object,
  resetPageNumber: PropTypes.func,
};

export default FilterFieldTable;
