import React from 'react';
import { Component } from "react";
import { withTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import PropTypes from 'prop-types';
import { InputAdornment } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';


class TextFieldFormControl extends Component {

    getInputProps = () => {
        var inputProps = {inputProps: {}}
        if (this.props.prefix) {
            inputProps['startAdornment'] = <InputAdornment position="start" style={{ marginRight: 0 }}>{this.props.prefix}</InputAdornment>
        }
        if (this.props.suffix) {
            inputProps['endAdornment'] = <InputAdornment position="end">{this.props.suffix}</InputAdornment>
        }
        if (this.props.readOnly){
            inputProps['readOnly'] = this.props.readOnly
        }
        if (this.props.max !== undefined){
            inputProps.inputProps['max'] = this.props.max
        }
        if (this.props.min !== undefined){
            inputProps.inputProps['min'] = this.props.min
        }
        if (this.props.maxLength){
            inputProps.inputProps['maxLength'] = this.props.maxLength
        }
        if (this.props.minLength){
            inputProps.inputProps['minLength'] = this.props.maxLength
        }
        if (this.props.inputComponent){
            inputProps.inputComponent  = this.props.inputComponent
        }
        return inputProps
    }

    isShrink = () =>{
        if(this.props.inputComponent && !["", undefined, null].includes(this.props.value)){
            return true
        }
        return undefined
    }

    render() {
        return (
            <FormControl fullWidth={this.props.fullWidth} variant="outlined">
                <TextField  style={this.props.style} type={this.props.type} size={'small'} id={this.props.id !== undefined ? this.props.id : this.props.name} label={this.props.label ? this.props.label.charAt(0).toUpperCase() + this.props.label.slice(1) : this.props.label} variant="outlined" value={![null, undefined].includes(this.props.value) ? this.props.value : ''} name={this.props.name} onChange={this.props.onChange} disabled={this.props.disabled} multiline={this.props.multiline} rows={this.props.rows} InputLabelProps={{...this.props.InputLabelProps, shrink:this.isShrink()}} InputProps={this.getInputProps()} required={this.props.required}  onBlur={this.props.onBlur} autoFocus={this.props.autoFocus} onKeyDown={this.props.onKeyDown}/>
                {this.props.helperText.length > 0 ?
                    <FormHelperText id="component-helper-text" error={this.props.isInvalid} style={this.props.helperTextStyle}>
                        {this.props.helperText}
                    </FormHelperText>
                    : null
                }
            </FormControl>
        )
    }
}

TextFieldFormControl.propTypes = {
    type: PropTypes.string,
    style: PropTypes.object,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.any,
    name: PropTypes.string,
    t: PropTypes.any,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    multiline: PropTypes.bool,
    rows: PropTypes.number,
    InputLabelProps: PropTypes.object,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    required: PropTypes.bool,
    helperText: PropTypes.string,
    autoFocus: PropTypes.bool,
    onKeyDown: PropTypes.func,
    hidden: PropTypes.func,
    id: PropTypes.string,
    readOnly: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    maxLength: PropTypes.number,
    minLength: PropTypes.number,
    inputComponent: PropTypes.any,
    placeholder: PropTypes.string,
    isInvalid: PropTypes.bool,
    helperTextStyle: PropTypes.object,
    fullWidth: PropTypes.bool
}

TextFieldFormControl.defaultProps = {
    type: 'text',
    disabled: false,
    multiline: false,
    rows: 1,
    InputLabelProps: {},
    required: false,
    helperText: '',
    readOnly: false,
    isInvalid: false,
    helperTextStyle: {},
    fullWidth: true
}

export default withTranslation()(TextFieldFormControl)
