import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import FloatField from "../../base/FloatField/FloatField";
import { useTranslation } from "react-i18next";
import SelectField from "../../field/SelectField";


export default function CompanyForm(props) {

  const { t } = useTranslation();


  return (
    <Grid container spacing={1}>
      {!props.selectedProjectId &&
        <>
          <Grid item xs={12}>
            {props.isCustomer ?
              <SelectField
                label={t("form.customer_form.select_client")}
                name="client"
                value={props.formValue?.client}
                options={props.companies_clients}
                isObjectOption={true}
                optionLabel={"company_short_name"}
                optionKey={'id'}
                onChange={props.onChange}
                disabled={props.readOnly}
                onClear={props.onClearClientSelectField}
              />
              :
              <SelectField
                label={t("form.company_form.select_company")}
                name="company"
                value={props.formValue?.company}
                options={props.companies}
                isObjectOption={true}
                optionLabel={"company_short_name"}
                optionKey={'id'}
                onChange={props.onChange}
                disabled={props.readOnly}
                onClear={props.onClearCompanySelectField}
              />}
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
              style={{ margin: "5px" }}
            >
              {t("form.company_form.or_create_new_one")}
            </Typography>
          </Grid>
        </>
      }
      <Grid item xs={12}>
        <TextFieldFormControl
          name={"company_full_name"}
          label={props.isCustomer ? t(`form.customer_form.customer_full_name`) : t(`form.company_form.company_full_name`)}
          value={props.formValue?.company_full_name}
          onChange={props.onChange}
          disabled={props.readOnly}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name={"company_short_name"}
          label={props.isCustomer ? t(`form.customer_form.customer_short_name`) : t(`form.company_form.company_short_name`)}
          value={props.formValue?.company_short_name}
          onChange={props.onChange}
          disabled={props.readOnly}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <FloatField
          name={"company_nip"}
          label={props.isCustomer ? t(`form.customer_form.nip`) : t(`form.company_form.nip`)}
          value={props.formValue?.company_nip}
          onChange={props.onChange}
          disabled={props.readOnly}
          decimalPlaces={0}
        />
      </Grid>
      <Grid item xs={12}>
        <FloatField
          name={"company_bank"}
          label={props.isCustomer ? t(`form.customer_form.bank`) : t(`form.company_form.bank`)}
          value={props.formValue?.company_bank}
          onChange={props.onChange}
          disabled={props.readOnly}
          decimalPlaces={0}
        />
      </Grid>
      <Grid item xs={12}>
        <FloatField
          name={"company_iban"}
          label={props.isCustomer ? t(`form.customer_form.iban`) : t(`form.company_form.iban`)}
          value={props.formValue?.company_iban}
          onChange={props.onChange}
          disabled={props.readOnly}
          decimalPlaces={0}
        />
      </Grid>
    </Grid>
  );
};

CompanyForm.propTypes = {
  readOnly: PropTypes.bool,
  formValue: PropTypes.object,
  onChange: PropTypes.func
};

CompanyForm.defaultProps = {
  readOnly: false
};

