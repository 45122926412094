import { useParams } from "react-router-dom";
import NavigationDrawer from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper/DefaultPageWrapper";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import useProjectService from "../../services/projectService";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Card, Grid, Paper, Typography } from "@mui/material";
import UserStoryForm from "../../components/form/UserStoryForm";
import FilterTable from "../../components/table/FilterTable";
import TableService from "../../services/tableService";
import { USER_STORY_TABLE_FIELDS_CONFIG } from "./TableConfig";
import useBacklogsService from "../../services/backlogService";
import { useTranslation } from "react-i18next";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import { useForm } from "../../hooks/useForm";
import { useSnackbarAlert } from "../../context/snackbarAlert";
import ProcessOperationTable from "../../components/table/ProcessOperationTable";
import useProcessService from "../../services/processService";

export default function ProductBacklogUserStoryPage(props) {

    const [readOnly, setReadOnly] = useState(false)

    const { pageName } = props;

    const { projectId, productBacklogId } = useParams();

    const [projectTitle, setProjectTitle] = useState();

    const [userStoryId, setUserStoryId] = useState();

    const [processesOperationsList, setProcessesOperationsList] = useState([]);

    const { t } = useTranslation();

    const snackbarAlert = useSnackbarAlert();

    const initFormValue = { 'project': projectId, 'product_backlog': productBacklogId }

    const {
        formValue,
        setFormValue,
        onChange
    } = useForm(initFormValue)

    const {
        getUserStory,
        createUserStory,
        updateUserStory
    } = useBacklogsService();

    const {
        getProcessOperation
    } = useProcessService();

    const createUserStoryFn = useAsyncFn(createUserStory)

    const updateUserStoryFn = useAsyncFn(updateUserStory)


    const onSaveUserStory = useCallback((data) => {
        if (userStoryId) {
            updateUserStoryFn
                .execute(userStoryId, data)
                .then((res) => {
                    snackbarAlert.openSuccessSnackbarAlert(
                        t("snackbar_alert.process_updated")
                    );
                    onRefetchData()
                    setFormValue(initFormValue)

                })
                .catch((error) => {
                    snackbarAlert.openErrorSnackbarAlert(
                        t("snackbar_alert.occurred_error_during_updating_new_process")
                    );
                });
        } else {
            createUserStoryFn
                .execute(data)
                .then((res) => {
                    snackbarAlert.openSuccessSnackbarAlert(
                        t("snackbar_alert.process_created")
                    );
                    onRefetchData()
                    setFormValue(initFormValue)

                })
                .catch((error) => {
                    snackbarAlert.openErrorSnackbarAlert(
                        t("snackbar_alert.occurred_error_during_creating_new_process")
                    );
                });
        }

    }, [])

    const {
        getProjectAccess
    } = useProjectService();
    const projectAccessData = useAsync(() => {
        if (projectId) {
            return getProjectAccess(projectId)
        }
        return Promise.resolve({})
    }, [projectId])


    useEffect(() => {
        if (projectAccessData.loading) return
        if (projectAccessData.value) {
            setProjectTitle(projectAccessData.value.project_name)
        }
    }, [projectAccessData.loading])

    const userStoryList = useAsync(() => {
        if (projectId) {
            return getUserStory({ project: projectId, product_backlog: productBacklogId })
        }
        return Promise.resolve([])
    }, [projectId, productBacklogId])


    const processesOperations = useAsync(() => {
        if (projectId) {
            return getProcessOperation({ project: projectId })
        }
        return Promise.resolve([])
    }, [projectId])

    const onRefetchData = () => {
        userStoryList.refetch()
        processesOperations.refetch()
    }


    const data = useMemo(
        () =>
            TableService.getPreparedDataForCollapsibleTable(
                USER_STORY_TABLE_FIELDS_CONFIG,
                userStoryList.value?.results || [],
                [],
                []
            ),
        [userStoryList.value, USER_STORY_TABLE_FIELDS_CONFIG]
    );



    const contextMenuActions = [

    ];

    useEffect(() => {
        let temp = []
        if (!processesOperations.loading && processesOperations.value) {
            temp = processesOperations.value
        }
        setProcessesOperationsList(temp)

    }, [processesOperations.value])

    const operations = useMemo(() => {
        let operationsTemp = []
        if (processesOperations.value) {
            for (const process of processesOperations.value) {
                for (const operation of process.operations) {
                    let operationTemp = { ...operation }
                    operationTemp.name = `${process.process_nr}.${operation.ops_nr} ${operation.ops_text}`
                    operationTemp.process = process.id
                    operationsTemp.push(operationTemp)
                }
            }
        }
        return operationsTemp

    }, [processesOperations.value])


    if (projectAccessData.loading) return (<LoaderWrapper showLoader={true} />)

    return (
        <NavigationDrawer pageName={pageName} projectAccessData={projectAccessData?.value}>
            <DefaultPageWrapper titleKey={"pbl"} projectTitle={projectTitle} >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    columnSpacing={1}
                    rowSpacing={1}
                    style={{ marginTop: "2px" }}
                >
                    <Grid item sm={4} xs={12}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            rowSpacing={1}
                        >
                            <Grid item xs={12}>
                                <Paper elevation={3} sx={{ padding: 1, paddingTop: 0 }}>

                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        columnSpacing={1}
                                        rowSpacing={1}
                                    >
                                        <Grid item xs={12}>
                                            <Typography variant="h6">
                                                User story
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <UserStoryForm
                                                onChange={onChange}
                                                formValue={formValue}
                                                onSubmit={() => onSaveUserStory(formValue)}
                                                operationsOptions={operations}
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>

                            </Grid>
                            <Grid item xs={12}>
                                <ProcessOperationTable processes={processesOperationsList} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        <FilterTable
                            onClickRow={() => undefined}
                            data={data}
                            filteringData={[]}
                            countRecords={false}
                            showCounterRecords={false}
                            headersConfig={TableService.getHeadersConfigForCollapsibleTable(
                                USER_STORY_TABLE_FIELDS_CONFIG,
                                [],
                                [],
                                [],
                            )}
                            showCheckbox={false}
                            showFilters={false}
                            withPagination={false}
                            showCleanFilterIcon={false}
                            showContextMenu={!readOnly}
                            showDetailsIcon={false}
                            contextMenuActions={contextMenuActions}
                        />
                    </Grid>
                </Grid>
            </DefaultPageWrapper>
        </NavigationDrawer>

    )

}