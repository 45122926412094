import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid} from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import SelectField from "../../field/SelectField";

const AddressForm= (props) => {
  const { t } = useTranslation();

  
  
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      rowGap={1}
    >
        
      <Grid item xs={12}>
        <SelectField
          label={t("form.address_form.address_kind")}
          name="address_kind"
          value={props.formValue?.address_kind}
          options={props.addressKinds}
          isObjectOption={false}
          onChange={props.onChange}
          disabled={props.readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="address_zip"
          value={props.formValue?.address_zip}
          label={t("form.address_form.address_zip")}
          onChange={props.onChange}
          disabled={props.readOnly}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="address_city"
          value={props.formValue?.address_city}
          label={t("form.address_form.address_city")}
          onChange={props.onChange}
          disabled={props.readOnly}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="address_street"
          value={props.formValue?.address_street}
          label={t("form.address_form.address_street")}
          onChange={props.onChange}
          disabled={props.readOnly}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="address_building"
          value={props.formValue?.address_building}
          label={t("form.address_form.address_building")}
          onChange={props.onChange}
          disabled={props.readOnly}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="address_apartment"
          value={props.formValue?.address_apartment}
          label={t("form.address_form.address_apartment")}
          onChange={props.onChange}
          disabled={props.readOnly}
        />
      </Grid>
    </Grid>
  );
};

AddressForm.propTypes = {
  formValue: PropTypes.object,
  addressKinds: PropTypes.array,
  onChange : PropTypes.func
};

AddressForm.defaultProps = {
  addressKinds: [],
};

export default AddressForm;
