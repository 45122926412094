import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export default function ConfirmationDialog(props) {
  const { t } = useTranslation();

  return (
    <Dialog fullWidth open={props.open} maxWidth={props.maxWidth}>
      <DialogTitle sx={props.titleSx} align={props.titleAlign}>
        {props.title}
      </DialogTitle>
      {props.content !== undefined && (
        <DialogContent
          dividers={props.showDialogContentDividers}
          align={props.contentTextAlign}
          sx={props.contentSx}
        >
          {props.content}
        </DialogContent>
      )}
      <DialogActions sx={{ justifyContent: "center" }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={6} textAlign={"right"}>
            <LoadingButton
              style={{ minWidth: "100px" }}
              variant="contained"
              size="small"
              onClick={props.onYes}
              color={props.btnYesColor}
              loading={props.loading}
            >
              {t("true")}
            </LoadingButton>
          </Grid>
          <Grid item xs={6} textAlign={"left"}>
            <Button
              color={props.btnNoColor}
              style={{ minWidth: "100px" }}
              variant="contained"
              size="small"
              onClick={props.onNo}
              disabled={props.loading}
            >
              {t("false")}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.any,
  titleAlign: PropTypes.oneOf(["right", "left", "center"]),
  onYes: PropTypes.func,
  onNo: PropTypes.func,
  showDialogContentDividers: PropTypes.bool,
  contentTextAlign: PropTypes.oneOf(["right", "left", "center"]),
  maxWidth: PropTypes.string,
  titleSx: PropTypes.object,
  contentSx: PropTypes.object,
  btnYesColor: PropTypes.string,
  btnNoColor: PropTypes.string,
  loading: PropTypes.bool,
};

ConfirmationDialog.defaultProps = {
  open: false,
  titleAlign: "center",
  showDialogContentDividers: true,
  contentTextAlign: "left",
  btnYesColor: "primary",
  btnNoColor: "secondary",
  loading: false,
};
