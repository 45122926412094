import React, { useState, useEffect } from "react";
import { Button, Grid, Link, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PropTypes } from 'prop-types'
import LoadingButton from '@mui/lab/LoadingButton';
import PasswordField from "../../components/base/PasswordField";
import TextFieldFormControl from "../../components/base/TextFieldFormControl";

export default function RegistrationForm(props) {
    const { t } = useTranslation();
    const requiredFields = ['registration_name', 'registration_surname', 'registration_email',  'registration_password']
    const [formValue, setFormValue] = useState({})
    const [isDiffrentRepeatPassword, setIsDiffrentRepeatPassword] = useState(false)

    const changeValue = (e) => {
        const { value, name } = e.target
        setFormValue((form) => ({ ...form, [name]: value }))
    }

    const onClickSubmit = (event) => {
        event.preventDefault();
        props.onClickSubmit(formValue)
    }

    const isDisabledButton = () => {
        for (const fieldName of requiredFields) {
            if ([undefined, '', null].includes(formValue[fieldName])) {
                return true;
            }
        }
        return false;
    }

    useEffect(() => {
        setIsDiffrentRepeatPassword(checkIsDiffrentRepeatPassword())
    }, [formValue.repeat_password])

    const checkIsDiffrentRepeatPassword = () => {
        return formValue.repeat_password && formValue.repeat_password !== formValue.registration_password
    }

    return (
        <Paper elevation={3} className={"paper-form"} style={{ maxWidth: "600px", marginTop: "5vh" }}>
            <form onSubmit={onClickSubmit}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={1}
                >
                    <Grid item xs={12}>
                        <Typography align="center" style={{ textTransform: 'uppercase' }} variant="h5" display={"block"} gutterBottom>
                            {t("pages.registration.user_registration")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldFormControl name="registration_name" label={t("universal.name")} value={formValue.registration_name} onChange={changeValue} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldFormControl name="registration_surname" label={t("universal.surname")} value={formValue.registration_surname} onChange={changeValue} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldFormControl name="registration_email" label={t("universal.email")} value={formValue.registration_email} onChange={changeValue} />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <TextFieldFormControl name="registration_phone" label={t("universal.phone")} value={formValue.registration_phone} onChange={changeValue} />
                    </Grid> */}
                    <Grid item xs={12}>
                        <PasswordField name="registration_password" value={formValue.registration_password} onChange={changeValue} label={t("universal.password")} />
                    </Grid>
                    <Grid item xs={12}>
                        <PasswordField name="repeat_password" value={formValue.repeat_password} onChange={changeValue} label={t("universal.repeat_password")} />
                    </Grid>
                    {isDiffrentRepeatPassword &&
                        <Grid item xs={12} textAlign={"left"} style={{paddingTop: 0}} className="capitalize-first-letter">
                            <Typography variant="caption" color="error">{t("pages.registration.passwords_are_diffrent")} </Typography>
                        </Grid>}
                    <Grid item xs={12}>
                        <LoadingButton loading={props.showLoadingButton} type="submit" fullWidth variant="contained" onClick={onClickSubmit} disabled={isDisabledButton()}>{t("pages.registration.register")}</LoadingButton>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    )
}

RegistrationForm.propTypes = {
    showLoadingButton: PropTypes.bool
}