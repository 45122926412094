import BasicDialog from "../../base/BasicDialog"
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { useForm } from "../../../hooks/useForm"
import MemberForm from "../../form/MemberForm";
import useUserService from "../../../services/userService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync"
import LoadingButton from "@mui/lab/LoadingButton";
import { isEmptyValue } from "../../../helpers/methods";
import { useCallback, useState } from "react";
import { Grid } from "@mui/material";
import InviteMemberDialog from "../InsertEmailDialog/InviteMemberDialog";
import useDialog from "../../../hooks/useDialog";

function AddMemberDialog(props) {
    const { t } = useTranslation();

    const snackbarAlert = useSnackbarAlert();

    const requiredFields = [
       
        "role",
    ];

    const [
        openInviteMemberDialog,
        onOpenInviteMemberDialog,
        handleCloseInviteMemberDialog,
      ] = useDialog();

    const {
        getTeamUserSelectList,
        getRoleTeamSelectList,
        createTeamMember
    } = useUserService()

    const userSelectList = useAsync(() => getTeamUserSelectList( props.teamId), [ props.teamId])
    const roleTeamSelectList = useAsync(getRoleTeamSelectList)
    const createTeamMemberFn = useAsyncFn(createTeamMember)

    const {
        formValue,
        setFormValue,
        onChange,
        onChangeAutocompleteFieldWithObjectOptions
    } = useForm({ "company_short_name": props.companyName, "team" :props.teamId })

    const isFormValid = () => {
        return requiredFields.every(
            (fieldName) => !isEmptyValue(formValue[fieldName]) && (!isEmptyValue(formValue["user"]) || !isEmptyValue(formValue["member_name"]))
        );
    };

    const addExternalUserData = useCallback((data) =>{
        setFormValue((prev) => ({...prev, ...data}))
        handleCloseInviteMemberDialog()
    },[])

    const onSubmit = useCallback((data) => {
        createTeamMemberFn
        .execute(data)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.team_member_added")
          );
          if (props.onRefetch) {
            props.onRefetch();
          }
          props.onClose();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_adding_new_team_member")
          );
        });
    },[])

    return (
        <BasicDialog
            open={props.open}
            titleAlign="center"
            contentAlign="center"
            title={t("dialog.add_member_dialog.add_team_member")}
            fullScreen={false}
            showCustomFooter={false}
            showDialogActions={true}
            onClose={props.onClose}
            maxWidth={"sm"}
            // titleSx={{ height: `${64}px`, marginLeft: `${openMiniDrawerWidth}px` }}
            showTopCloseButton={true}
        >
            <Grid container spacing={1}>

                <Grid item xs={12}>
                    <MemberForm
                        formValue={formValue}
                        onChange={onChange}
                        onChangeAutocomplete={onChangeAutocompleteFieldWithObjectOptions}
                        companies_users={userSelectList?.value ? userSelectList.value : []}
                        roles={roleTeamSelectList?.value ? roleTeamSelectList.value : []}
                        onOpenInviteMemberDialog ={onOpenInviteMemberDialog}
                    />
                </Grid>

                <Grid item xs={12}>
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        fullWidth
                        loading={props.isLoading}
                        disabled={!isFormValid()}
                        onClick={() =>onSubmit(formValue)}
                    >
                        {t("universal.create")}
                    </LoadingButton>
                </Grid>
                </Grid>
                {openInviteMemberDialog &&
                    <InviteMemberDialog 
                    open ={openInviteMemberDialog}
                    onClose={handleCloseInviteMemberDialog}
                    onInvite = {addExternalUserData}
                    />
                }
        </BasicDialog >
    )
}

AddMemberDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
};

AddMemberDialog.defaultProps = {
    open: false
};

export default AddMemberDialog