import axios from "../api/axios"
import { linkUrl } from "../helpers/apiUrls"
import { makeRequest } from "../helpers/makeRequest"

export default function useLinkService() {

    const getLink = (kind, token) =>{
        return makeRequest(axios, `${linkUrl}${kind}/${token}/`, {
            method: "GET"
        })
    }

    return {
        getLink
    }

}