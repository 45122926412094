import { AccordionSummary, Grid } from "@mui/material";
import TextFieldFormControl from "../../base/TextFieldFormControl/textFieldFormControl";
import { useTranslation } from "react-i18next";
import SelectField from "../../field/SelectField";
import LoadingButton from "@mui/lab/LoadingButton";
import Accordion from '@mui/material/Accordion';


export default function UserStoryForm(props) {

    const { t } = useTranslation();

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextFieldFormControl
                    name={"us_as"}
                    label={t(`form.user_story.as`)}
                    value={props.formValue?.us_as}
                    onChange={props.onChange}
                    disabled={props.readOnly}
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <TextFieldFormControl
                    name={"us_want"}
                    label={t(`form.user_story.want`)}
                    value={props.formValue?.us_want}
                    onChange={props.onChange}
                    disabled={props.readOnly}
                    required
                    multiline
                    rows={2}
                />
            </Grid>
            <Grid item xs={12}>
                <TextFieldFormControl
                    name={"us_in_order"}
                    label={t(`form.user_story.in_order`)}
                    value={props.formValue?.us_in_order}
                    onChange={props.onChange}
                    disabled={props.readOnly}
                    required
                    multiline
                    rows={2}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <SelectField
                    name={"us_category"}
                    label={t(`form.user_story.category`)}
                    options={['inbound', 'outbound']}
                    value={props.formValue?.us_category}
                    onChange={props.onChange}
                    disabled={props.readOnly}
                    required
                    isObjectOption={false}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextFieldFormControl
                    name={"us_priority"}
                    label={t(`form.user_story.priority`)}
                    value={props.formValue?.us_priority}
                    onChange={props.onChange}
                    disabled={props.readOnly}
                    required
                    type="number"
                />
            </Grid>
            <Grid item xs={12}>
                <SelectField
                    name={"operation"}
                    label={t(`form.user_story.activity`)}
                    disabled={props.readOnly}
                    required
                    options={props.operationsOptions}
                    onChange={props.onChange}
                    value={props.formValue?.operation}
                />

            </Grid>
            <Grid item xs={12} sm={6}>

            </Grid>
            <Grid item xs={12} sm={6}>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    fullWidth
                    loading={props.isLoading}
                    onClick={props.onSubmit}
                    size="small"
                >
                    {t("universal.create")}
                </LoadingButton>
            </Grid>
        </Grid>
    )
}