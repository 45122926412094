
import { useCallback, useEffect, useRef, useState } from "react";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper/DefaultPageWrapper";
import NavigationDrawer from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import { useParams } from "react-router-dom";
import useProjectService from "../../services/projectService";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { useSnackbarAlert } from "../../context/snackbarAlert";
import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  Controls,
  useReactFlow,
  ControlButton
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import SipocNode from "../../components/flow/nodes/SipocNode/SipocNode";
import SipocEdge from "../../components/flow/edges/SipocEdge/SipocEdge";
import SipocTitleNode from "../../components/flow/nodes/SipocTitleNode/SipocTitleNode";
import SipocButtonNode from "../../components/flow/nodes/SipocButtonNode";
import useProcessService from "../../services/processService";
import useSipocMapService from "../../services/sipocMapService";
import StartShape from "../../components/flow/nodes/StartShape/StartShape";
import ActivityShape from "../../components/flow/nodes/ActivityShape/ActivityShape";
import DecisionShape from "../../components/flow/nodes/DecisionShape/DecisionShape";
import useOperationDialog from "../../hooks/useOperationDialog";
import OperationDialog from "../../components/dialog/OperationDialog/OperationDialog";
import EndShape from "../../components/flow/nodes/EndShape";
import CommentShape from "../../components/flow/nodes/CommentShape";
import { TECHNICAL_NODES } from "./technicalNodes";
import ProviderNode from "../../components/flow/nodes/ProviderNode";
import ProviderDialog from "../../components/dialog/ProviderDialog";
import useDialogWithId from "../../hooks/useDialogWithId";
import { getErrorMsg } from "../../helpers/methods";
import ReplayIcon from '@mui/icons-material/Replay';
import { Tooltip } from "@mui/material";
//do zrobienia
//ustaw kolor z backendu
//tłumaczenia do default nodes

export default function MapProcessPage(props) {
  const { pageName } = props;
  const [projectTitle, setProjectTitle] = useState()
  const { projectId, processId } = useParams();
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const [structureWidth, setStructureWidth] = useState(1000)


  const nodesRef = useRef([]);

  const {
    generateProvidersGroups,
    generateOperationNodes,
    checkIfNodeIsInEditableRectangle,
    getProvider,
    checkIfNodeIsInWatinigRoom,
    prepareOperationNodeToBackend,
    prepareStartEndNodeToBackend,
    getWaitingRoom
  } = useSipocMapService();



  const {
    getProcessMapData,
    updateOperationNode,
    createOperation,
    createOperationEdge,
    deleteOperationEdge,
    deleteOperation,
    deleteProvider,
    renumerateOperationNodes
  } = useProcessService();


  const [
    operationNodeId,
    openOperationDialog,
    handleOpenOperationNodeDialog,
    handleOpenOperationNodeDialogWithId,
    handleCloseOperationNodeDialog,
    operationKind,
    opsProvider,
    XCoordinate,
    YCoordinate,
    color] = useOperationDialog();

  const [
    openProviderDialog,
    selectedProviderId,
    handleOpenProviderDialog,
    handleCloseProviderDialog
  ] = useDialogWithId();


  const nodeTypes = {
    sipocNode: SipocNode,
    titleNode: SipocTitleNode,
    buttonNode: SipocButtonNode,
    startShape: StartShape,
    endShape: EndShape,
    activityShape: ActivityShape,
    decisionShape: DecisionShape,
    commentShape: CommentShape,
    providerNode: ProviderNode
  };

  const {
    getProjectAccess
  } = useProjectService();


  const processMapData = useAsync(() => getProcessMapData(processId), [processId]);

  const groupProviders = generateProvidersGroups([], structureWidth, handleOpenProviderDialog)

  const [editableRectangle, setEditableRectangle] = useState({ x_min: 350, y_min: 100, x_max: structureWidth, y_max: (groupProviders.length / 3) * 100 })
  const [nodes, setNodes, onNodesChange] = useNodesState([...TECHNICAL_NODES, ...groupProviders, ...getWaitingRoom()]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const updateOperationNodeFn = useAsyncFn(updateOperationNode)
  const createOperationFn = useAsyncFn(createOperation);
  const createOperationEdgeFn = useAsyncFn(createOperationEdge)
  const deleteOperationEdgeFn = useAsyncFn(deleteOperationEdge);
  const deleteOperationNodeFn = useAsyncFn(deleteOperation);
  const deleteProviderNodeFn = useAsyncFn(deleteProvider)
  const renumerateOperationNodesFn = useAsyncFn(renumerateOperationNodes)

  const onRemoveNode = useCallback((nodeId) => {
    onDeleteNode(nodeId)
  }, []);

  const onEditNode = useCallback((nodeId) => {
    handleOpenOperationNodeDialogWithId(nodeId)
  }, []);

  const onEditProvider = useCallback((providerId) => {
    handleOpenProviderDialog(providerId)
  }, []);

  const onRemoveProvider = useCallback((providerId) => {
    onDeleteProvider(providerId)
  }, []);

  const onUpdateNode = useCallback((node) => {
    updateOperationNodeFn
      .execute(node.id, { ...node, 'project': projectId })
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.operation_node_updated")
        );
        processMapData.refetch()
      })
      .catch((error) => {
        processMapData.refetch()
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_updating_opearion_node")
        );
      });
  }, [])

  const onCreateNode = useCallback((data) => {
    createOperationFn
      .execute({ ...data, 'project': projectId, 'process': processId })
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.operation_added")
        );
        processMapData.refetch()
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_adding_operation")
        );
        processMapData.refetch()
      });
  }, [projectId])

  const onCreateEdge = useCallback((edgeData) => {
    createOperationEdgeFn
      .execute(onPrepareEdgeData(edgeData))
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.operation_edge_added")
        );
        processMapData.refetch()
      })
      .catch((error) => {
        processMapData.refetch()
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_adding_operation_edge")
        );
      });
  }, [processId])

  const onPrepareEdgeData = (data) => {
    return {
      opsrel_predecessor: data.source,
      opsrel_sucsessor: data.target,
      opsrel_predecessor_handler: data.sourceHandle,
      opsrel_sucsessor_handler: data.targetHandle,
      process: processId
    }
  }


  const onNodesChangeLocal = useCallback((changes) => {
    onNodesChange(changes)
    let changedNode = changes[0]
    if (changedNode.type === "position" && changedNode.dragging === false) {
      let node = nodes.find((n) => n.id === changedNode.id)
      let providersNodes = nodes.filter((node) => node?.data?.type === "map")
      let provider = getProvider(providersNodes, changedNode, structureWidth)
      if (checkIfNodeIsInEditableRectangle(editableRectangle, changedNode)) {
        onNewNodeToTechnicalNodes(node, changedNode.position, provider)
      } else if (checkIfNodeIsInWatinigRoom(structureWidth, changedNode) && (node.data.type === "activity_shape" || node.data.type === "decision_shape")) {
        onNewNodeToTechnicalNodes(node, changedNode.position, null)
      } else if (node.data.type === "comment_shape") {
        onNewNodeToTechnicalNodes(node, changedNode.position, provider)
      }
      else {
        processMapData.refetch()
      }
    }
  }, [onNodesChange, editableRectangle, nodes, structureWidth])


  const onNewNodeToTechnicalNodes = (node, position, provider) => {
    if (node.id === "ashape" || node.id === "dshape" || node.id == "cshape") {
      handleOpenOperationNodeDialog(node.data.type, provider, position.x, position.y, node.data.buttonCollor)
    }
    else if (node.id === "sshape" || node.id === "eshape") {
      onCreateNode(prepareStartEndNodeToBackend(node, provider, position.x, position.y))
    }
    else {
      onUpdateNode(prepareOperationNodeToBackend(node, provider))
    }
  }

  const onDeleteEdge = useCallback((edgeId) => {
    deleteOperationEdgeFn
      .execute(edgeId)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.operation_edge_deleted")
        );
        processMapData.refetch()
      })
      .catch((error) => {
        processMapData.refetch()
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_deleting_operation_edge")
        );
      });
  }, [])

  const onDeleteNode = useCallback((nodeId) => {
    deleteOperationNodeFn
      .execute(nodeId)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.operation_node_deleted")
        );
        processMapData.refetch()
      })
      .catch((error) => {
        processMapData.refetch()
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_deleting_operation_node")
        );
      });
  }, [])

  const onDeleteProvider = useCallback((providerId) => {
    deleteProviderNodeFn
      .execute(providerId)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.provider_deleted")
        );
        processMapData.refetch()
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(error.response.data),
          t("snackbar_alert.occurred_error_during_deleting_provider")
        );
        processMapData.refetch()
      });
  }, [])


  const onRemoveEdge = useCallback((id, data) => {
    setEdges((edges) => edges.filter((edge) => edge.id !== id));
    onDeleteEdge(id)
  }, [])

  const customOperationNodes = nodes.map((node) => ({
    ...node,
    data: { ...node.data, onRemove: onRemoveNode, onEdit: onEditNode },
  }));

  const customOperationEdges = edges.map((edge) => ({
    ...edge,
    type: 'sipocEdge',
    data: { ...edge.data, onRemove: onRemoveEdge },
  }));


  const onConnect = useCallback((connection) => {

    if (connection.source !== connection.target) {
      setEdges((eds) => onAddEdgeLocal(connection, eds));
    }
  }, []);

  const onAddEdgeLocal = useCallback((connection, eds) => {
    onCreateEdge(connection)
    return eds

  }, [])

  const onRenumerateAllNodes = useCallback(()=>{
    renumerateOperationNodesFn
    .execute(processId)
    .then((res) => {
      snackbarAlert.openSuccessSnackbarAlert(
        t("snackbar_alert.operation_nodes_renumerated")
      );
      processMapData.refetch()
    })
    .catch((error) => {
      processMapData.refetch()
      snackbarAlert.openErrorSnackbarAlert(
        t("snackbar_alert.occurred_error_during_operation_nodes_renumerating")
      );
    });
  },[processId])



  const edgeTypes = {
    sipocEdge: SipocEdge,
  };


  const projectAccessData = useAsync(() => {
    if (projectId) {
      return getProjectAccess(projectId)
    }
    return Promise.resolve({})
  }, [projectId])


  useEffect(() => {
    if (projectAccessData.loading) return
    if (projectAccessData.value) {
      setProjectTitle(projectAccessData.value.project_name)
    }
  }, [projectAccessData.loading])


  useEffect(() => {
    if (processMapData.loading) return
    if (processMapData.value) {
      let operationNodes = generateOperationNodes(processMapData.value.operations)
      let newStructureWidth = processMapData.value.providers_max_width
      setStructureWidth(newStructureWidth)
      let tempNodes = generateProvidersGroups(processMapData.value.providers, newStructureWidth, handleOpenProviderDialog, processMapData.value.providers_y_max, onEditProvider, onRemoveProvider)
      newStructureWidth = newStructureWidth === 1000 ? 1350 : newStructureWidth + 350

      setNodes([...TECHNICAL_NODES, ...tempNodes, ...operationNodes, ...getWaitingRoom(newStructureWidth)])
      setEdges(processMapData.value.operations_related)
      setEditableRectangle((prev) => {
        return { ...prev, x_max: newStructureWidth, y_max: processMapData.value.providers_y_max };
      })
      nodesRef.current = [...TECHNICAL_NODES, ...tempNodes, ...operationNodes, ...getWaitingRoom(newStructureWidth)]
    }
  }, [processMapData.loading])


  const onCloseAddNodeDialog = useCallback(() => {
    processMapData.refetch()
    handleCloseOperationNodeDialog()
  })

  if (projectAccessData.loading) return (<LoaderWrapper showLoader={true} />)



  return (
    <NavigationDrawer pageName={pageName} projectAccessData={projectAccessData?.value}>
      <DefaultPageWrapper titleKey={"map_process"} projectTitle={projectTitle}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          columnSpacing={1}
          rowSpacing={1}
          style={{ marginTop: "2px" }}
        >
          <Grid item xs={12} style={{ height: 800 }}>
            <ReactFlow
              nodes={customOperationNodes}
              edges={customOperationEdges}
              onNodesChange={onNodesChangeLocal}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              edgeTypes={edgeTypes}
              nodeTypes={nodeTypes}
              defaultZoom={0.9}
              panOnDrag={true}
              panOnScroll={true}
              zoomOnScroll={true}
              panOnScrollSpeed={0}
              fitView
            >
              <Controls
                showInteractive={false}
                style={{ transform: 'scale(2) translateY(-20px)' }}
              >
                <ControlButton
                  onClick={onRenumerateAllNodes}>
                    <Tooltip title ={t("pages.map_page.renumerate_nodes")}> 
                  <ReplayIcon
                    style={{ transform: 'scale(1.5)' }}
                  />
                  </Tooltip>
                </ControlButton>
              </Controls>
            </ReactFlow>

          </Grid>
        </Grid>
        {openProviderDialog &&
          <ProviderDialog
            open={openProviderDialog}
            onClose={handleCloseProviderDialog}
            providerId={selectedProviderId}
            processId={processId}
            onRefetch={processMapData.refetch}
          />
        }
        {openOperationDialog &&
          <OperationDialog
            operationId={operationNodeId}
            open={openOperationDialog}
            onClose={onCloseAddNodeDialog}
            processId={processId}
            projectId={projectId}
            operationKind={operationKind}
            opsProvider={opsProvider}
            XCoordinate={XCoordinate}
            YCoordinate={YCoordinate}
            nodeColor={color}
            onRefetch={processMapData.refetch}
          />
        }
      </DefaultPageWrapper>
    </NavigationDrawer >
  );
}
