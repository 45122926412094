import SelectField from "../../field/SelectField";
import i18n from "../../../i18n";
import { convertStringBoolValueToBool } from "../../../helpers/methods";

function TrueFalseSelectField({
  name,
  value,
  valuesAsBool = false,
  variant = "outlined",
  addEmptyOptions = true,
  ...rest
}) {
  const formattedValue = valuesAsBool
    ? convertStringBoolValueToBool(value)
    : value;
  const statusSelectList = [
    { id: valuesAsBool ? true : "true", name: i18n.t("true") },
    { id: valuesAsBool ? false : "false", name: i18n.t("false") },
  ];
  return (
    <SelectField
      name={name}
      value={formattedValue}
      options={statusSelectList}
      variant={variant}
      emptyOptionsLabel={"-------"}
      addEmptyOptions={addEmptyOptions}
      {...rest}
    />
  );
}
export default TrueFalseSelectField;

export function reverseLookupForTrueFalseSelectField(key) {
  const reverseLookup = { true: i18n.t("true"), false: i18n.t("false") };
  return reverseLookup[key];
}
