export const ORDERING_DIRECTION_ASC = "asc";
export const ORDERING_DIRECTION_DESC = "desc";

export const LANGUAGE_KEY_IN_LOCAL_STORAGE = "language";

export const ERROR_TEXT_STYLE = {
    marginLeft: 0,
    marginRight: 0,
    fontWeight: "bold",
    color: "red",
    minWidth: "180px",
  };
  
export const FORMAT_NUMBER_SEPARATOR = " ";

export const TEXT_FIELD_TYPE = "text";
export const NUMERIC_FIELD_TYPE = "numeric";
export const CHECKBOX_FIELD_TYPE = "checkbox";
export const SELECT_FIELD_TYPE = "select";
export const AUTOCOMPLETE_FIELD_TYPE = "autocomplete";
export const BOOLEAN_FIELD_TYPE = "boolean";
export const DATE_FIELD_TYPE = "date";
export const DATETIME_TO_DATE_FIELD_TYPE = "datetime_to_date";
export const DATE_TO_DATETIME_FIELD_TYPE = "date_to_datetime";
export const DATE_RANGE_FIELD_TYPE = "date_range";
export const DATETIME_TO_DATE_RANGE_FIELD_TYPE = "datetime_to_date_range";
export const DATETIME_TO_DATE_RANGE_START_FIELD_SUBTYPE = "datetime_to_date_range_start";
export const DATETIME_TO_DATE_RANGE_END_FIELD_SUBTYPE = "datetime_to_date_range_end";
export const NUMERIC_RANGE_FIELD_TYPE = "numeric_range";
export const BUTTON_FIELD_TYPE = "button_type";
export const DATE_TIME_FIELD_TYPE = "datetime";
export const DATE_TIME_RANGE_FIELD_TYPE = "datetime_range";
export const TIME_FIELD_TYPE = "time";
export const TIME_RANGE_FIELD_TYPE = "time_range";

export const PAGINATION_PAGE_SIZE = 10;
export const BIG_PAGINATION_PAGE_SIZE = 100;

export const FILTER_SEPARATOR = "^";

export const TABLE_TEXT_FIELD_MAX_LENGTH = "64";

export const SIPOC_SUPPLIER = 'sipoc_supplier'
export const SIPOC_INPUT = 'sipoc_input'
export const SIPOC_CUSTOMER = 'sipoc_customer'
export const SIPOC_OUTPUT = 'sipoc_output'
export const SIPOC_PROCESS = 'sipoc_process'
