import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import FloatField from "../../base/FloatField/FloatField";
import { useTranslation } from "react-i18next";
import SelectField from "../../field/SelectField";


export default function ProcessForm(props) {

  const { t } = useTranslation();


  return (
    <Grid container spacing={1}>
    <Grid item xs={6}>
      <TextFieldFormControl
        name={"process_start"}
        label={t(`form.process_form.process_start`)}
        value={props.formValue?.process_start}
        onChange={props.onChange}
        disabled={props.readOnly}
        required
      />
    </Grid>
    <Grid item xs={6}>
      <TextFieldFormControl
        name={"process_stop"}
        label={t(`form.process_form.process_stop`)}
        value={props.formValue?.process_stop}
        onChange={props.onChange}
        disabled={props.readOnly}
        required
      />
    </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name={"process_name"}
          label={t(`form.process_form.process_name`)}
          value={props.formValue?.process_name}
          onChange={props.onChange}
          disabled={props.readOnly}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name={"process_owner"}
          label={t(`form.process_form.process_owner`)}
          value={props.formValue?.process_owner}
          onChange={props.onChange}
          disabled={props.readOnly}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name={"process_mission"}
          label={t(`form.process_form.process_mission`)}
          value={props.formValue?.process_mission}
          onChange={props.onChange}
          disabled={props.readOnly}
          multiline
          rows={4}
          resize={"vertical"}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name={"process_resources"}
          label={t(`form.process_form.process_resources`)}
          value={props.formValue?.process_resources}
          onChange={props.onChange}
          disabled={props.readOnly}
          multiline
          rows={4}
          resize={"vertical"}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name={"process_equipment"}
          label={t(`form.process_form.process_equipment`)}
          value={props.formValue?.process_equipment}
          onChange={props.onChange}
          disabled={props.readOnly}
          multiline
          rows={4}
          resize={"vertical"}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name={"process_licences"}
          label={t(`form.process_form.process_licences`)}
          value={props.formValue?.process_licences}
          onChange={props.onChange}
          disabled={props.readOnly}
          multiline
          rows={4}
          resize={"vertical"}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name={"process_location"}
          label={t(`form.process_form.process_location`)}
          value={props.formValue?.process_location}
          onChange={props.onChange}
          disabled={props.readOnly}
          multiline
          rows={4}
          resize={"vertical"}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name={"process_note"}
          label={t(`form.process_form.process_note`)}
          value={props.formValue?.process_note}
          onChange={props.onChange}
          disabled={props.readOnly}
          multiline
          rows={4}
          resize={"vertical"}
        />
      </Grid>
    </Grid>
  );
};

ProcessForm.propTypes = {
  readOnly: PropTypes.bool,
  formValue: PropTypes.object,
  onChange: PropTypes.func
};

ProcessForm.defaultProps = {
  readOnly: false
};

