import * as React from "react";

import PropTypes from "prop-types";

import {
  StyledTableCell,
  StyledTableRow,
} from "../../../base/BaseTable/BaseTable";

import FilterFieldTable from "../../../field/FilterFieldTable";

import {
  DATE_RANGE_FIELD_TYPE,
  DATE_TIME_RANGE_FIELD_TYPE,
  NUMERIC_RANGE_FIELD_TYPE,
  TIME_RANGE_FIELD_TYPE,
  DATETIME_TO_DATE_RANGE_START_FIELD_SUBTYPE,
  DATETIME_TO_DATE_RANGE_END_FIELD_SUBTYPE,
  DATETIME_TO_DATE_RANGE_FIELD_TYPE,
} from "../../../../helpers/constants";
import { useTranslation } from "react-i18next";
import { getFilterSearchParamsKey } from "../../../../helpers/methods";
import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSearchParams } from "react-router-dom";
import useFilterSearchParams from "../../../../hooks/useFilterSearchParams";
import IconButtonWithTooltip from "../../../button/IconButtonWithTooltip";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

const UniversalHeaderFilterRow = React.memo(
  ({
    showCheckbox,
    showDetailsIcon,
    headersConfig,
    resetPageNumber,
    filterPrefix,
    showCleanFilterIcon,
    showChangesCircle,
    excludedKeysForClearSearchParams,
  }) => {
    const { t } = useTranslation();

    const [searchParams, setSearchParams] = useSearchParams();

    const { clearSearchParamsByFilterPrefix } =
      useFilterSearchParams(searchParams);

    const setFieldName = (name, filterPrefix) => {
      if (filterPrefix !== undefined) {
        return `${getFilterSearchParamsKey(filterPrefix)}${name}`;
      }
      return name;
    };

    const onClearParams = () => {
      setSearchParams(
        clearSearchParamsByFilterPrefix(
          filterPrefix,
          excludedKeysForClearSearchParams
        )
      );
    };

    const getFieldConfig = (header) => {
      return header.filterType === TIME_RANGE_FIELD_TYPE
        ? {
            minsStep: 60,
            hoursAsId: true,
            withClearIcon: true,
            idIncludeTimeOffset: true,
            ...header.fieldConfig,
          }
        : header.fieldConfig;
    };

    const getFilterFieldContent = (header) => {
      if (header.includeExcludedFilter) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FilterFieldTable
              name={setFieldName(header.name, filterPrefix)}
              type={header.filterType}
              resetPageNumber={resetPageNumber}
              fieldConfig={getFieldConfig(header)}
              filterPrefix={filterPrefix}
            />
            <div
              style={{
                display: "flex",
                alignItems: "column",
              }}
            >
              <IconButtonWithTooltip title={t("excluded")}>
                <PriorityHighIcon
                  sx={{ color: "var(--alert)" }}
                  fontSize="small"
                />
              </IconButtonWithTooltip>
              <FilterFieldTable
                name={setFieldName(`${header.name}_excluded`, filterPrefix)}
                type={header.filterType}
                resetPageNumber={resetPageNumber}
                fieldConfig={getFieldConfig(header)}
                filterPrefix={filterPrefix}
              />
            </div>
          </div>
        );
      } else if (
        header.filterType !== DATE_RANGE_FIELD_TYPE &&
        header.filterType !== DATE_TIME_RANGE_FIELD_TYPE &&
        header.filterType !== DATETIME_TO_DATE_RANGE_FIELD_TYPE &&
        header.filterType !== NUMERIC_RANGE_FIELD_TYPE &&
        header.filterType !== TIME_RANGE_FIELD_TYPE
      ) {
        return (
          <FilterFieldTable
            name={setFieldName(header.name, filterPrefix)}
            type={header.filterType}
            resetPageNumber={resetPageNumber}
            fieldConfig={getFieldConfig(header)}
            filterPrefix={filterPrefix}
          />
        );
      } else if (
        header.filterType === DATE_RANGE_FIELD_TYPE ||
        header.filterType === DATETIME_TO_DATE_RANGE_FIELD_TYPE ||
        header.filterType === DATE_TIME_RANGE_FIELD_TYPE
      ) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FilterFieldTable
              name={setFieldName(`${header.name}_start`, filterPrefix)}
              label={t("from")}
              type={header.filterType}
              subtype={DATETIME_TO_DATE_RANGE_START_FIELD_SUBTYPE}
              resetPageNumber={resetPageNumber}
              fieldConfig={getFieldConfig(header)}
              filterPrefix={filterPrefix}
            />
            <FilterFieldTable
              name={setFieldName(`${header.name}_end`, filterPrefix)}
              label={t("to")}
              type={header.filterType}
              subtype={DATETIME_TO_DATE_RANGE_END_FIELD_SUBTYPE}
              resetPageNumber={resetPageNumber}
              fieldConfig={getFieldConfig(header)}
              filterPrefix={filterPrefix}
            />
          </div>
        );
      } else {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FilterFieldTable
              name={setFieldName(`${header.name}_from`, filterPrefix)}
              label={t("from")}
              type={header.filterType}
              resetPageNumber={resetPageNumber}
              fieldConfig={getFieldConfig(header)}
              filterPrefix={filterPrefix}
            />
            <FilterFieldTable
              name={setFieldName(`${header.name}_to`, filterPrefix)}
              label={t("to")}
              type={header.filterType}
              resetPageNumber={resetPageNumber}
              fieldConfig={getFieldConfig(header)}
              filterPrefix={filterPrefix}
            />
          </div>
        );
      }
    };

    const getSecondColSpan = () => {
      let colSpan = 1;
      if (showDetailsIcon) colSpan += 1;
      return colSpan;
    };

    return (
      <StyledTableRow>
        {showChangesCircle && (
          <StyledTableCell
            sx={{
              backgroundColor: "var(--light-blue) !important",
            }}
          />
        )}
        {showCheckbox && (
          <StyledTableCell
            sx={{
              backgroundColor: "var(--light-blue) !important",
            }}
          />
        )}
        <StyledTableCell
          colSpan={getSecondColSpan()}
          sx={{
            backgroundColor: "var(--light-blue) !important",
            textAlign: "center",
          }}
        >
          {showCleanFilterIcon && (
            <Tooltip
              title={t("other.universal_header_filter_row.clean_filters")}
            >
              <IconButton
                color="primary"
                onClick={() => onClearParams()}
                sx={{ padding: "5px" }}
              >
                <DeleteIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
          )}
        </StyledTableCell>
        {headersConfig.map((header, index) => (
          <StyledTableCell
            className="capitalize-first-letter"
            key={`${header.name}${index}`}
            align="center"
            sx={{
              verticalAlign: "bottom",
              backgroundColor: "var(--light-blue) !important",
            }}
          >
            {getFilterFieldContent(header)}
          </StyledTableCell>
        ))}
      </StyledTableRow>
    );
  }
);

export default UniversalHeaderFilterRow;

UniversalHeaderFilterRow.propTypes = {
  resetPageNumber: PropTypes.func,
  showCheckbox: PropTypes.bool,
  showDetailsIcon: PropTypes.bool,
  headersConfig: PropTypes.arrayOf(
    PropTypes.shape({
      filterType: PropTypes.string,
      name: PropTypes.string,
      label: PropTypes.string,
      fieldConfig: PropTypes.object,
    })
  ),
  showCleanFilterIcon: PropTypes.bool,
  showChangesCircle: PropTypes.bool,
  excludedKeysForClearSearchParams: PropTypes.array,
};

UniversalHeaderFilterRow.defaultProps = {
  showCleanFilterIcon: false,
  showCheckbox: true,
  showDetailsIcon: false,
  showChangesCircle: false,
};
