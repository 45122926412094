import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import HeaderBar from "../../bar/HeaderBar/HeaderBar";
import PageFooter from "../../footer/pageFooter/PageFooter";


export default function DefaultPageWrapper(props) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      style = {{marginRight:"300px"}}
    >
      <Grid item xs={12}>
        <HeaderBar  titleKey={props.titleKey}  projectTitle ={props.projectTitle}/>
      </Grid>
      <Grid item xs={12} minHeight={{ xs: "70vh", md: "75vh" }}>
        {props.children}
      </Grid>
      <Grid item xs={12} >
        <PageFooter />
      </Grid>
    </Grid>
  );
}

DefaultPageWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.element.isRequired),
  ]),
  titleKey: PropTypes.string.isRequired,
};

DefaultPageWrapper.defaultProps = {};
