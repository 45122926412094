import React from "react";
import PropTypes from "prop-types";

import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";

import { getFieldValueFromSearchParams } from "../../../helpers/methods";

import useFillterButton from "../../../hooks/useFilterButton";
import usePaginationWithSearchParams from "../../../hooks/usePaginationWithSearchParams";

import { IconButton } from "@mui/material";

function HasChangesDotFilter(props) {
  const { searchParams, getFilterFieldKey } = usePaginationWithSearchParams(
    props.filterPrefix
  );
  const { handleClickFilterButton } = useFillterButton(props.filterPrefix);

  const filterValue = getFieldValueFromSearchParams(
    searchParams,
    getFilterFieldKey(props.hasChangesKey)
  );

  const handleClickFilter = (filterValue) => {
    handleClickFilterButton(filterValue, props.hasChangesKey);
  };

  const getDotFilter = () => {
    if (filterValue === "true")
      return (
        <IconButton onClick={() => handleClickFilter(undefined)}>
          <CircleIcon color="success" fontSize="small" />
        </IconButton>
      );

    return (
      <IconButton onClick={() => handleClickFilter(true)}>
        <CircleOutlinedIcon fontSize="small" sx={{ color: "var(--white)" }} />
      </IconButton>
    );
  };

  return getDotFilter();
}

HasChangesDotFilter.propTypes = {
  filterPrefix: PropTypes.string,
  hasChangesKey: PropTypes.string,
};

HasChangesDotFilter.defaultProps = {
  hasChangesKey: "has_changes",
};

export default HasChangesDotFilter;
