import "./styles.css"
import PropTypes from "prop-types";

export default function VerticalSeparator(props) {

    return (
        <div className="verticalLine" style={{ height: `${props.height}` }}/>
    )
}


VerticalSeparator.propTypes = {
    height: PropTypes.string
};

VerticalSeparator.defaultProps = {
    height: "60px"
};
 