import React, { memo } from 'react';
import './nodeButton.css';
import OperationShape from '../OperationShape';


const SipocNode = memo(({
  id,
  data,
  draggable
}) => {
  return (
    <OperationShape
      id={id}
      data={data}
      draggable={draggable}
      label={data.label}
      className={'sipocnode'}
      style={{ width: data.width, height: data.height }}
    />
  );
});

export default SipocNode;
