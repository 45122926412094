import BasicDialog from "../../base/BasicDialog"
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CreateProjectStepper from "../../stepper/CreateProjectStepper";

function ProjectRegistrationDialog(props) {
    const { t } = useTranslation();

    return (
        <BasicDialog
            open={props.open}
            titleAlign="center"
            contentAlign="center"
            title={props.selectedProjectId ? t("dialog.project_registration_dialog.project") : t("dialog.project_registration_dialog.new_project")}
            fullScreen={true}
            showCustomFooter={false}
            showDialogActions={true}
            onClose={props.onClose}
            // titleSx={{ height: `${64}px`, marginLeft: `${openMiniDrawerWidth}px` }}
            showTopCloseButton={false}
        >
            <CreateProjectStepper
                onClose={props.onClose}
                selectedProjectId={props.selectedProjectId}
                onRefetch={props.onRefetch}
            />
        </BasicDialog >
    )
}

ProjectRegistrationDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
};

ProjectRegistrationDialog.defaultProps = {
    open: false
};

export default ProjectRegistrationDialog