import { Button, Grid, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import RegexpService from "../../services/regexpService";
import TextFieldFormControl from "../../components/base/TextFieldFormControl/textFieldFormControl";

export default function ForgotPasswordForm(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [formValue, setFormValue] = useState({ email: '' })

    const changeValue = (e) => {
        const { value, name } = e.target
        setFormValue({ ...formValue, [name]: value })
    }

    const isDisabledButton = () =>{
        return [undefined, null, ''].includes(formValue.email) || !RegexpService.checkEmail(formValue.email)
    }

    return (
        <Paper elevation={3} className={"paper-form"} style={{ maxWidth: "600px", marginTop: "5vh" }}>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={3}
            >
                <Grid item xs={12} spacing={1}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={1}
                    >
                        <Grid item xs={12}>
                            <Typography align="center" style={{ textTransform: 'uppercase' }} variant="h5" display={"block"} gutterBottom>
                                {t("pages.forgot_password.recovering_access_to_your_account")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldFormControl name="email" label={t("universal.email")} value={formValue.email} onChange={changeValue} />
                        </Grid>
                        <Grid item xs={12}>
                            <Button disabled={isDisabledButton()} fullWidth variant="contained" onClick={() => props.onSubmit(formValue)}>{t("pages.forgot_password.recover")}</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >

                        <Button size="small" variant="outlined" onClick={() => navigate('/')}>{t("universal.back")}</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}