const DateService = {
  convertDateToFormatYYYYMMDD: function (date) {
    if (!date) return date;

    if (typeof date === "string") return date.split("T")[0];
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return date.toISOString().split("T")[0];
  },

  convertDateToFormatYYYYMMDD_hhmmss: function (date) {
    if (!date) return date;
    if (typeof date === "string") return date.split(".")[0].replace("T", ' ');
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return date.toISOString().split(".")[0].replace("T", ' ');
  },

  convertDatetimeFromBackendToFormatYYYYMMDD: function (stringDatetime) {
    if (
      typeof stringDatetime === "string" ||
      stringDatetime instanceof String
    ) {
      return this.convertDateToFormatYYYYMMDD(new Date(stringDatetime));
    }
    return stringDatetime;
  },

  convertDatetimeFromBackendToFormatYYYYMMDD_hhmmss: function (stringDatetime) {
    if (
      typeof stringDatetime === "string" ||
      stringDatetime instanceof String
    ) {
      return this.convertDateToFormatYYYYMMDD_hhmmss(new Date(stringDatetime));
    }
    return stringDatetime;
  },

  isValidDate: function (d) {
    return d instanceof Date && !isNaN(d);
  },

  convertDatetimeListToUniqueDateList: function (dateTimeList) {
    return [
      ...new Set(
        dateTimeList.map((date) =>
          this.convertDatetimeFromBackendToFormatYYYYMMDD(date)
        )
      ),
    ];
  },

  getISOStringZeroHourOfDate: function(date){
    var dateTemp = new Date(date)
    dateTemp.setHours(0, 0, 0, 0)
    return dateTemp.toISOString()
  },

  getISOStringleEndHourOfDate: function(date){
    var dateTemp = new Date(date)
    dateTemp.setHours(24, 0, 0, 0)
    return dateTemp.toISOString()
  },

  getFilterDatetimeRangeForData: function(date){
    return {
      start: DateService.getISOStringZeroHourOfDate(date),
      end: DateService.getISOStringleEndHourOfDate(date)
    }
  }
};

export default DateService;
