import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import TablePagination from "@mui/material/TablePagination";
import BasicPagination from "../BasicPagination/basicPagination";

function BasicPaginationWithRowsPerPage(props) {
  const { t } = useTranslation();
  return (
    <TablePagination
      component="div"
      count={props.countRecords}
      page={props.page}
      onPageChange={props.onPageChange}
      rowsPerPage={props.rowsPerPage}
      onRowsPerPageChange={props.onRowsPerPageChange}
      rowsPerPageOptions={props.rowsPerPageOptions}
      labelRowsPerPage={t("rows_per_page")}
      ActionsComponent={BasicPagination}
      labelDisplayedRows={() => undefined}
    />
  );
}

BasicPaginationWithRowsPerPage.propTypes = {
  countRecords: PropTypes.number,
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onPageChange: PropTypes.func,
  rowsPerPage: PropTypes.number,
  onRowsPerPageChange: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
};

export default BasicPaginationWithRowsPerPage;
