import axios from "../api/axios"
import { registrationUrl } from "../helpers/apiUrls"
import { makeRequest } from "../helpers/makeRequest"

export default function useRegistrationService() {
    
    const register = (data) => {    
        return makeRequest(axios, registrationUrl, {
            method: "POST",
            data: data,
        })
    }
    
    return {
        register
    }

  }