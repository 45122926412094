import Grid from "@mui/material/Grid";
import TitleTypography from "../../base/TitleTypography";
import { FoundationBar } from "../../../media/bar";
import { scrumpionUrl } from "../../../helpers/apiUrls";
import VerticalSeparator from "../../other/VerticalSeparator/VerticalSeparator";
import ProjectTitleTypography from "../../base/ProjectTitleTypography";
import Divider from "@mui/material/Divider";

export default function HeaderBar({ titleKey = "menu", projectTitle }) {

  const navigateToFoundation = () => {
    window.open(scrumpionUrl, '_blank').focus()
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid item sm={3} xs={12} />
        <Grid item sm={6.9} xs={12} textAlign={"right"}>
          <ProjectTitleTypography projectTitle={projectTitle} />
        </Grid>
        <Grid
          item
          sm={0.1}
          xs={12}>
          <VerticalSeparator
            height={"65px"} />
        </Grid>
        <Grid
          item
          sm={1.5}
          xs={12}
        >
          <img src={FoundationBar} height={60} onClick={navigateToFoundation} />
        </Grid>
        <Grid item
          md={12}
          xs={12}
          marginBottom={{ xs: 2, md: 0 }}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center">
          <TitleTypography titleKey={titleKey} color={"primary"} />
        </Grid>
      </Grid>
      <Divider />
    </>
  );
}
