import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import './WarningPaper.css'


export default function WarningPaper(props) {

    const getClassName = ()=>{
        var className = "warning-paper"
        if(props.className !== undefined){
            className = `${className} ${props.className}`
        }
        return className
    }

    return(
        <Paper style={props.style} className={getClassName()}>
            {props.children}
        </Paper>
    )
}


WarningPaper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.array
    ]),
    style: PropTypes.object,
    className: PropTypes.string
};

