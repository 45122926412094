import { SELECT_FIELD_TYPE, TEXT_FIELD_TYPE } from "../../helpers/constants";
import i18n from "../../i18n";

export const PRODUCT_BACKLOG_TABLE_FIELDS_CONFIG = [
    {
        name: "pbl_name",
        getValueCallback: (rowData) => rowData.pbl_name,
        label: i18n.t("table.product_backlog_table.name"),
        filterType: TEXT_FIELD_TYPE,
    },
    {
        name: "pbl_note",
        getValueCallback: (rowData) => rowData.pbl_note,
        label: i18n.t("table.product_backlog_table.note"),
        filterType: TEXT_FIELD_TYPE,
    },
    {
        name: "pbl_system",
        getValueCallback: (rowData) => rowData.pbl_system,
        label: i18n.t("table.product_backlog_table.system"),
        filterType: TEXT_FIELD_TYPE,
    },
    {
        name: "pbl_product",
        getValueCallback: (rowData) => rowData.pbl_product,
        label: i18n.t("table.product_backlog_table.product"),
        filterType: TEXT_FIELD_TYPE,
    },
    {
        name: "pbl_technology",
        getValueCallback: (rowData) => rowData.pbl_technology,
        label: i18n.t("table.product_backlog_table.technology"),
        filterType: TEXT_FIELD_TYPE,
    },
    {
        name: "pbl_repository",
        getValueCallback: (rowData) => rowData.pbl_repository,
        label: i18n.t("table.product_backlog_table.repository"),
        filterType: TEXT_FIELD_TYPE,
    },
    {
        name: "pbl_owner",
        getValueCallback: (rowData) => rowData.pbl_owner,
        label: i18n.t("table.product_backlog_table.owner"),
        filterType: SELECT_FIELD_TYPE,
    },

]