import React, { memo } from 'react';
import './titleSipocNode.css';
import { Handle, Position, NodeToolbar } from '@xyflow/react';
import { IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';



const SipocTitleNode = memo(({
  id,
  data,
  style
}) => {

  return (
    <div>
      {data.type === "map" &&
        <NodeToolbar
          isVisible={data.forceToolbarVisible || undefined}
          position={data.toolbarPosition}
        >
          <IconButton
            color="var(--secondary)"
            onClick={() => data.onEditProvider(id)}
            sx={{ padding: "5px" }}
          >
            <EditIcon fontSize="medium" />
          </IconButton>
          <IconButton
            color="var(--secondary)"
            onClick={() => data.onRemoveProvider(id)}
            sx={{ padding: "5px" }}
          >
            <CancelIcon fontSize="medium" />
          </IconButton>
        </NodeToolbar>}
      <div className='nodegometry' style={{ ...style }}>

        <div className={data.type === "sipoc" ? 'sipoctitle' : 'maptitle'}>
          {data.label}
        </div>
      </div>
    </div>
  );
});

export default SipocTitleNode;
