import BasicDialog from "../../base/BasicDialog"
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { useForm } from "../../../hooks/useForm"
import { useAsync, useAsyncFn } from "../../../hooks/useAsync"
import LoadingButton from "@mui/lab/LoadingButton";
import { isEmptyObject, isEmptyValue } from "../../../helpers/methods";
import { useCallback, useState,  useEffect } from "react";
import { Grid } from "@mui/material";
import useProcessService from "../../../services/processService";
import ProcessForm from "../../form/ProcessForm";
import LoaderWrapper from "../../wrapper/LoaderWrapper";


function ProcessDialog(props) {
  const { t } = useTranslation();

  const {
    formValue,
    setFormValue,
    onChange
  } = useForm({ 'project': props.projectId })

  const snackbarAlert = useSnackbarAlert();

  const requiredFields = [
    "process_name",
    "process_owner",
    "process_start",
    "process_stop"
  ];


  const {
    createProcess,
    updateProcess,
    getProcessData
  } = useProcessService()

  const processDetails = useAsync(() => {
    if (props.processId !== undefined) {
      return getProcessData(props.processId)
    }
    return Promise.resolve({})
  }, [props.processId])

  useEffect(() => {
    if (processDetails.loading) return
    if (!isEmptyObject(processDetails.value)) {
      setFormValue(processDetails.value)
    }
  }, [processDetails.loading])

  const createProcessFn = useAsyncFn(createProcess)
  const updateProcessFn = useAsyncFn(updateProcess)

  const isFormValid = () => {
    return requiredFields.every(
      (fieldName) => !isEmptyValue(formValue[fieldName])
    );
  };

  const onSubmit = useCallback((data) => {
    if (props.processId) {
      updateProcessFn
        .execute(props.processId, data)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.process_updated")
          );
          if (props.onRefetch) {
            props.onRefetch();
          }
          props.onClose();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_updating_new_process")
          );
        });
    } else {
      createProcessFn
        .execute(data)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.process_created")
          );
          if (props.onRefetch) {
            props.onRefetch();
          }
          props.onClose();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_creating_new_process")
          );
        });
    }

  }, [])

  return (
    <BasicDialog
      open={props.open}
      titleAlign="center"
      contentAlign="center"
      title={props.processId ? t("dialog.process_dialog.process_details") : t("dialog.process_dialog.new_process")}
      fullScreen={false}
      showCustomFooter={false}
      showDialogActions={true}
      onClose={props.onClose}
      maxWidth={"sm"}
      showTopCloseButton={true}
    >
      <LoaderWrapper isLoading = {processDetails.loading}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ProcessForm
            formValue={formValue}
            onChange={onChange}
            readOnly={false}
          />
        </Grid>
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              loading={props.isLoading}
              disabled={!isFormValid()}
              onClick={() => onSubmit(formValue)}
            >
              {props.processId ? t("universal.create"): t("universal.save")}
            </LoadingButton>
          </Grid>
      </Grid>
      </LoaderWrapper>
    </BasicDialog >
  )
}

ProcessDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  projectId: PropTypes.string,
  processId: PropTypes.string,
  onRefetch: PropTypes.func
};

ProcessDialog.defaultProps = {
  open: false
};

export default ProcessDialog