import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import  PropTypes  from 'prop-types'


export default function PasswordField (props){
    const {t} = useTranslation()
    const [showPassword, setShowPassword] = useState(false)
    const [label, setLabel] = useState()
    useEffect(()=>{
        let label = props.label ? props.label : t("login_page.password")
        label = label.charAt(0).toUpperCase() + label.slice(1)
        setLabel(label)
    }, [props.label, t])

    return (
        <FormControl fullWidth size="small" sx={{m:0}} variant="outlined">
            <InputLabel htmlFor="outlined-andornment-password" className='text=input-label'>{label}</InputLabel>
            <OutlinedInput
                id = {props.name}
                type={showPassword? 'text': 'password'}
                value={[undefined, null].includes(props.value)? '' : props.value}
                onChange={props.onChange}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton onClick={()=>setShowPassword(!showPassword)} edge="end">
                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                    </InputAdornment>
                }
                label={props.label !== undefined ? props.label : t("login_page.password")}
                name={props.name}
                ></OutlinedInput>
        </FormControl>
    )
}

PasswordField.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string
}