import { useState } from "react";
import useDialog from "./useDialog";

const useSipocDialog = () => {
  
  const [openDialog, handleOpenDialog, handleCloseDialog] = useDialog();
  const [sipocNodeKind, setSipocNodeKind] = useState();
  const [positionX, setPositionX] = useState();

  const handleOpenSipocNodeDialog = (nodeKind, positionX) => {
    setSipocNodeKind(nodeKind);
    setPositionX(positionX);
    handleOpenDialog();
  };

  const handleCloseSipocNodeDialog = () => {
    setSipocNodeKind(null);
    setPositionX(null);
    handleCloseDialog();
  };

  return [openDialog, handleOpenSipocNodeDialog, handleCloseSipocNodeDialog, sipocNodeKind, positionX];
};

export default useSipocDialog;
