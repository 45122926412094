import { Dialog, Grid, IconButton, Step, StepLabel, Stepper } from "@mui/material";
import { useTranslation } from "react-i18next";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import SelectField from "../../field/SelectField";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';
import LowPriorityOutlinedIcon from '@mui/icons-material/LowPriorityOutlined';
import React, { useEffect, useMemo, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import CheckboxField from "../../base/CheckboxField/checkboxField";
import useBacklogsService from "../../../services/backlogService";
import { useAsync } from "../../../hooks/useAsync";
import { isEmptyObject, isEmptyValue } from "../../../helpers/methods";
import AddButton from "../../button/AddButton";
import BasicDialog from "../../base/BasicDialog";
import useDialogWithId from "../../../hooks/useDialogWithId";
import { useParams } from "react-router-dom";
import NoRecordsPaper from "../../paper/NoRecordsPaper";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";




const ProductBacklogStepper = React.memo(({ activeStep }) => {
    return (
        <Stepper activeStep={activeStep} alternativeLabel>
            <Step key={1}>
                <StepLabel StepIconComponent={InfoOutlinedIcon}>Informacje ogólne</StepLabel>
            </Step>
            <Step key={2}>
                <StepLabel StepIconComponent={LowPriorityOutlinedIcon}>Parametry priorytetyzacji</StepLabel>
            </Step>
            <Step key={3}>
                <StepLabel StepIconComponent={ViewWeekOutlinedIcon}>Parametry user story</StepLabel>
            </Step>
        </Stepper>
    )
});

const ProductBacklogGeneralInformationForm = React.memo((props) => {
    const { t } = useTranslation();

    const requiredFields = ['pbl_name', 'pbl_system', 'pbl_product', 'pbl_technology', 'owner']

    const checkIsValidData = () => {
        for(const field of requiredFields){
            if(isEmptyValue(props.formValue[field])){
                return false
            }
        }
        return true
    }

    useEffect(()=>{
        props.onChangeIsValidData(checkIsValidData())
    }, [props.formValue, props.onChangeIsValidData])

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextFieldFormControl
                    name={"pbl_name"}
                    label={t(`form.product_backlog.name`)}
                    value={props.formValue?.pbl_name}
                    onChange={props.onChange}
                    disabled={props.readOnly}
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <TextFieldFormControl
                    name={"pbl_system"}
                    label={t(`form.product_backlog.system`)}
                    value={props.formValue?.pbl_system}
                    onChange={props.onChange}
                    disabled={props.readOnly}
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <TextFieldFormControl
                    name={"pbl_product"}
                    label={t(`form.product_backlog.product`)}
                    value={props.formValue?.pbl_product}
                    onChange={props.onChange}
                    disabled={props.readOnly}
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <TextFieldFormControl
                    name={"pbl_technology"}
                    label={t(`form.product_backlog.technology`)}
                    value={props.formValue?.pbl_technology}
                    onChange={props.onChange}
                    disabled={props.readOnly}
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <SelectField
                    name={"owner"}
                    label={t(`form.product_backlog.owner`)}
                    value={props.formValue?.owner}
                    onChange={props.onChange}
                    disabled={props.readOnly}
                    options_url={"users/select_list/"}
                    optionLabel={"full_name"}
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <TextFieldFormControl
                    name={"pbl_repository"}
                    label={t(`form.product_backlog.repository`)}
                    value={props.formValue?.pbl_repository}
                    onChange={props.onChange}
                    disabled={props.readOnly}
                />
            </Grid>
            <Grid item xs={12}>
                <TextFieldFormControl
                    name={"pbl_note"}
                    label={t(`form.product_backlog.note`)}
                    value={props.formValue?.pbl_note}
                    onChange={props.onChange}
                    disabled={props.readOnly}
                    multiline={true}
                    rows={3}
                />
            </Grid>
        </Grid>
    )
});

const ProductBacklogPriorityParamsForm = React.memo(({ priorityParams, formValue, onChange, productBacklogId }) => {

    const onChangeLocal = (priorityMatrix) => {
        const e = { target: { name: 'priority_matrix', value: priorityMatrix } }
        onChange(e)
    }

    const onChangeCheckbox = (e) => {
        const value = e.target.checked
        const paramId = e.target.name
        const element = formValue.find(x => x.priority_param === paramId)
        if (element) {
            element.prioritymatrix_active = value
        }
        else {
            formValue.push({ priority_param: paramId, prioritymatrix_active: value })
        }
        onChangeLocal([...formValue])
    }

    useEffect(() => {
        if (priorityParams !== undefined && (isEmptyValue(formValue))) {
            let formValueTemp = []
            for (const param of priorityParams.filter(x => x.param_active === true)) {
                formValueTemp.push({ priority_param: param.id, prioritymatrix_active: param.param_main })
            }
            onChangeLocal(formValueTemp)
        }
    }, [priorityParams, formValue])

    return (
        <Grid container spacing={1} paddingLeft={1} paddingRight={1}>
            {formValue && priorityParams && priorityParams.filter(x => x.param_active === true).map((param) => (
                <Grid item xs={12} textAlign={'justify'}>
                    <CheckboxField label={param.param_name} labelPlacement={'end'} name={param.id} onChange={onChangeCheckbox} checked={formValue.find(x => x.priority_param === param.id)?.prioritymatrix_active} />
                </Grid>
            ))}
        </Grid>
    )
}
)

const ProductBacklogColumnParamsForm = React.memo(({ columnParams = [], onChange }) => {

    const { projectId } = useParams();
    const [openColumnParamDialog, columnParamId, setOpenColumnParamDialog, handleCloseOpenColumnParamDialog] = useDialogWithId();

    const onChangeLocal = (columnParams) => {
        const e = { target: { name: 'column_param', value: columnParams } }
        onChange(e)
    }

    const onChangeCheckbox = (e, index) => {
        columnParams[index]['column_show'] = !columnParams[index]['column_show']
        onChangeLocal([...columnParams])
    }

    const onSubmitColumnParamDialog = (name, columnParamId) => {
        if (columnParamId) {
        }
        else {
            let columnParamData = {
                'column_name': name,
                'column_show': true,
                'project': projectId
            }
            columnParams.push(columnParamData)
        }
        onChangeLocal([...columnParams])
    }


    const onDelete = (index) => {
        if (columnParams[index]['id']) {
            columnParams[index]['column_is_active'] = false
        }
        else {
            columnParams.slice(index, 1)
        }
        onChangeLocal([...columnParams])
    }

    return (
        <Grid container>
            <Grid item xs={12} textAlign={'right'}>
                <AddButton onClick={() => setOpenColumnParamDialog()} />
            </Grid>
            {columnParams && columnParams.map((param, index) => (
                <>
                    {param.column_is_active !== false && <Grid item xs={12} textAlign={'justify'} >
                        <CheckboxField label={param.column_name} labelPlacement={'end'} onChange={(e) => onChangeCheckbox(e, index)} checked={param.column_show} />
                        <IconButton onClick={() => onDelete(index)}>
                            <DeleteOutlineIcon color="secondary" size="small" />
                        </IconButton>
                    </Grid>}
                </>
            ))}
            {(!columnParams || columnParams.filter(x => x.is_active !== false).length === 0) &&
                <Grid item xs={12}>
                    <NoRecordsPaper label={"Brak parametrów"} />
                </Grid>
            }
            <ColumnParamDialog
                open={openColumnParamDialog}
                onClose={handleCloseOpenColumnParamDialog}
                columnParamId={columnParamId}
                onSubmit={onSubmitColumnParamDialog}
            />
        </Grid >
    )
})

const ColumnParamDialog = React.memo(({ open, onClose, onSubmit, columnParamId }) => {

    const [name, setName] = useState("")

    return (
        <BasicDialog
            open={open}
            onClose={onClose}
            title={"Nowy parametr"}
            maxWidth={"xs"}
        >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextFieldFormControl
                        label={'nazwa'}
                        value={name}
                        onChange={(e) => { setName(e.target.value) }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        size="small"
                        fullWidth
                        onClick={() => { onSubmit(name, columnParamId) }}
                        disabled={name === ""}
                    >
                        Dodaj
                    </Button>
                </Grid>
            </Grid>
        </BasicDialog>
    )
})

const ProductBacklogFormByActiveSteper = React.memo(({ activeStep, formValue, onChange, onChangeStep, onSubmit, priorityParams, isLoadingButton }) => {

    const [isValidData, setIsValidData] = useState(false)

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                {activeStep === 0 && <ProductBacklogGeneralInformationForm formValue={formValue} onChange={onChange} onChangeIsValidData={setIsValidData}/>}
                {activeStep === 1 && <ProductBacklogPriorityParamsForm
                    priorityParams={priorityParams}
                    onChange={onChange}
                    formValue={formValue?.priority_matrix}
                />}
                {activeStep === 2 && <ProductBacklogColumnParamsForm
                    columnParams={formValue?.column_param}
                    onChange={onChange}

                />}
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        {activeStep !== 0 && <Grid
                            container
                            direction="row"
                            sx={{
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}
                        >
                            <Button
                                variant="contained"
                                size="small"
                                fullWidth
                                maxWidth={150}
                                onClick={() => onChangeStep(activeStep - 1)}
                                disabled={isLoadingButton}
                            >
                                Cofnij
                            </Button>

                        </Grid>}
                    </Grid>
                    <Grid item xs={6}>
                        <Grid
                            container
                            direction="row"
                            sx={{
                                justifyContent: "flex-end",
                                alignItems: "center",
                            }}
                        >
                            {activeStep !== 2 && <Button
                                variant="contained"
                                size="small"
                                fullWidth
                                maxWidth={150}
                                onClick={() => onChangeStep(activeStep + 1)}
                                disabled={!isValidData}
                            >
                                Dalej
                            </Button>
                            }
                            {activeStep === 2 && <LoadingButton
                                variant="contained"
                                size="small"
                                fullWidth
                                maxWidth={150}
                                onClick={() => onSubmit()}
                                loading={isLoadingButton}
                            >
                                Zapisz
                            </LoadingButton>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    )

});


export default function ProductBacklogForm({ formValue, isLoadingButton, onSubmit, onChange }) {

    const { t } = useTranslation();

    const { getPriorityParam } = useBacklogsService()
    const priorityParams = useAsync(getPriorityParam)

    const [activeStep, setActiveStep] = useState(0);


    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <ProductBacklogStepper
                    activeStep={activeStep}
                />
            </Grid>
            <Grid item xs={12}>
                <ProductBacklogFormByActiveSteper
                    activeStep={activeStep}
                    formValue={formValue}
                    onChange={onChange}
                    onChangeStep={setActiveStep}
                    onSubmit={onSubmit}
                    isLoadingButton={isLoadingButton}
                    priorityParams={priorityParams.value}
                />
            </Grid>

        </Grid>
    )
}
