import React, { memo, useCallback } from 'react';
import { Handle, Position, NodeToolbar } from '@xyflow/react';
import { IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';



const OperationShape = memo(({
  id,
  data,
  draggable,
  label,
  style,
  className
}) => {

  const editableNodes = ['activity_shape', 'decision_shape', 'comment_shape']

  const getHandles = useCallback((nodeType, sipocKind = undefined) => {
    switch (nodeType) {
      case 'activity_shape':
      case 'decision_shape':
        return (
          <>
            <Handle type="source" position={Position.Top} id="top" style={{ background: '#555' }} />
            <Handle type="source" position={Position.Right} id="right" style={{ background: '#555' }} />
            <Handle type="source" position={Position.Bottom} id="bottom" style={{ background: '#555' }} />
            <Handle type="source" position={Position.Left} id="left" style={{ background: '#555' }} />

            <Handle type="target" position={Position.Top} id="top" style={{ background: '#555' }} />
            <Handle type="target" position={Position.Right} id="right" style={{ background: '#555' }} />
            <Handle type="target" position={Position.Bottom} id="bottom" style={{ background: '#555' }} />
            <Handle type="target" position={Position.Left} id="left" style={{ background: '#555' }} />
          </>
        )
      case 'start_shape':
        return (
          <>
          <Handle type="source" position={Position.Right} id="right"  style={{ background: '#555' }} />
          <Handle type="target" position={Position.Right} id="right"  style={{ background: '#555' }} />
          </>
        )
      case 'end_shape':
        return (
          <>
          <Handle type="sourve" position={Position.Left}  id="left" style={{ background: '#555' }} />
          <Handle type="target" position={Position.Left}  id="left" style={{ background: '#555' }} />
          </>
        )
      case 'comment_shape':
        return (
          <></>
        )
      case 'sipoc_shape':
        return (
          <Handle
            type={sipocKind}
            position={sipocKind === 'source' ? Position.Bottom : Position.Top}
            id={sipocKind}
            style={sipocKind=== 'source' ? { bottom: '1px', top: 'auto' } : { bottom: 'auto', top: '1px' }}
          />
        )
    }
  }, [])


  return (
    <div >
      {data.isTechnicalNode !== true &&
        <NodeToolbar
          isVisible={data.forceToolbarVisible || undefined}
          position={data.toolbarPosition}
        >
          {editableNodes.includes(data.type) &&
            <IconButton
              color="var(--secondary)"
              onClick={() => data.onEdit(id)}
              sx={{ padding: "5px" }}
            >
              <EditIcon fontSize="medium" />
            </IconButton>}
          <IconButton
            color="var(--secondary)"
            onClick={() => data.onRemove(id)}
            sx={{ padding: "5px" }}
          >
            <CancelIcon fontSize="medium" />
          </IconButton>
        </NodeToolbar>}
      <div className={className} style={{ ...style }}>
        {label}
      </div>
      {draggable && data.isTechnicalNode !== true && data.operation_in_waiting_room !== true &&
        <>
          {getHandles(data.type, data.sipocType)}
        </>
      }
    </div>
  );
});

export default OperationShape;
