import BasicDialog from "../../base/BasicDialog"
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useForm } from "../../../hooks/useForm"
import { Grid , Button} from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import { isEmptyValue } from "../../../helpers/methods";

function InviteMemberDialog(props) {
    const { t } = useTranslation();

    const {
        formValue,
        onChange
    } = useForm({})


    const requiredFields = [
        "user_mail",
        "member_name"
    ];

    const isFormValid = () => {
        return requiredFields.every(
            (fieldName) => !isEmptyValue(formValue[fieldName])
        );
    };


    return (
        <BasicDialog
            open={props.open}
            titleAlign="center"
            contentAlign="center"
            title={t("dialog.insert_email_dialog.insert_data_to_invite_new_member")}
            fullScreen={false}
            showCustomFooter={false}
            showDialogActions={true}
            onClose={props.onClose}
            maxWidth={"sm"}
            showTopCloseButton={true}
        >
            <Grid container spacing={1}>
             
                    <Grid item xs={12}>
                        <TextFieldFormControl
                            name="member_name"
                            label={t(`dialog.insert_email_dialog.team_member`)}
                            value={formValue?.member_name}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldFormControl
                            name="user_mail"
                            label={t(`dialog.insert_email_dialog.email`)}
                            value={formValue?.user_mail}
                            onChange={onChange}
                        />
                    </Grid>
        
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={!isFormValid()}
                        onClick={() => props.onInvite(formValue)}
                    >
                        {t("universal.add")}
                    </Button>
                </Grid>
            </Grid>
        </BasicDialog >
    )
}

InviteMemberDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,

};

InviteMemberDialog.defaultProps = {
    open: false,
};

export default InviteMemberDialog