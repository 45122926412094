import { Grid } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import { useForm } from "../../../hooks/useForm";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import useBacklogsService from "../../../services/backlogService";
import { useCallback, useEffect } from "react";
import ProductBacklogForm from "../../form/ProductBacklogForm";
import { isEmptyObject } from "../../../helpers/methods";

export default function ProductBacklogDialog(props) {

    const { t } = useTranslation();
    const {
        formValue,
        setFormValue,
        onChange
    } = useForm({ 'project': props.projectId, 'column_param': [] })

    const snackbarAlert = useSnackbarAlert();

    const {
        createProductBacklog,
        updateProductBacklog,
        getProductBacklogData
    } = useBacklogsService()

    const productBacklogDetails = useAsync(() => {
        if (props.productBacklogId !== undefined) {
            return getProductBacklogData(props.productBacklogId)
        }
        return Promise.resolve({})
    }, [props.productBacklogId])

    const createProductBacklogFn = useAsyncFn(createProductBacklog)
    const updateProductBacklogFn = useAsyncFn(updateProductBacklog)

    useEffect(() => {
        if (productBacklogDetails.loading) return
        if (!isEmptyObject(productBacklogDetails.value)) {
          setFormValue(productBacklogDetails.value)
        }
      }, [productBacklogDetails.loading])

    const onSubmit = useCallback(() => {
        if (props.productBacklogId) {
            updateProductBacklogFn
                .execute(props.productBacklogId, formValue)
                .then((res) => {
                    snackbarAlert.openSuccessSnackbarAlert(
                        t("snackbar_alert.process_updated")
                    );
                    if (props.onRefetch) {
                        props.onRefetch();
                    }
                    props.onClose();
                })
                .catch((error) => {
                    snackbarAlert.openErrorSnackbarAlert(
                        t("snackbar_alert.occurred_error_during_updating_new_process")
                    );
                });
        } else {
            console.log('form', formValue)
            createProductBacklogFn
                .execute(formValue)
                .then((res) => {
                    snackbarAlert.openSuccessSnackbarAlert(
                        t("snackbar_alert.process_created")
                    );
                    if (props.onRefetch) {
                        props.onRefetch();
                    }
                    props.onClose();
                })
                .catch((error) => {
                    console.log(error)
                    snackbarAlert.openErrorSnackbarAlert(
                        t("snackbar_alert.occurred_error_during_creating_new_process")
                    );
                });
        }

    }, [formValue])

    return (
        <BasicDialog
            open={props.open}
            titleAlign="center"
            contentAlign="center"
            title={props.productBacklogId ? t("dialog.product_backlog_dialog.product_backlog_details") : t("dialog.product_backlog_dialog.new_product_backlog")}
            fullScreen={false}
            showCustomFooter={false}
            showDialogActions={true}
            onClose={props.onClose}
            maxWidth={"sm"}
            showTopCloseButton={true}
        >
            <LoaderWrapper isLoading={productBacklogDetails.loading}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <ProductBacklogForm
                            formValue={formValue}
                            onChange={onChange}
                            readOnly={false}
                            onSubmit={onSubmit}
                            isLoadingButton={updateProductBacklogFn.loading || createProductBacklogFn.loading}
                        />
                    </Grid>
                </Grid>
            </LoaderWrapper>
        </BasicDialog >
    )
}