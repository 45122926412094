import i18n from "../../../i18n";

const TITLE_DICT = {
    team: { label: i18n.t("headers.team") },
    process: { label: i18n.t("headers.process") },
    sipoc: { label: i18n.t("headers.sipoc") },
    map_process: { label: i18n.t("headers.map_process") },
    pbl: {label: i18n.t("headers.pbl")}
};

export default TITLE_DICT;
