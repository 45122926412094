import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import { useTranslation } from "react-i18next";


export default function OperationNodeForm(props) {

  const { t } = useTranslation();


  return (
    <Grid container spacing={1}>

    <Grid item xs={4}>
      <TextFieldFormControl
        name = "ops_nr"
        label={t(`form.operation_node_form.ops_nr`)}
        value={props.formValue?.ops_nr}
        onChange = {props.onChange}
      />
    </Grid>

      <Grid item xs={ 8 }>
        <TextFieldFormControl
          label={t(`form.operation_node_form.kind`)}
          value={t(`form.operation_node_form.${props.formValue?.shape}`)}
          disabled={true}
        />
      </Grid>

      <Grid item xs={12}>
        <TextFieldFormControl
          name = "ops_text"
          label={t(`form.operation_node_form.ops_text`)}
          value={props.formValue?.ops_text}
          onChange = {props.onChange}
          multiline
          rows={3}
          resize={"vertical"}
          required
        />
      </Grid>

    </Grid>
  );
};

OperationNodeForm.propTypes = {
  formValue: PropTypes.object,
  onChange: PropTypes.func
};

OperationNodeForm.defaultProps = {
  readOnly: false
};

