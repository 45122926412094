import { useState } from "react";

const useDialog = (initialOpen = false) => {
  const [openDialog, setOpenDialog] = useState(initialOpen);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return [openDialog, handleOpenDialog, handleCloseDialog];
};

export default useDialog;
