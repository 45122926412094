import { Routes, Route, Navigate } from "react-router-dom";
import RequiredAuth from "../../auth/RequiredAuth";

import Layout from "../../wrapper/Layout";
import TeamPage from "../../../pages/TeamPage";
import LoginPage from "../../../pages/LoginPage";
import ForgotPasswordPage from "../../../pages/ForgotPasswordPage";
import DashboardPage from "../../../pages/DashboardPage/DashboardPage";
import ProcessPage from "../../../pages/ProcessPage/ProcessPage"

import { ReactFlowProvider } from "@xyflow/react";
import RegistrationPage from "../../../pages/RegistrationPage";
import LinkPage from "../../../pages/LinkPage";
import SipocPage from "../../../pages/SipocPage/SipocPage";
import MapProcessPage from "../../../pages/MapProcessPage";
import ProductBacklogPage from "../../../pages/ProductBacklogPage";
import ProductBacklogUserStoryPage from "../../../pages/ProductBacklogUserStoryPage";

function Router() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/login/" element={<LoginPage />} />
        <Route path="/registration/" element={<RegistrationPage />} />
        <Route path="/forgot_password/" element={<ForgotPasswordPage pageName={"login"} />} />
        <Route path="/link/:kind/:token/" element={<LinkPage />} />
        <Route element={<RequiredAuth />}>
          <Route path="/dashboard/" element={<DashboardPage />} />
          <Route path="/project/:projectId/team/" element={<TeamPage pageName={"team"} />} />
          <Route path="/project/:projectId/process/" element={<ProcessPage pageName={"process"} />} />

          <Route path="/project/:projectId/process/:processId/sipoc/" element={
            <ReactFlowProvider>
              <SipocPage pageName={"process"} />
            </ReactFlowProvider>
          } />
          
          <Route path="/project/:projectId/process/:processId/map/" element={
            <ReactFlowProvider>
              <MapProcessPage pageName={"process"} />
            </ReactFlowProvider>
          } />

          <Route path="/project/:projectId/product_backlog/" element={<ProductBacklogPage pageName={"product_backlog"} />} />
          <Route path="/project/:projectId/product_backlog/:productBacklogId/user_story/" element={<ProductBacklogUserStoryPage pageName={"pbl"} />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/" element={<Navigate to="/dashboard/" />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default Router;
