import React from "react";
import WarningPaper from "../../base/WarningPaper"
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function NoRecordsPaper({label}) {
  const { t } = useTranslation();
  return (
 <WarningPaper>
      <Typography
        variant="overline"
        display="block"
        gutterBottom
        style={{ margin: 0, fontSize: "20px", color: "var(--primary)", background: 'warning' }}
      >
        {label ? label : t("no_records")}
      </Typography>
   </WarningPaper>
  );
}
