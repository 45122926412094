import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { pl } from "date-fns/locale";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

export default function DateField(props) {
  const { value, onChange, disabled, name, required, showClearIcon } = props;

  return (
    <LocalizationProvider adapterLocale={pl} dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label={props.label.charAt(0).toUpperCase() + props.label.slice(1)}
        inputFormat="dd/MM/yyyy"
        disabled={disabled}
        value={value || null}
        readOnly={props.readOnly}
        open={props.open}
        onClose={props.onClose}
        onChange={(newValue) =>
          onChange({ target: { value: newValue, name: name } })
        }
        renderInput={(params) => (
          <div style={{ position: "relative" }}>
            <TextField
              {...params}
              variant={props.variant}
              size={"small"}
              style={props.style}
              fullWidth
              required={required}
              onKeyDown={(e) => e.preventDefault()}
              onClick={props.onClickTextInput}
              sx={{ ...props.textFieldSx }}
            />
            { showClearIcon && (
              <IconButton
                style={{
                  position: "absolute",
                  top: props.variant === "standard" ? ".5rem" : "0rem",
                  margin: "auto",
                  right: "2rem",
                }}
                onClick={(e) => {
                  e.target.value = "";
                  e.target.name = name;
                  onChange(e);
                }}
              >
                <ClearIcon />
              </IconButton>
            )}
          </div>
        )}
      />
    </LocalizationProvider>
  );
}


DateField.propTypes = {
  style: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  textFieldSx: PropTypes.object,
  onClickTextInput: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  variant: PropTypes.string,
  showClearIcon: PropTypes.bool,
};

DateField.defaultProps = {
  readOnly: false,
  open: null,
  onClose: null,
  variant: "outlined",
  label: "",
  showClearIcon: true,
};
