import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Grid } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import NavigationCircle from "../../other/NavigationCircle";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

export const openMiniDrawerWidth = 180;
export const closeMiniDrawerWidth = 65;

const openedMixin = (theme) => ({
  overflowX: "hidden",
  width: openMiniDrawerWidth,
});

const closedMixin = (theme) => ({
  overflowX: "hidden",
  width: closeMiniDrawerWidth,
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: openMiniDrawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));



export default function MiniDrawer(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = React.useState(true);
  const toggleDrawer = () => {
    setOpenDrawer((prev) => !prev);
  };

  const projectId  = props.projectId


  const width = openDrawer
    ? `calc(100% - ${openMiniDrawerWidth}px)`
    : `calc(100% - ${closeMiniDrawerWidth}px)`;

  const handleClickHome = React.useCallback(() =>{
    navigate(`/dashboard/`, { state: {projectId : projectId}})
  }, [projectId])

  
  return (
    <Grid container>
      <CssBaseline />
      <Drawer variant="permanent" open={openDrawer}>
        <DrawerHeader>
          {openDrawer && (

            <Typography
              className="typography-project"
              variant="h4"
              color={props.color}
              onClick={handleClickHome }
              style={{ cursor: 'default' }}
            >
              HOME
            </Typography>
          )}
          <IconButton onClick={toggleDrawer}>
            {openDrawer === false ? (
              <ChevronRightIcon color={"primary"} />
            ) : (
              <ChevronLeftIcon color={"primary"} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        {openDrawer && props.navigationDrawer.map((content, index) => (
          <NavigationCircle
            index={index}
            navigationSize={props.navigationDrawer.length}
            isOpenDrawer={openDrawer}
            {...content}
          />
        ))}

      </Drawer>
      <div style={{ width: width }}>{props.children}</div>
    </Grid>
  );
}

MiniDrawer.propTypes = {
  navigationDrawer: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      callback: PropTypes.func,
      isOpe: PropTypes.bool,
      isActive: PropTypes.bool,
    })
  ),
};

MiniDrawer.defaultProps = {
};
