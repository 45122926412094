import React, { useState } from "react";
import { Button, Grid, Link, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PropTypes } from 'prop-types'
import { useNavigate } from "react-router-dom";
import LoadingButton from '@mui/lab/LoadingButton';
import PasswordField from "../../components/base/PasswordField";
import TextFieldFormControl from "../../components/base/TextFieldFormControl";

export default function LoginForm(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loginFormValue, setLoginFormValue] = useState({ username: '', password: '' })

    const changeValue = (e) => {
        const { value, name } = e.target
        setLoginFormValue({ ...loginFormValue, [name]: value })
    }

    const onClickLogin = (event) => {
        event.preventDefault();
        props.onClickLogin(loginFormValue)
    }

    const isDisabledButton = () => {
        return [undefined, '', null].includes(loginFormValue.username) || [undefined, '', null].includes(loginFormValue.password)
    }

    return (
        <Paper elevation={3} className={"paper-form"} style={{ maxWidth: "600px", marginTop: "5vh" }}>
            <form onSubmit={onClickLogin}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={1}
                >
                    <Grid item xs={12}>
                        <Typography align="center" style={{ textTransform: 'uppercase' }} variant="h5" display={"block"} gutterBottom>
                            {t("pages.login.login")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldFormControl name="username" label={t("pages.login.username")} value={loginFormValue.username} onChange={changeValue} />
                    </Grid>
                    <Grid item xs={12}>
                        <PasswordField name="password" value={loginFormValue.password} onChange={changeValue} label={t("pages.login.password")} />
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingButton loading={props.showLoadingButton} type="submit" fullWidth variant="contained" onClick={onClickLogin} disabled={isDisabledButton()}>{t("pages.login.login_in")}</LoadingButton>
                    </Grid>
                    <Grid item xs={12}>

                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                        >
                            <Grid item xs={12} sm={6} textAlign={{xs: "center", sm:"left"}}>
                                <Typography fontWeight={"bold"} className="capitalize-first-letter" onClick={() => { navigate('/registration/') }} style={{ cursor: "pointer" }}>{t("pages.login.create_account")}</Typography>
                            </Grid>
                            {/* <Grid item xs={12} sm={6} textAlign={{xs: "center", sm:"right"}}>
                                <Typography className="capitalize-first-letter" onClick={() => { navigate('/forgot_password/') }} style={{ cursor: "pointer" }}>{t("pages.login.forgot_password")}</Typography>
                            </Grid> */}
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    )
}

LoginForm.propTypes = {
    onClickLogin: PropTypes.func,
    showLoadingButton: PropTypes.bool
}