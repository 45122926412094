export const TECHNICAL_NODES = [
    {
      id: "shapes",
      data: {  type:  'structure' },
      draggable : false,
      position: { x: 0, y: 100 },
      className: 'light',
      style: { backgroundColor: 'rgb(255, 255, 255)', borderRadius : 0, width: 150, height: 500, zIndex : -1},
      type: 'group', //zmienić na custom bez drgable points
    },
    {
      id: 'sshape2',
      data: { label: undefined,  buttonCollor: 'rgb(255, 153, 51, 1)',   type:  'start_shape', width : 75, height: 50 },
      position: { x: 35, y: 20 },
      className: 'light',
      parentId: "shapes",
      extent: 'parent',
      type: 'startShape',
      style: {},
      draggable: false
    }, 
    {
      id: 'sshape',
      data: { label: undefined, buttonCollor: 'rgb(255, 153, 51, 1)',  type:  'start_shape', width : 75, height: 50, isTechnicalNode : true },
      position: { x: 35, y: 120 },
      className: 'light',
      type: 'startShape',
      style: {},
      draggable: true
    },
    {
      id: 'ashape2',
      data: { label: undefined,  buttonCollor: 'rgb(0, 0, 204, 1)', type:  'structure', width :100, height: 75 },
      position: { x: 25, y: 120 },
      className: 'light',
      parentId: "shapes",
      extent: 'parent',
      type: 'activityShape',
      style: {},
      draggable: false
    },
    {
      id: 'ashape',
      data: { label: undefined,  buttonCollor: 'rgb(20, 0, 204, 1)' , type:  'activity_shape', isTechnicalNode : true, width :100, height: 75},
      position: { x: 25, y: 220 },
      className: 'light',
      type: 'activityShape',
      style: {},
      draggable: true
    },
    
    
    {
      id: 'dshape2',
      data: { label: undefined, buttonCollor: 'rgb(255, 0, 34)',   type:  'structure', width :120 }, 
      position: { x: 10, y: 220 },
      className: 'light',
      parentId: "shapes",
      extent: 'parent',
      type: 'decisionShape',
      style: {},
      draggable: false
    },
    {
      id: 'dshape',
      data: { label: undefined , buttonCollor: 'rgb(255, 0, 34)',  type:  'decision_shape', isTechnicalNode : true, width :120},
      position: { x: 10, y: 320 },
      className: 'light',
      type: 'decisionShape',
      style: {},
      draggable: true
    },
    
    {
      id: 'cshape2',
      data: { label: undefined, buttonCollor: 'rgb(231, 164, 20)',   type:  'structure', width :120, height : 50 }, 
      position: { x: 10, y: 320 },
      className: 'light',
      parentId: "shapes",
      extent: 'parent',
      type: 'commentShape',
      style: {},
      draggable: false
    },
    {
      id: 'cshape',
      data: { label: undefined ,   buttonCollor: 'rgb(231, 164, 20)',   type:  'comment_shape', isTechnicalNode : true, width :120, height : 50 },
      position: { x: 10, y: 420 },
      className: 'light',
      type: 'commentShape',
      style: {},
      draggable: true
    },
    {
      id: 'eshape2',
      data: { label: undefined,  buttonCollor: 'rgb(4 168 4)',  type:  'end_shape', width : 75, height: 50 },
      position: { x: 35, y: 420 },
      className: 'light',
      type: 'endShape',
      parentId: "shapes",
      extent: 'parent',
      style: {},
      draggable: false
    },
    {
      id: 'eshape',
      data: { label: undefined, buttonCollor: 'rgb(4 168 4)',  type:  'end_shape', width : 75, height: 50, isTechnicalNode : true },
      position: { x: 35, y: 520 },
      className: 'light',
      type: 'endShape',
      style: {},
      draggable: true
    }
  ]