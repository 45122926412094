import jwtDecode from "jwt-decode";
import i18n from "../i18n";
import moment from "moment";
import { FILTER_SEPARATOR } from "./constants";

export function title(string) {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const validToken = (token) => {
  return token && jwtDecode(token).exp > Date.now() / 1000;
};

export function isEven(n) {
  return n % 2 == 0;
};

export function setUrlParamsFromMultiChoiceFilter(paramName, choiceList) {
  if (choiceList.length === 0) return "";
  return `${paramName}__in=${choiceList.join(",")}`;
}

export function isNumeric(str) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function prepareUrlWithQueryParams(url, queryParams) {
  if (queryParams) {
    var queryString = "";
    if (
      queryParams instanceof URLSearchParams ||
      typeof queryParams === "string"
    ) {
      queryString = queryParams.toString();
    } else {
      queryString = Object.keys(queryParams)
        .map((key) => {
          const value = queryParams[key];
          if (Array.isArray(value)) {
            return value.map((arr_val) => `${key}=${arr_val}`).join("&");
          }
          return key + "=" + queryParams[key];
        })
        .join("&");
    }
    if (queryString) {
      url = `${url}?${queryString}`;
    }
  }
  return url;
}

export function prepareQueryParamsFromObject(paramsObject) {
  return Object.keys(paramsObject)
    .map((key) => key + "=" + paramsObject[key])
    .join("&");
}

export function getFieldsFromObject(obj, fieldsNamesArray) {
  const filteredObj = Object.entries(obj).reduce((acc, [key, value]) => {
    if (fieldsNamesArray.includes(key)) {
      acc[key] = value ? value : null;
    }
    return acc;
  }, {});
  return filteredObj;
}

export function checkIfIsUndefinedOrNullOrEmpty(value) {
  return [undefined, null, ""].includes(value);
}

export function getFieldValueFromSearchParams(searchParams, key) {
  const value = searchParams.getAll(key);
  return value.length > 1 ? value : value[0];
}

export function getObjectFromSearchParams(searchParams, exculdedParams) {
  const searchParamsObject = {};
  for (let key of searchParams.keys()) {
    if (exculdedParams && exculdedParams.includes(key)) continue;
    searchParamsObject[key] = getFieldValueFromSearchParams(searchParams, key);
  }
  return searchParamsObject;
}

export function getQueryStringFromSearchParams(searchParams, exculdedParams) {
  let queryString = "";

  for (const [key, value] of Object.entries(
    getObjectFromSearchParams(searchParams)
  )) {
    if (exculdedParams && exculdedParams.includes(key)) continue;
    if (Array.isArray(value)) {
      for (let arr_val of value) {
        queryString += `&${key}=${arr_val}`;
      }
    } else queryString += `&${key}=${value}`;
  }

  return queryString.length > 0 ? queryString.slice(1) : "";
}


export const convertFlatListFromBackendToTranslatedOptionObject = (
  valueList,
  optionKey = "id",
  optionLabelKey = "name"
) => {
  if (!valueList) return [];
  return valueList.map((value) => ({
    [optionKey]: value,
    [optionLabelKey]: i18n.t(`backend_choices_list.${value}`),
  }));
};

export const convertObjectListFromBackendToTranslatedOptionObject = (
  valueList,
  optionLabelKey = "name"
) => {
  if (!valueList) return [];
  return valueList.map((value) => ({
    ...value,
    [optionLabelKey]: i18n.t(`backend_choices_list.${value[optionLabelKey]}`),
  }));
};

export function convertBoolenValueToZero(value) {
  return value ? 1 : 0;
}

export function convertUndefinedValueToZero(value) {
  return !isEmptyValue(value) ? value : 0;
}

export function convertEmptyValueToZero(value) {
  return !isEmptyValue(value) ? value : 0;
}

export function convertEmptyValueOrMinusCharToZero(value) {
  return !isEmptyValue(value) && value !== "-" ? value : 0;
}

export function getCurrentDate() {
  return new Date().toISOString().slice(0, 10);
}

export function isEmptyValue(value) {
  return [null, undefined, ""].includes(value); 
}

export function isEmptyArray(arr) {
  return arr.length === 0;
}

export function isEmptyObject(object) {
  return Object.keys(object).length === 0;
}

export function roundToTwoPlaces(value) {
  return Math.round(value * 100) / 100;
}

export function roundPrice(price) {
  return roundToTwoPlaces(price);
}

export function roundToInteger(value) {
  return Math.ceil(value);
}

export function convertPlnValueToOtherCurrency(plnValue, currencyValue) {
  return roundPrice(plnValue / currencyValue);
}

export function convertOtherCurrencyToPlnValue(
  otherCurrencyValue,
  currencyValue
) {
  return roundPrice(otherCurrencyValue * currencyValue);
}


export function round(number, decimalPlaces) {
  if (!isEmptyValue(number) && !isNaN(number)) {
    return +(Math.round(number + `e+${decimalPlaces}`) + `e-${decimalPlaces}`);
  }
  return number;
}

export function formatNumber(number, separator = " ") {
  const parts = number.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  return parts.join(".");
}

export function unformatNumber(formattedNumber) {
  const unformattedString = formattedNumber.replace(/\s/g, "");
  return unformattedString;
}

export const getRandomString = () => {
  return (Math.random() + 1).toString(36).substring(2);
};

export function countPercent(
  nominator,
  denominator,
  roundPercentToTwoPlaces = true,
  addPercentChar = false
) {
  if (denominator !== undefined) {
    var percent = (nominator / denominator) * 100;
    if (roundPercentToTwoPlaces) {
      percent = roundToTwoPlaces(percent);
    }
    if (addPercentChar) {
      percent = `${percent}%`;
    }
    return percent;
  }
  return "-";
}

export function checkIfValueIsObject(value) {
  return typeof value === "object" && value !== null;
}

export function convertEmptyValueToNull(value) {
  return !isEmptyValue(value) ? value : null;
}

export function returnEmptyStringWhenIsUndefined(value) {
  if ([undefined, null].includes(value)) {
    return "";
  }
  return value;
}
export function getMaxFlotWhenIsUndefined(value) {
  if (value) {
    return value;
  }
  return Number.MAX_VALUE;
}

export function getMinFlotWhenIsUndefined(value) {
  if (value) {
    return value;
  }
  return Number.MIN_VALUE;
}

export function convertStringBoolValueToBool(value) {
  if (!["true", "false"].includes(value)) return value;
  return value === "true" ? true : false;
}

export function isObjectEmpty(_object) {
  return Object.keys(_object).length === 0;
}

export function isSearchParamsEmpty(searchParams) {
  return searchParams.size === 0;
}

export function removeElementsInArrayByIndex(array, index) {
  if (index < 0 || index >= array.length) {
    console.error("Bad index");
    return;
  }
  array.splice(index + 1, array.length - index);
  return array;
}

export function getKeysFromFilterDict(array) {
  return array.map((el) => {
    return el.key;
  });
}

export function getInitSelectedKeyFromDict(array){
  let keys = []
  for (let dict of array){
    if (dict.selectedDefault){
      keys.push(dict.key)
    }
  }
  return keys
}



export function isDictsAreEqual(dict1, dict2) {
  if (Object.keys(dict1).length !== Object.keys(dict2).length) {
    return false;
  }

  for (const key in dict1) {
    if (dict1[key] !== dict2[key]) {
      return false;
    }
  }

  return true;
}



export function convertMomentInstanceToStringDatetimeInUtc(momentInstance) {
  momentInstance = moment(momentInstance);
  momentInstance.utc();
  return momentInstance.format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ");
}

export function getTimeInHHMMFormatFromMinutes(mins) {
  if (mins < 0) {
    throw new RangeError("Valid input should be greater than or equal to 0");
  }
  let h = (mins / 60) | 0;
  let m = mins % 60 | 0;
  return `${h.toString().padStart(2, "0")}:${m.toString().padStart(2, "0")}`;
}

export function findAndDeleteObjectFromArray(
  array,
  valueToFind,
  fieldKey = "id"
) {
  const index = array.findIndex(function (x) {
    return x[fieldKey] === valueToFind;
  });
  if (index !== -1) {
    array.splice(index, 1);
  }
  return {
    array: array,
    deleted: index !== -1 
  }
}

export function getFilterSearchParamsKey(filterPrefix) {
  if (!isEmptyValue(filterPrefix)) {
    return `${filterPrefix}${FILTER_SEPARATOR}`;
  }
  return "";
}

export function getFilterFieldKeyByNameAndPrefix(fieldName, prefix) {
  if (prefix) {
    return `${getFilterSearchParamsKey(prefix)}${fieldName}`;
  }
  return fieldName;
}


export function getFilterSearchParamsKeyForField(fieldKey, filterPrefix) {
  return `${getFilterSearchParamsKey(filterPrefix)}${fieldKey}`;
}

export function clearSearchParamsByFilterPrefixesFn(
  searchParams,
  filterPrefixes = [],
  excludedKeys = []
) {
  let clearedParams = {};
  const filterKeys = filterPrefixes.map((prefix) =>
    getFilterSearchParamsKey(prefix)
  );

  const excludedFilterKeys = ["page_size", ...excludedKeys]
    .map((key) =>
      filterPrefixes.map((filterKey) =>
        getFilterSearchParamsKeyForField(key, filterKey)
      )
    )
    .flat();

  searchParams.forEach((value, key) => {
    if (
      filterKeys.every((filterKey) => !key.includes(filterKey)) ||
      excludedFilterKeys.includes(key)
    ) {
      clearedParams[key] = getFieldValueFromSearchParams(searchParams, key);
    }
  });
  return clearedParams;
}

export function clearSearchParamsByFilterPrefixFn(
  searchParams,
  filterPrefix = "",
  excludedKeys = [],
  excludePageSize = true
) {
  let clearedParams = {};
  const filterKey = getFilterSearchParamsKey(filterPrefix);
  let excludedFilterKeys = [];
  if (excludePageSize) {
    excludedFilterKeys.push("page_size");
  }
  excludedFilterKeys = [excludedFilterKeys, ...excludedKeys].map((key) =>
    getFilterSearchParamsKeyForField(key, filterPrefix)
  );

  searchParams.forEach((value, key) => {
    if (
      (!isEmptyValue(filterPrefix) && !key.includes(filterKey)) ||
      excludedFilterKeys.includes(key)
    ) {
      clearedParams[key] = getFieldValueFromSearchParams(searchParams, key);
    }
  });
  return clearedParams;
}

export const convertOrderArrayToOrderObject = (orderArray) => {
  let orderObject = {};
  for (let key of orderArray) {
    orderObject[key] = orderArray.indexOf(key);
  }
  return orderObject;
};

export const sortArrayOfObjectsByOrderArray = (
  arrayOfObjects,
  orderArray,
  key = "name"
) => {
  if (!orderArray) return arrayOfObjects;

  const orderObject = convertOrderArrayToOrderObject(orderArray);

  let resArrayOfObjects = [...arrayOfObjects];

  resArrayOfObjects.sort((a, b) => {
    let aName = a[key];
    let bName = b[key];

    if (orderObject[aName] === undefined && orderObject[bName] === undefined)
      return 0;

    if (orderObject[bName] === undefined) return -1;
    if (orderObject[aName] === undefined) return 1;

    if (orderObject[aName] <= orderObject[bName]) return -1;

    return 1;
  });

  return resArrayOfObjects;
};


export const getErrorMsg = (
  errorData,
  defaultErrorMsg = i18n.t(`snackbar_alert.occurred_unknown_error`)
) => {
  let errorCode = errorData.error_code;
  if (!errorCode) {
    return defaultErrorMsg;
  }

  const tags = [...errorCode.matchAll("\\[.*?\\]")];
  if (tags) {
    for (const [index, tag] of tags.entries()) {
      errorCode = errorCode.replace(tag, `\$${index}`);
    }
  }

  let errorMsg = i18n.t(`snackbar_alert.${errorCode}`);
  if (errorMsg.includes("snackbar_alert")) {
    return defaultErrorMsg;
  }
  if (tags) {
    for (const [index, tag] of tags.entries()) {
      errorMsg = errorMsg.replace(
        `\$${index}`,
        tag[0].substring(1, tag[0].length - 1)
      );
    }
  }

  return errorMsg;
};
