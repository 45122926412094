import React, { memo } from 'react';
import './shapeNode.css';
import OperationShape from '../OperationShape';
import '../OperationShape/operationNode.css'


const CommentShape = memo(({
  id,
  data,
  draggable
}) => {

  return (
    <OperationShape
    id={id}
      data={data}
      draggable={draggable}
      label={data.label !== undefined ? data.label : "KOMENTARZ"}
      className={'commentNode tri-right btm-left operationNode'}
      style={{ width: data.width, height: data.height }}
    />

  );
});

export default CommentShape;
