import React from "react";
import { Pagination } from "@mui/material";
import PropTypes from "prop-types";
import "./BasicPagination.css";

import { PAGINATION_PAGE_SIZE } from "../../../helpers/constants";

export default function BasicPagination(props) {
  if (props.count <= props.rowsPerPage) return <></>;

  return (
    <Pagination
      style={props.style}
      sx={{ ".css-wjh20t-MuiPagination-ul": { flexWrap: "nowrap" } }}
      size={props.size}
      count={Math.ceil(props.count / props.rowsPerPage)}
      color={"primary"}
      page={![undefined, null].includes(props.page) ? parseInt(props.page) : 1}
      className={`align-${props.position}`}
      onChange={props.onPageChange}
    />
  );
}

BasicPagination.propTypes = {
  position: PropTypes.oneOf(["right", "left"]),
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  count: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func,
  size: PropTypes.string,
  style: PropTypes.object,
};

BasicPagination.defaultProps = {
  position: "right",
  page: 1,
  rowsPerPage: PAGINATION_PAGE_SIZE,
  size: "small",
};
