import * as React from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { StyledTableHead } from "../../base/BaseTable/BaseTable";
import FilterTableDataRow from "./FilterTableDataRow";
import { Typography } from "@mui/material";
import NoRecordsTableFooter from "../../footer/NoRecordsTableFooter";
import UniversalTableHeaders from "../UniversalTable/Headers/UniversalTableHeaders";
import BottomTable from "../../other/BottomTable";
import WindowService from "../../../services/windowService";
import { convertBoolenValueToZero } from "../../../helpers/methods";

const FilterTable = React.memo((props) => {


  return (
    <>
      <TableContainer component={Paper} style={props.style}>
        {props.showTitle && props.title && (
          <Typography variant="h6" color="primary">
            {props.title}
          </Typography>
        )}
        <Table>
          <StyledTableHead>
            <UniversalTableHeaders
              showDetailsIcon={props.showDetailsIcon}
              showChangesCircle={props.showChangesCircle}
              showFilters={props.showFilters}
              showCheckbox={props.showCheckbox}
              checkedAll={props.checkedAll}
              onClickCheckAll={props.onClickCheckAll}
              headersConfig={props.headersConfig}
              resetPageNumber={props.resetPageNumber}
              filterPrefix={props.filterPrefix}
              showCleanFilterIcon={props.showCleanFilterIcon}
            />
          </StyledTableHead>
          <TableBody>
            {props.data.map((rowData, index) => (
              <FilterTableDataRow
                hasChanges={rowData.hasChanges}
                showChangesCircle={props.showChangesCircle}
                showFilters={props.showFilters}
                showCheckbox={props.showCheckbox}
                showDetailsIcon={props.showDetailsIcon}
                onClickDetailsIcon={() =>
                  WindowService.openInNewTab(
                    `/${props.itemDetailsLinkPrefix}/${rowData.id}/`
                  )
                }
                key={rowData.id}
                id={rowData.id}
                updated={rowData.updated}
                selected={rowData.selected}
                data={rowData.data}
                onClickCheck={props.onClickCheck}
                onClickRow={props.onClickRow}
                showContextMenu={props.showContextMenu}
                contextMenuActions={props.contextMenuActions}
                draggableRow={props.draggableRows}
                onDragEnd={props.onDragEndRow}
              />
            ))}
          </TableBody>

          {props.data.length === 0 && (
            <NoRecordsTableFooter
              colspan={
                convertBoolenValueToZero(props.showCheckbox) +
                convertBoolenValueToZero(props.showChangesCircle) +
                convertBoolenValueToZero(props.showDetailsIcon) +
                convertBoolenValueToZero(props.showFilters) +
                props.headersConfig.length
              }
            />
          )}
        </Table>
      </TableContainer>
      <BottomTable
        showExportToFileButton={props.showExportToFileButton}
        showCounterRecords={props.showCounterRecords}
        withPagination={props.withPagination}
        page={props.page}
        countRecords={props.countRecords ? props.countRecords : props.data.length}
        onPageChange={props.onPageChange}
        rowsPerPage={props.rowsPerPage}
        onRowsPerPageChange={props.onRowsPerPageChange}
        rowsPerPageOptions={props.rowsPerPageOptions}
        exportToFileUrl={props.exportToFileUrl}
        exportToFileSearchParams={props.exportToFileSearchParams}
        exportToFileHeaders={props.exportToFileHeaders}
        exportToFileFileName={props.exportToFileFileName}
      />
    </>
  );
});

export default FilterTable;

FilterTable.propTypes = {
  showCheckbox: PropTypes.bool,
  showDetailsIcon: PropTypes.bool,
  itemDetailsLinkPrefix: PropTypes.string,
  checkedAll: PropTypes.bool,
  onClickCheckAll: PropTypes.func,
  onClickCheck: PropTypes.func,
  onClickRow: PropTypes.func,
  data: PropTypes.array,
  headersConfig: PropTypes.object,
  filteringData: PropTypes.object,
  countRecords: PropTypes.number,
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onPageChange: PropTypes.func,
  rowsPerPage: PropTypes.number,
  onRowsPerPageChange: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  resetPageNumber: PropTypes.func,
  CollapsibleElement: PropTypes.element,
  selectedItemId: PropTypes.string,
  style: PropTypes.object,
  showTitle: PropTypes.bool,
  title: PropTypes.string,
  filterPrefix: PropTypes.string,
  showCleanFilterIcon: PropTypes.bool,
  showFilters: PropTypes.bool,
  withPagination: PropTypes.bool,
  showContextMenu: PropTypes.bool,
  contextMenuActions: PropTypes.arrayOf(PropTypes.object),
  draggableRows: PropTypes.bool,
  onDragEndRow: PropTypes.func,
  showChangesCircle: PropTypes.bool,
  hasChanges: PropTypes.bool,
  showExportToFileButton: PropTypes.bool,
  exportToFileUrl: PropTypes.string,
  exportToFileSearchParams: PropTypes.object,
  exportToFileHeaders: PropTypes.array,
  exportToFileFileName: PropTypes.string,
  showCounterRecords: PropTypes.bool,
};

FilterTable.defaultProps = {
  showDetailsIcon: false,
  showCheckbox: true,
  showTitle: false,
  title: "",
  showCleanFilterIcon: false,
  showFilters: true,
  onClickRow: () => {},
  showContextMenu: false,
  contextMenuActions: [],
  withPagination: true,
  draggableRows: false,
  showPagination: true,
  showChangesCircle: false,
  hasChanges: true,
  showCounterRecords: true,
};
