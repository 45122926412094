import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useTheme } from "@emotion/react";

const CardIconWithText = React.memo((props) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        color: "#FFFFFF",
        background: props.isActive ? theme.palette.primary.light : "grey",
  borderRadius: "25px"
      }}
    >
      <CardActionArea
        sx={{ padding: 1, ...props.cardAreaStyle }}
        onClick={props.isActive ? props.onClick : undefined}
      >
        <CardContent sx={{ padding: "0 !important" }}>
          <Typography
            variant="body2"
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {props.label}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
});

CardIconWithText.propTypes = {
  label: PropTypes.string,
  isActive: PropTypes.bool,
  cardAreaStyle: PropTypes.object,
};

CardIconWithText.defaultProps = {
  categories: [],
  isSelected: false,
};

export default CardIconWithText;
