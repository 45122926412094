export const overflowButtonStyle = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  display: "inline-block",
};

export const centerVericalAlignIconStyle = {
  verticalAlign: "sub",
};

