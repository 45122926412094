import { Grid } from "@mui/material"
import { useEffect, useState } from "react"
import LoaderWrapper from "../../components/wrapper/LoaderWrapper"
import { useParams } from "react-router-dom";
import useLinkService from "../../services/linkService";
import { useAsync } from "../../hooks/useAsync";
import { useSnackbarAlert } from "../../context/snackbarAlert";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/auth";

export default function LinkPage(props) {

    const { onSuccessLogin } = useAuth()
    const { kind, token } = useParams();

    const { t } = useTranslation();

    const snackbarAlert = useSnackbarAlert();

    const { getLink } = useLinkService();

    const getLinkAsync = useAsync(() => (getLink(kind, token)
        .then((data) => {
            onSuccessLogin(data)
        })
        .catch(() => {
        snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.bad_link")
        );
        }))
    );

    // useEffect(()=>{
    //     onSuccessLogin(getLinkAsync.value)
    //     snackbarAlert.openSuccessSnackbarAlert(t("snackbar_alert.logged_in"));
    // }, [getLinkAsync.value])

    // useEffect(()=>{
    //     snackbarAlert.openErrorSnackbarAlert(
    //         t("snackbar_alert.bad_link")
    //     );
    // }, [getLinkAsync.error])

    return (
        <LoaderWrapper showLoader={getLinkAsync.loading}>
            <Grid>

            </Grid>
        </LoaderWrapper>
    )
}