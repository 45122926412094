import { useNavigate, useParams } from "react-router-dom";
import NavigationDrawer from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper/DefaultPageWrapper";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { useAsync } from "../../hooks/useAsync";
import useProjectService from "../../services/projectService";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import useBacklogsService from "../../services/backlogService";
import TableService from "../../services/tableService";
import { PRODUCT_BACKLOG_TABLE_FIELDS_CONFIG } from "./TablesConfig";
import { Grid } from "@mui/material";
import FilterTable from "../../components/table/FilterTable";
import AddButton from "../../components/button/AddButton";
import useDialogWithId from "../../hooks/useDialogWithId";
import ProductBacklogDialog from "../../components/dialog/ProductBacklogDialog";
import EditIcon from '@mui/icons-material/Edit';

export default function ProductBacklogPage(props) {
    const { pageName } = props;

    const [projectTitle, setProjectTitle] = useState()
    const { projectId } = useParams();
    const { t } = useTranslation();
    const [readOnly, setReadOnly] = useState(false)

    const navigate = useNavigate();

    const [
        openProductBacklogDialog,
        productBacklogDialogId,
        onOpenProductBacklogDialog,
        onCloseProductBacklogDialog,
    ] = useDialogWithId();


    const {
        getProjectAccess
    } = useProjectService();
    const projectAccessData = useAsync(() => {
        if (projectId) {
            return getProjectAccess(projectId)
        }
        return Promise.resolve({})
    }, [projectId])

    const {
        getProductBacklogsForProject
    } = useBacklogsService();


    const productBacklogsList = useAsync(() => {
        if (projectId) {
            return getProductBacklogsForProject(projectId)
        }
        return Promise.resolve([])
    }, [projectId])

    const onRefetchData = () => {
        productBacklogsList.refetch()
        projectAccessData.refetch()
      }


    useEffect(() => {
        if (projectAccessData.loading) return
        if (projectAccessData.value) {
            setProjectTitle(projectAccessData.value.project_name)
        }
    }, [projectAccessData.loading])

    const data = useMemo(
        () =>
            TableService.getPreparedDataForCollapsibleTable(
                PRODUCT_BACKLOG_TABLE_FIELDS_CONFIG,
                productBacklogsList.value?.results || [],
                [],
                []
            ),
        [productBacklogsList.value, PRODUCT_BACKLOG_TABLE_FIELDS_CONFIG]
    );

    const contextMenuActions = [
        {
          label: t("universal.edit"),
          callback: (productBacklogId) => {
            onOpenProductBacklogDialog(productBacklogId)
          },
          icon: <EditIcon fontSize="small" />,
        }
      ];

    if (projectAccessData.loading) return (<LoaderWrapper showLoader={true} />)

    return (
        <NavigationDrawer pageName={pageName} projectAccessData={projectAccessData?.value}>
            <DefaultPageWrapper titleKey={"pbl"} projectTitle={projectTitle} >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    columnSpacing={1}
                    rowSpacing={1}
                    style={{ marginTop: "2px" }}
                >
                    <Grid item xs={12} textAlign={'right'}>
                        <AddButton onClick={() => onOpenProductBacklogDialog(undefined)} />
                    </Grid>
                    <Grid item xs={12}>
                        <FilterTable
                            onClickRow={(productBacklogId) => navigate(`${productBacklogId}/user_story/`)}
                            data={data}
                            filteringData={[]}
                            countRecords={false}
                            showCounterRecords={false}
                            headersConfig={TableService.getHeadersConfigForCollapsibleTable(
                                PRODUCT_BACKLOG_TABLE_FIELDS_CONFIG,
                                [],
                                [],
                                [],
                            )}
                            showCheckbox={false}
                            showFilters={false}
                            withPagination={false}
                            showCleanFilterIcon={false}
                            showContextMenu={!readOnly}
                            showDetailsIcon={false}
                            contextMenuActions={contextMenuActions}
                        />
                    </Grid>
                </Grid>
                {openProductBacklogDialog &&
          <ProductBacklogDialog
            open={openProductBacklogDialog}
            onClose={onCloseProductBacklogDialog}
            productBacklogId={productBacklogDialogId}
            projectId={projectId}
            onRefetch={onRefetchData}
          />
        }
            </DefaultPageWrapper>
        </NavigationDrawer>
    )
}