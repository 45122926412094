import axios from "axios";
import { ScrumpionAppApiUrl } from "../helpers/apiUrls";

export default axios.create({
  withCredentials: true,
  baseURL: ScrumpionAppApiUrl,
});

export const axiosPrivate = axios.create({
  withCredentials: true,
  baseURL: ScrumpionAppApiUrl
});
