import React, { memo } from 'react';
import './shapeNode.css';
import OperationShape from '../OperationShape';
import '../OperationShape/operationNode.css'



const EndShape = memo(({
  id,
  data,
  draggable
}) => {

  return (
    <OperationShape
    id={id}
    data={data}
    draggable={draggable}
    label={data.label !== undefined ? data.label : "STOP"}
    className={'endNode operationNode'}
    style={{ width: data.width, height: data.height }}
  />
  );
});

export default EndShape;
