import { useState } from "react";
import useDialog from "./useDialog";

const useOperationDialog = () => {
  
  const [openDialog, handleOpenDialog, handleCloseDialog] = useDialog();
  const [id, setId] = useState();
  const [operationKind, setOperationKind] = useState();
  const [opsProvider, setOpsProvider] = useState();
  const [XCoordinate, setXCoordinate] = useState();
  const [YCoordinate, setYCoordinate] = useState();
  const [color, setColor] = useState();

  const handleOpenOperationNodeDialog = (operationKind, provider, XCoordinate, YCoordinate, color) => {
    setOperationKind(operationKind);
    setOpsProvider(provider)
    setXCoordinate(XCoordinate)
    setYCoordinate(YCoordinate)
    setColor(color)
    handleOpenDialog();
  };

  const handleOpenOperationNodeDialogWithId = ( id) => {
    setId(id)
    handleOpenDialog();
  };

  const handleCloseOperationNodeDialog = () => {
    setId(null)
    setOperationKind(null);
    setOpsProvider(null)
    setXCoordinate(null)
    setYCoordinate(null)
    setColor(null)
    handleCloseDialog();
  };

  return [id, openDialog, handleOpenOperationNodeDialog, handleOpenOperationNodeDialogWithId,  handleCloseOperationNodeDialog, operationKind, opsProvider, XCoordinate, YCoordinate, color];
};

export default useOperationDialog;
