import { Button } from "@mui/material";
// import useFileService from "../../../services/fileService";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PropTypes from "prop-types";
import LocalstorageService from "../../../services/localStorageService";
import { getObjectFromSearchParams } from "../../../helpers/methods";

const ExportToFileButton = (props) => {
  // const { downloadFile } = useFileService();

  const exportToFile = () => {
    // const preparedSearchParams =
    //   props.searchParams instanceof URLSearchParams
    //     ? getObjectFromSearchParams(props.searchParams)
    //     : props.searchParams;

    // downloadFile(
    //   props.exportFileUrl,
    //   props.fileName,
    //   { headers: props.headers },
    //   {
    //     ...preparedSearchParams,
    //     ...props.exportToFileRequestContent,
    //     language: LocalstorageService.getLanguage(),
    //   }
    // );
  };

  return (
    <Button
      variant="contained"
      size="small"
      onClick={exportToFile}
      startIcon={<FileDownloadIcon />}
    >
      EXPORT
    </Button>
  );
};

ExportToFileButton.propTypes = {
  exportFileUrl: PropTypes.string,
  headers: PropTypes.array,
  searchParams: PropTypes.object,
  fileName: PropTypes.string,
  exportToFileRequestContent: PropTypes.object,
};

ExportToFileButton.defaultProps = {
  exportToFileRequestContent: {},
};

export default ExportToFileButton;
