import { useEffect, useState } from "react";
import { SIPOC_CUSTOMER, SIPOC_INPUT, SIPOC_OUTPUT, SIPOC_PROCESS, SIPOC_SUPPLIER } from "../helpers/constants";
import i18n from "../i18n";

const useSipocStructure = (
  structureWidth,
  onAddNode) => {

  const groupNodes = [
    {
      id: '1',
      data: { label: '' },
      draggable : false,
      position: { x: 0, y: 0 },
      className: 'light',
      style: { backgroundColor: 'rgb(10, 70, 209, 1)', width: 200, height: 100 },
      type: 'group',
    },
    {
      id: SIPOC_SUPPLIER,
      data: { label: '' },
      draggable : false,
      position: { x: 200, y: 10 },
      className: 'light',
      style: { backgroundColor: 'rgb(0, 70, 209, 0.4)', width: structureWidth, height: 80},
      type: 'group',
    },
    {
      id: '2',
      data: { label: '' },
      draggable : false,
      position: { x: 0, y: 110 },
      className: 'light',
      style: {  backgroundColor: 'rgb(37, 209, 10)', width: 200, height: 100  },
      type: 'group',
    },
    {
      id: SIPOC_INPUT,
      data: { label: '' },
      draggable : false,
      position: { x: 200, y: 120 },
      className: 'light',
      style: {  backgroundColor: 'rgba(37, 209, 10, 0.4)', width: structureWidth, height: 80  },
      type: 'group'
    },
    {
      id: '3',
      data: { label: '' },
      draggable : false,
      position: { x: 0, y: 220 },
      className: 'light',
      style: {  backgroundColor: 'rgb(98, 98, 98)', width: 200, height: 100  },
      type: 'group',
    },
    {
      id: SIPOC_PROCESS,
      data: { label: '' },
      draggable : false,
      position: { x: 200, y: 230 },
      className: 'light',
      style: {  backgroundColor: 'rgba(98, 98, 98, 0.4)', width: structureWidth, height: 80  },
      type: 'group'
    },
  
    {
      id: '4',
      data: { label: '' },
      draggable : false,
      position: { x: 0, y: 330 },
      className: 'light',
      style: {  backgroundColor: 'rgb(255, 128, 98)', width: 200, height: 100  },
      type: 'group',
    },
    {
      id: SIPOC_OUTPUT,
      data: { label: '' },
      draggable : false,
      position: { x: 200, y: 340 },
      className: 'light',
      style: {  backgroundColor: 'rgba(255, 128, 98, 0.4)', width: structureWidth, height: 80  },
      type: 'group'
    },
    {
      id: '5',
      data: { label: '' },
      draggable : false,
      position: { x: 0, y: 440 },
      className: 'light',
      style: {  backgroundColor: 'rgb(255, 153, 51)', width: 200, height: 100  },
      type: 'group',
    },
    {
      id: SIPOC_CUSTOMER,
      data: { label: '' },
      draggable : false,
      position: { x: 200, y: 450 },
      className: 'light',
      style: {  backgroundColor: 'rgba(255, 153, 51, 0.4)', width: structureWidth, height: 80  },
      type: 'group'
    }
  ]
  
  const titleNodes = [
    {
      id: '1.0',
      data: { label: i18n.t("pages.sipoc_page.suppliers"), type : "sipoc"},
      position: { x: 25, y: 30 },
      parentId: '1',
      draggable : false,
      extent: 'parent',
      style: {  backgroundColor: 'rgb(10, 70, 209)'},
      type: 'titleNode' 
    },
    {
      id: '2.0',
      data: { label: i18n.t("pages.sipoc_page.input"), type : "sipoc"},
      position: { x: 25, y: 30 },
      parentId: '2',
      draggable : false,
      extent: 'parent',
      style: {  backgroundColor: 'rgb(37, 209, 10)'},
      type: 'titleNode' 
    },
    {
      id: '3.0',
      data: { label:i18n.t("pages.sipoc_page.procces"), type : "sipoc"},
      position: { x: 25, y: 30 },
      parentId: '3',
      draggable : false,
      extent: 'parent',
      style: {  backgroundColor: 'rgb(98, 98, 98)'},
      type: 'titleNode' 
    },
    {
      id: '4.0',
      data: { label: i18n.t("pages.sipoc_page.output"), type : "sipoc"},
      position: { x: 25, y: 30 },
      parentId: '4',
      draggable : false,
      extent: 'parent',
      style: {  backgroundColor: 'rgb(255, 128, 98)'},
      type: 'titleNode' 
    },
    {
      id: '5.0',
      data: { label:  i18n.t("pages.sipoc_page.clients"), type : "sipoc"},
      position: { x: 25, y: 30 },
      parentId: '5',
      draggable : false,
      extent: 'parent',
      style: {  backgroundColor: 'rgb(255, 153, 51)'},
      type: 'titleNode' 
    }
  ]
   const addButtonNodes = [
    {
      id: '1.1',
      data: { label: '', onAddNode : onAddNode, buttonCollor : 'rgb(10, 70, 209, 1)', parentId : SIPOC_SUPPLIER},
      position: { x: structureWidth, y: 10 },
      className: 'light',
      parentId: SIPOC_SUPPLIER,
      extent: 'parent',
      type: 'buttonNode',
      style:{},
      draggable: false
    },
    {
      id: '2.1',
      data: { label: '', onAddNode : onAddNode, buttonCollor : 'rgb(37, 209, 10)', parentId : SIPOC_INPUT},
      position: { x: structureWidth, y: 10 },
      className: 'light',
      parentId: SIPOC_INPUT,
      extent: 'parent',
      type: 'buttonNode', 
      style:{},
      draggable: false
    },
    {
      id: '4.1',
      data: { label: '', onAddNode : onAddNode, buttonCollor : 'rgb(255, 128, 98)', parentId : SIPOC_OUTPUT},
      position: { x: structureWidth, y: 10 },
      className: 'light',
      parentId: SIPOC_OUTPUT,
      extent: 'parent',
      type: 'buttonNode', 
      style:{},
      draggable: false
    },
    {
      id: '5.1',
      data: { label: '', onAddNode : onAddNode, buttonCollor : 'rgb(255, 153, 51)', parentId : SIPOC_CUSTOMER},
      position: { x: structureWidth, y: 10 },
      className: 'light',
      parentId: SIPOC_CUSTOMER,
      extent: 'parent',
      type: 'buttonNode', 
      style:{},
      draggable: false
    }

   ]


  const [structureNodes, setStructureNodes] = useState([...groupNodes,  ...titleNodes, ...addButtonNodes]);

  useEffect(() => {
    setStructureNodes((nodes) => nodes.map((node) =>({
      ...node,
      measured : {},
      position : { x:  node.type === 'buttonNode'? structureWidth-75: node.position.x, y : node.position.y},
      style: {...node.style, width:  [SIPOC_CUSTOMER, SIPOC_INPUT, SIPOC_OUTPUT, SIPOC_SUPPLIER, SIPOC_PROCESS].includes(node.id) ? structureWidth : node.style.width}
    })))
  }, [structureWidth])



  return [structureNodes];
};

export default useSipocStructure;
