import PropTypes from "prop-types";
import MiniDrawer from "../MiniDrawer";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";


function NavigationDrawer(props) {
  const { t } = useTranslation();

  const iconList = ['team', 'process', 'screens', 'product_backlog', 'events',  'kanban', 'tests', 'go_live', 'retro', 'docs', 'reports']
  const [navigationDrawer, setNavigationDrawer] =useState([])

  const prepareDraverDictsData = (iconsList) =>{
    let drawerListData = []

    for (let icon of iconsList){
      drawerListData.push({
        label : t(`drawer.navigation_drawer.${icon}`),
        link: `/project/${props.projectAccessData?.id}/${icon}/`,
        pageName: icon,
        isActive : props.projectAccessData[`is_${icon}_icon_active`]
      })
    } 
    return drawerListData
  }

  const getNavDrawerActionData = (navDrawerActionData) => {
    return navDrawerActionData
      .map((drawerActionIcon) => {
        const { pageName, link, ...rest } = drawerActionIcon;
        return {
          ...rest,
          callback: () => window.location.replace(link),
          isOpen: pageName === props.pageName,
        };
      });
  };

  useEffect (() =>{
    if (props.projectAccessData){
      setNavigationDrawer(getNavDrawerActionData (prepareDraverDictsData(iconList)))
    }
  },[props.projectAccessData])


  return (
    <MiniDrawer
      navigationDrawer={navigationDrawer}
      projectId = {props.projectAccessData?.id}
    >
      {props.children}
    </MiniDrawer>
  );
}

export default NavigationDrawer;

NavigationDrawer.propTypes = {
  pageName: PropTypes.string,
  projectId : PropTypes.string
};

NavigationDrawer.defaultProps = {
};
