import { isEven } from "../../../helpers/methods";
import "./styles.css"
import PropTypes from "prop-types";
import ListItem from "@mui/material/ListItem";


export default function NavigationCircle({ index, navigationSize, label, callback, isOpen, isActive }) {

    const getArrowClass = () => {
        return isEven(index) ? "arrow" : "arrow2"
    }

    return (
        <ListItem
        disablePadding
        sx={{
          display: "block",
          color: isOpen ? "var(--primary) !important" : null,
          ":hover" : " background-color: #f0f0f0"
        }}
      >
        <div class={`${getArrowClass()} ${isActive ? 'hover-enabled' : ''}`} style={{top : `${((index+1)*100)-70}px`, left:'5px', zIndex: `${navigationSize-index}`, }} onClick={isActive ?callback : undefined}>
            <span>{label}</span>
            <div class>
            <div class="circle" style={{border: isActive ? ' 15px solid green' : '15px solid gray'}}/>
            <div class="triangle2" />
            <div class="triangle"   style={{background : isActive ? 'green' : 'gray'}}/>
            </div>
        </div>
        
    </ListItem>
    )
}


NavigationCircle.propTypes = {
    label : PropTypes.string,
    index : PropTypes.number,
    navigationSize : PropTypes.number

};
NavigationCircle.defaultProps = {

};
