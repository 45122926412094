import { useTranslation } from "react-i18next";
import { useAuth } from "../context/auth";
import { useSnackbarAlert } from "../context/snackbarAlert";
import { useAsyncFn } from "./useAsync";


function useLogout() {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const { logout  } = useAuth();
  const logoutFn = useAsyncFn(logout);

  const onLogout = () => {
    return logoutFn
      .execute()
      .then(() => {
        snackbarAlert.openSuccessSnackbarAlert(t("snackbar_alert.logged_out"));
      })
      .catch(() => {
        snackbarAlert.openErrorSnackbarAlert(t("snackbar_alert.server_error"));
      });
  };

  return {
    onLogout,
  };
}

export default useLogout;
