import { useState, useContext, createContext } from "react";
import SnackbarAlert from "../components/base/SnackbarAlert";

export const SnackbarAlertContext = createContext({});

export const SnackbarAlertProvider = ({ children }) => {
  const [snackbarAlertState, setSnackbarAlertState] = useState(() => ({
    open: false,
    alertMessage: "",
    severity: "success",
    autoHideDuration: 10000,
  }));

  const onCloseSnackbarAlert = () => {
    setSnackbarAlertState((prev) => ({ ...prev, open: false }));
  };

  const openSnackbarAlert = (alertMessage, severity) => {
    setSnackbarAlertState((prev) => ({
      ...prev,
      open: true,
      alertMessage: alertMessage,
      severity: severity,
    }));
  };

  const openSuccessSnackbarAlert = (alertMessage) => {
    openSnackbarAlert(alertMessage, "success");
  };

  const openErrorSnackbarAlert = (alertMessage) => {
    openSnackbarAlert(alertMessage, "error");
  };

  const openWarningSnackbarAlert = (alertMessage) => {
    openSnackbarAlert(alertMessage, "warning");
  };

  const contextData = {
    openSuccessSnackbarAlert,
    openErrorSnackbarAlert,
    openWarningSnackbarAlert,
  };

  return (
    <SnackbarAlertContext.Provider value={contextData}>
      {children}
      <SnackbarAlert {...snackbarAlertState} onClose={onCloseSnackbarAlert} />
    </SnackbarAlertContext.Provider>
  );
};

export const useSnackbarAlert = () => {
  return useContext(SnackbarAlertContext);
};
