import { useState, useContext, createContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import axios from "../api/axios";
import useLocalStorage from "../hooks/useLocalStorage";
import { makeRequest } from "../helpers/makeRequest";
import {
  loginUrl,
  logoutUrl,
  refreshTokenUrl,
} from "../helpers/apiUrls";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken, removeAccessToken] =
    useLocalStorage("accessToken");

  const [user, setUser] = useState(() => {
    if (accessToken) return jwtDecode(accessToken);
  });

  const navigate = useNavigate();
  const location = useLocation();

  const login = (username, password) => {
    return makeRequest(axios, loginUrl, {
      method: "POST",
      data: { username, password },
    }).then((data) => {
      onSuccessLogin(data)
    });
  };

  const refreshAccess = async () => {
    try {
      const data = await makeRequest(axios, refreshTokenUrl, {
        method: "POST",
        data: {},
      });
      const access = await data.access;
      setAccessToken(access);
      setUser(jwtDecode(access));

      return access;
    } catch {
      removeAccessToken();
      setUser(null);
    }
  };

  const logout = () => {
    return makeRequest(axios, logoutUrl, {
      method: "POST",
      data: {},
    }).then(() => {
      removeAccessToken(null);
      setUser(null);
      navigate("/login", { replace: true });
    });
  };


  const onSuccessLogin = (data) => {
    setAccessToken(data?.access);
    setUser(jwtDecode(data?.access));

    const from = location.state?.from || "/dashboard/";
    navigate(from, { replace: true });
  }



  const contextData = {
    user,
    accessToken,
    refreshAccess,
    login,
    logout,
    onSuccessLogin
  };
  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
