import SettingsIcon from '@mui/icons-material/Settings';
import { TEXT_FIELD_TYPE } from '../../helpers/constants';
import i18n from '../../i18n';
import MapIcon from '@mui/icons-material/Map';
import SchemaIcon from '@mui/icons-material/Schema';

export const PROCESS_TABLE_FIELDS_CONFIG = [
  {
    name: "process_nr",
    getValueCallback: (rowData) => rowData.process_nr,
    label: i18n.t("table.process_table.process_nr"),
    filterType: TEXT_FIELD_TYPE,
  },
  {
    name: "process_name",
    getValueCallback: (rowData) => rowData.process_name,
    label: i18n.t("table.process_table.process_name"),
    filterType: TEXT_FIELD_TYPE,
  },
  {
    name: "process_owner",
    getValueCallback: (rowData) => rowData.process_owner,
    label: i18n.t("table.process_table.process_owner"),
    filterType: TEXT_FIELD_TYPE,
  },
  {
    name: "process_start",
    getValueCallback: (rowData) => rowData.process_start,
    label: i18n.t("table.process_table.process_start"),
    filterType: TEXT_FIELD_TYPE,
  },
  {
    name: "process_stop", 
    getValueCallback: (rowData) => rowData.process_stop,
    label: i18n.t("table.process_table.process_stop"),
    filterType: TEXT_FIELD_TYPE,
  }, 
  {
    name: "process_map",
    getValueCallback: (rowData, onOpenMap) => {
      return (
        <MapIcon onClick={(e) => onOpenMap(e, rowData.process_id)} />)
        ;
    },
    label: i18n.t("table.process_table.process_map"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  }, 
  {
    name: "process_sipoc",
    getValueCallback: (rowData, onOpenMap, onOpenSipoc) => {
      return (
        <SchemaIcon onClick={(e) => onOpenSipoc(e, rowData.process_id)} />)
        ;
    },
    label: i18n.t("table.process_table.sipoc"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  // {
  //   name: "process_operation",
  //   getValueCallback: (rowData, onOpenMap, onOpenSipoc, onOpenOperations) => {
  //     return (
  //       <SettingsIcon onClick={(e) => onOpenOperations(e, rowData.process_id)} />)
  //       ;
  //   },
  //   label: i18n.t("table.process_table.operations"),
  //   filterType: null,
  //   getFilterFieldConfigCallback: (filteringData) => undefined,
  // }
];
