import React, { memo } from 'react';
import './shapeNode.css';
import OperationShape from '../OperationShape';
import '../OperationShape/operationNode.css'



const DecisionShape = memo(({
  id,
  data,
  draggable
}) => {

  return (
    <OperationShape
    id={id}
    data={data}
    draggable={draggable}
    label={data.label !== undefined ? data.label : "DECYZJA"}
    className={'decisionNode operationNode'}
    style= {{ width: data.width  }}
  />
  );
});

export default DecisionShape;
