import React from "react";
import { Grid } from "@mui/material";
import ForgotPasswordForm from "../../forms/ForgotPasswordForm";
import { useNavigate } from "react-router-dom";

export default function ForgotPasswordPage(props){

    const navigate = useNavigate();

    const sendForgotPasswordRequest = (formValue) => {
    }

    return (
        <Grid container direction="row" justifyContent={"flex-start"} alignItems="center">
            <Grid item sm={2} xs={0} />
            <Grid item sm={8} xs={12}>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <ForgotPasswordForm onSubmit={sendForgotPasswordRequest}/>
                </Grid>
            </Grid>
            <Grid item sm={2} xs={0} />
        </Grid>
    )
}