import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { width } from "@mui/system";

export default function CounterRecords(props) {
  const { t } = useTranslation();

  const { value } = props;

  const getLabel = () => {
    const label = props.label ? props.label : t("number_of_records");
    return label.charAt(0).toUpperCase() + label.slice(1);
  };

  return (
    <TextField
      id="counterRecord"
      disabled
      label={getLabel()}
      size="small"
      value={parseInt(value)}
    />
  );
}

CounterRecords.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string,
};
