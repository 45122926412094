import * as React from 'react';
import { Grid } from '@mui/material';
import CreateProjectForm from '../../../form/CreateProjectForm';
import CompanyForm from '../../../form/CompanyForm';
import AddressForm from '../../../form/AddressForm';
import PropTypes from "prop-types";


export default function CompanyStep(props) {


  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
      style={{ marginTop: "5px" }}
    >
      <Grid item xs={4}>
        <CreateProjectForm
          formValue={props.projectForm}
          readOnly={true}
        />
      </Grid>
      <Grid item xs={4} >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={12} >
            <CompanyForm
              formValue={props.companyForm}
              onChange={props.onCompanyChange}
              onClearCompanySelectField={props.onClear}
              companies = {props.companies}
              isCustomer={false}
            />
          </Grid>
          <Grid item xs={12} >
            <AddressForm
              formValue={props.addressForm}
              onChange={props.onAddressChange}
              addressKinds={props.addressKinds}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

CompanyStep.propTypes = {
  projectForm : PropTypes.object,
  companyForm : PropTypes.object,
  onCompanyChange : PropTypes.func,
  onClear : PropTypes.func,
  addressForm : PropTypes.object,
  onAddressChange : PropTypes.func,
  addressKinds : PropTypes.array
};

CompanyStep.defaultProps = {
  addressKinds : []
};