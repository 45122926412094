import React, { memo } from 'react';
import './shapeNode.css';
import OperationShape from '../OperationShape';



const ProviderNode = memo(({
  id,
  data,
  draggable
}) => {

  return (
    <OperationShape
    id={id}
    data={data}
    draggable={draggable}
    label={undefined}
    className={'providerNode'}
    style= {{...data.syle}}
  />
  );
});

export default ProviderNode;
