import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";

import "./index.css";

import "./i18n";

import moment from 'moment';

moment.locale('pl'); 


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App  />
 </React.StrictMode>
);
